// src/screens/Dashboard.jsx

import React, { useEffect, useState } from "react";
import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import CustomLinearProgress, {
  CustomToolbar,
  BankChip,
  CustomMessage,
} from "../components/Common";
// import Modal from "./Modal";

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  //   const [entryID, setEntryID] = useState("");
  //   const [caller, setCaller] = useState(null);
  //   const [isModalOpen, setIsModalOpen] = useState(false);

  // Trigger to AutoUpdate
  //   useEffect(() => {
  //     const unsubscribe = onSnapshot(
  //       collection(firestore, "parts"),
  //       (snapshot) => {
  //         const updatedData = snapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));
  //         setData(updatedData);
  //         setLoading(false);
  //       }
  //     );

  //     return () => unsubscribe();
  //   }, []);

  //   const closeModal = () => {
  //     setIsModalOpen(false);
  //   };

  //   const handleEdit = (id, process) => {
  //     console.log(`Editing item with ID: ${id}`);
  //     setCaller(process);
  //     setEntryID(id);
  //     setIsModalOpen(true);
  //   };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "barcode",
      headerName: t("Barcode"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "category",
      headerName: t("Category"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "internal_pn",
      headerName: t("Internal Part Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "unit",
      headerName: t("Unit"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "minimum_stock",
      headerName: t("Minimum Stock"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => <BankChip {...params} color="info" />,
    },
    {
      field: "curret_stock",
      headerName: t("Stock"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => (
        <div>
          {params.value <= params.row.minimum_stock ? (
            <BankChip {...params} color="error" />
          ) : (
            <BankChip {...params} color="success" />
          )}
        </div>
      ),
    },
    {
      field: "storage_location",
      headerName: t("Storage Location"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "purchase_price",
      headerName: t("Purchase Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "sale_price",
      headerName: t("Sale Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
  ];

  //   if (currentUser.auth === "Admin" || currentUser.auth === "User") {
  return (
    <Box m="20px" sx={{ height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "95vh",
          width: "100%",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={2}>
            {/* Feedback Mechanism */}
            {/* <CustomMessage
              open={openMessage}
              onClose={handleMessageClose}
              message={message}
            /> */}
            <Grid item xs={2}>
              <Box mb="30px">
                <Typography
                  variant="h2"
                  color={colors.grey[200]}
                  fontWeight="bold"
                  sx={{
                    fontSize: {
                      xs: "1.5rem", // font size for extra-small screens
                      sm: "2rem", // font size for small screens
                      md: "3rem", // font size for medium screens
                      lg: "3.5rem", // font size for large screens
                      xl: "3.5rem", // font size for extra-large screens
                    },
                  }}
                >
                  {t("Dashboard")}
                </Typography>
                <Typography
                  variant="r16"
                  color={colors.greenAccent[400]}
                  sx={{
                    fontSize: {
                      xs: "1rem", // font size for extra-small screens
                      sm: "1.2rem", // font size for small screens
                      md: "1.2rem", // font size for medium screens
                      lg: "1.5rem", // font size for large screens
                      xl: "1.5rem", // font size for extra-large screens
                    },
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflow if any
                    textOverflow: "ellipsis", // Add ellipsis if text overflows
                  }}
                >
                  {t("Your Dashboard")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* {isModalOpen && (
          <Modal
            onClose={closeModal}
            caller={caller}
            extParam={entryID}
            currentUser={currentUser}
          />
        )} */}
        <Box
          m="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          //height="100vh" /* Full height of the viewport */
          min-height="0" /* Ensure it can shrink */
          width="99%"
          sx={{
            "& .MuiDataGrid-root": {
              fontSize: "16px",
              ...(theme.palette.mode === "dark"
                ? {
                    border: "1px solid rgba(62, 67, 150, 1)",
                  }
                : { border: "1px solid rgba(175, 63, 59, 1)" }),
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              fontFamily: "Poppins",
              fontSize: "12px",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontFamily: "Poppins",
              fontSize: "0.9rem",
              ...(theme.palette.mode === "dark"
                ? {
                    color: colors.greenAccent[500],
                  }
                : { color: colors.redAccent[500] }),
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              ...(theme.palette.mode === "dark"
                ? {
                    backgroundColor: colors.blueAccent[700],
                  }
                : { backgroundColor: colors.redAccent[800] }),
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-cell:hover": {
              ...(theme.palette.mode === "dark"
                ? {
                    color: colors.blueAccent[500],
                  }
                : { color: colors.redAccent[500] }),
            },
          }}
        >
          <DataGrid
            columnVisibilityModel={{
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
              notes: false,
              process_status: false,
            }}
            checkboxSelection={false}
            rows={data}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: CustomLinearProgress,
            }}
            loading={loading}
            pageSize={5}
            autoHeight
            autoWidth
            // autoPageSize
            disableSelectionOnClick
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      </Box>
    </Box>
  );
  // biome-ignore lint/style/noUselessElse: <explanation>
  //   } else {
  //     return (
  //       <Box m="20px">
  //         {/* HEADER */}
  //         <Box
  //           display="flex"
  //           justifyContent="space-between"
  //           alignItems="center"
  //           paddingRight="25px"
  //         >
  //           {/* <Header
  //             title={t("403")}
  //             subtitle={t("You don't have permission to see this page")}
  //           /> */}
  //         </Box>
  //       </Box>
  //     );
  //   }
};

export default Dashboard;
