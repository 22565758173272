// src/screens/AddDocuments.jsx

import React, { useState, useEffect } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import {
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import LoadingWindow from "../components/LoadingWindow";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";

const AddDocuments = ({ onClose, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { brandsAndModels, docCategories } = useData();
  const [years, setYears] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [updateChecked, setUpdateChecked] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedDocCategory, setSelectedDocCategory] = useState(null);
  // const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    brand: "",
    model: "",
    docCategory: "",
    startYear: "",
    endYear: "",
    documents: [],
  });

  // Get years from JSON file
  useEffect(() => {
    fetch("/Years.json")
      .then((response) => response.json())
      .then((data) => {
        setYears(data.years);
      })
      .catch((error) => console.error("Error fetching years:", error));
  }, []);

  // Get motorcycles brands from Database
  useEffect(() => {
    setBrands(Object.keys(brandsAndModels).sort());
  }, [brandsAndModels]);

  // Get motorcycles models from Database according to brand
  useEffect(() => {
    if (selectedBrand && brandsAndModels) {
      setModels(brandsAndModels[selectedBrand].sort());
    }
  }, [selectedBrand, brandsAndModels]);

  const handleBrandChange = (event, value) => {
    setSelectedBrand(value);
    setData({ ...data, brand: value });
  };

  const handleModelChange = (event, value) => {
    setSelectedModel(value);
    setData({ ...data, model: value });
  };

  const handleDocCategoryChange = (event, value) => {
    setSelectedDocCategory(value);
    setData({ ...data, docCategory: value });
  };

  const handleStartYearChange = (event, value) => {
    setData({ ...data, startYear: value });
  };

  const handleEndYearChange = (event, value) => {
    setData({ ...data, endYear: value });
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setData({
      brand: "",
      model: "",
      docCategory: "",
      startYear: "",
      endYear: "",
      documents: [],
    });
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!data.brand) newErrors.brand = t("Brand is required");
    if (!data.model) newErrors.model = t("Model is required");
    if (documents.length > 0) {
      if (!data.startYear) newErrors.startYear = t("Start year is required");
      if (!data.endYear) newErrors.endYear = t("End year is required");
    }

    return newErrors;
  };

  const handleClearData = () => {
    // setErrors({});
    setDocuments([]);
    setBrands(Object.keys(brandsAndModels));
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setDocuments(files);
  };

  // Mark files for delete operation
  const handleDeleteFile = (filePath) => {
    setDocuments(documents.filter((file) => file !== filePath));
  };

  async function updateReferenceDocuments(
    brand,
    model,
    startYear,
    endYear,
    category,
    newEntries
  ) {
    try {
      // Reference to the motorcycles collection
      const motorcyclesRef = collection(firestore, "motorcycles");

      // Fetch all documents in the collection
      const querySnapshot = await getDocs(motorcyclesRef);

      // Iterate over each document
      // biome-ignore lint/complexity/noForEach: <explanation>
      querySnapshot.forEach(async (docSnapshot) => {
        const data = docSnapshot.data();
        // console.log("Current data processing: ", data);
        // console.log(
        //   "Search params: ",
        //   data.year,
        //   startYear,
        //   endYear,
        //   data.brand,
        //   brand,
        //   data.model,
        //   model
        // );

        // Check if the year field matches the specified year
        if (
          data.year >= startYear &&
          data.year <= endYear &&
          data.brand === brand &&
          data.model === model
        ) {
          // Get the reference to the specific document
          const docRef = doc(firestore, "motorcycles", docSnapshot.id);
          // console.log("Motorcycle found with ID: ", docSnapshot.id);
          // console.log("Current data of motorcycle: ", data);
          // console.log("URLs: ", newEntries);

          await setDoc(
            docRef,
            {
              referenceDocuments: {
                ...data.referenceDocuments,
                [category]: newEntries,
              },
            },
            { merge: true }
          );

          // console.log(
          //   `Document with ID ${docSnapshot.id} updated successfully.`
          // );
        }
      });

      // console.log("All relevant documents have been processed.");
    } catch (error) {
      console.error("Error updating documents: ", error);
    }
  }

  // TOTAL BRAIN FUCK! MAY GOD FORGIVE YOU!!!
  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Document Reference
        const brandRef = doc(firestore, "refDocs", data.brand);
        const brandDoc = await getDoc(brandRef);
        const documentUrls = [];

        // Check if selected brand does exist or not
        if (brandDoc.exists()) {
          const brandData = brandDoc.data();
          const modelExists = !!brandData[data.model];
          const modelData = brandData[data.model];

          // Brand exist! Check if selected model does exist or not
          if (modelExists) {
            // console.log(`Model ${data.model} exists in brand ${data.brand}`);

            let startYearL;
            let endYearL;
            let docCategoryL;
            let yearsFound = false;
            let lastKey;

            // Model exist too. Iterate over all entries in selected model to see if years are matched
            for (const [key, value] of Object.entries(modelData)) {
              const { startYear, endYear } = value;

              startYearL = startYear;
              endYearL = endYear;
              docCategoryL = value[data.docCategory];
              lastKey = key;

              // console.log(
              //   `Model ${data.model} found with start year: ${startYearL} and end year: ${endYearL} with documents of: ${docCategoryL}`
              // );

              if (
                Number(data.startYear) === Number(startYearL) &&
                Number(data.endYear) === Number(endYearL)
              ) {
                yearsFound = true;
                // Same year
                // console.log("Same model including years found! Updating it...");

                // Years found! Check if category is same or not
                if (docCategoryL) {
                  // Same category! Keep the data already in there and update only the docs.
                  // console.log("Category Found! Updating now...");

                  // Upload documents
                  for (const file of documents) {
                    const documentRef = ref(
                      storage,
                      `referenceDocuments/${data.brand}/${data.model}/${data.startYear}_${data.endYear}/${data.docCategory}/${file.name}`
                    );
                    await uploadBytes(documentRef, file);
                    const url = await getDownloadURL(documentRef);
                    documentUrls.push(url);
                  }

                  // console.log("Documents Uploaded! Updating database...");

                  const newModelL = {
                    ...modelData[key],
                    startYear: startYearL,
                    endYear: endYearL,
                    [t(data.docCategory)]: documentUrls,
                  };

                  // Update
                  await updateDoc(brandRef, {
                    [data.model]: { ...modelData, [key]: newModelL },
                  });

                  // Check if user want to update already saved motorcycles
                  if (updateChecked) {
                    // console.log(documentUrls);
                    await updateReferenceDocuments(
                      data.brand,
                      data.model,
                      data.startYear,
                      data.endYear,
                      data.docCategory,
                      documentUrls
                    );

                    // console.log("Updating motorcycle database...");
                  }
                } else {
                  // Different category! Keep the data already in there and add only new docs into it.
                  // console.log("There is no such category! Creating now...");

                  // Upload documents
                  for (const file of documents) {
                    const documentRef = ref(
                      storage,
                      `referenceDocuments/${data.brand}/${data.model}/${data.startYear}_${data.endYear}/${data.docCategory}/${file.name}`
                    );
                    await uploadBytes(documentRef, file);
                    const url = await getDownloadURL(documentRef);
                    documentUrls.push(url);
                  }

                  const newModelL = {
                    ...modelData[key],
                    [t(data.docCategory)]: documentUrls,
                  };

                  await setDoc(
                    brandRef,
                    {
                      [data.model]: { ...modelData, [key]: newModelL },
                    },
                    { merge: true }
                  );

                  // Check if user want to update already saved motorcycles
                  if (updateChecked) {
                    // console.log(documentUrls);
                    await updateReferenceDocuments(
                      data.brand,
                      data.model,
                      data.startYear,
                      data.endYear,
                      data.docCategory,
                      documentUrls
                    );

                    // console.log("Updating motorcycle database...");
                  }
                }
                // console.log("Updated!");
              } else {
              }
            }

            if (!yearsFound) {
              // Same model but years are different
              // console.log(
              //   "Model found but years are not same! Creating now..."
              // );

              // Upload documents
              for (const file of documents) {
                const documentRef = ref(
                  storage,
                  `referenceDocuments/${data.brand}/${data.model}/${data.startYear}_${data.endYear}/${data.docCategory}/${file.name}`
                );
                await uploadBytes(documentRef, file);
                const url = await getDownloadURL(documentRef);
                documentUrls.push(url);
              }

              // Create data package
              const newModel = {
                startYear: data.startYear,
                endYear: data.endYear,
                [t(data.docCategory)]: documentUrls,
              };
              // Add new entry into the model
              await updateDoc(brandRef, {
                [data.model]: {
                  ...modelData,
                  [Number(lastKey) + 1]: newModel,
                },
              });

              // Check if user want to update already saved motorcycles
              if (updateChecked) {
                // console.log(documentUrls);
                await updateReferenceDocuments(
                  data.brand,
                  data.model,
                  data.startYear,
                  data.endYear,
                  data.docCategory,
                  documentUrls
                );

                // console.log("Updating motorcycle database...");
              }
              // console.log("Added!");
            }
          } else {
            // Model not found! Create new model data package.
            // console.log(
            //   `Model ${data.model} does not exist in brand ${data.brand} Creating now...`
            // );

            // Upload documents
            for (const file of documents) {
              const documentRef = ref(
                storage,
                `referenceDocuments/${data.brand}/${data.model}/${data.startYear}_${data.endYear}/${data.docCategory}/${file.name}`
              );
              await uploadBytes(documentRef, file);
              const url = await getDownloadURL(documentRef);
              documentUrls.push(url);
            }

            const newModel = {
              startYear: data.startYear,
              endYear: data.endYear,
              [t(data.docCategory)]: documentUrls,
            };

            await setDoc(
              brandRef,
              {
                [data.model]: arrayUnion(newModel),
              },
              { merge: true }
            );

            // Check if user want to update already saved motorcycles
            if (updateChecked) {
              // console.log(documentUrls);
              await updateReferenceDocuments(
                data.brand,
                data.model,
                data.startYear,
                data.endYear,
                data.docCategory,
                documentUrls
              );

              // console.log("Updating motorcycle database...");
            }
            // console.log("Model created!");
          }
        } else {
          // Brand not found! Create whole data package.
          // console.log("No such document! Creating now...");

          // Upload documents
          for (const file of documents) {
            const documentRef = ref(
              storage,
              `referenceDocuments/${data.brand}/${data.model}/${data.startYear}_${data.endYear}/${data.docCategory}/${file.name}`
            );
            await uploadBytes(documentRef, file);
            const url = await getDownloadURL(documentRef);
            documentUrls.push(url);
          }

          const newModel = [
            {
              startYear: data.startYear,
              endYear: data.endYear,
              [t(data.docCategory)]: documentUrls,
            },
          ];

          await setDoc(brandRef, {
            [data.model]: newModel,
          });

          // Check if user want to update already saved motorcycles
          if (updateChecked) {
            // console.log(documentUrls);
            await updateReferenceDocuments(
              data.brand,
              data.model,
              data.startYear,
              data.endYear,
              data.docCategory,
              documentUrls
            );

            // console.log("Updating motorcycle database...");
          }

          // console.log(
          //   `Model ${data.model} does not exist in brand ${data.brand}`
          // );
        }

        // Clear Data
        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Create Brand/Model"));
      } finally {
        setLoading(false);
      }
    } else {
      //   console.log(errors);
      // setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Add Documents to Motorcycles")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Brand Field */}
                  <Grid item xs={6}>
                    <Autocomplete
                      options={brands}
                      fullWidth
                      disablePortal
                      id="combobox-brand"
                      name="brand"
                      color="secondary"
                      value={selectedBrand || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleBrandChange("brand", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Brand")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Model Field */}
                  <Grid item xs={6}>
                    <Autocomplete
                      options={models}
                      fullWidth
                      disablePortal
                      id="combobox-model"
                      name="model"
                      color="secondary"
                      value={selectedModel || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleModelChange("model", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Model")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Category Field */}
                  <Grid item xs={12}>
                    <Autocomplete
                      options={docCategories}
                      fullWidth
                      disablePortal
                      id="combobox-doccategory"
                      name="docCategory"
                      color="secondary"
                      value={selectedDocCategory || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleDocCategoryChange(
                          "docCategory",
                          newValue ? newValue : ""
                        )
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Document Category")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Year Range Selection */}
                  <Grid item xs={6}>
                    <Autocomplete
                      options={years}
                      fullWidth
                      disablePortal
                      id="combobox-start-year"
                      name="startYear"
                      color="secondary"
                      value={data.startYear || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleStartYearChange(
                          "startYear",
                          newValue ? newValue : ""
                        )
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Document Validity Start Date")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Year Range Selection */}
                  <Grid item xs={6}>
                    <Autocomplete
                      options={years}
                      fullWidth
                      disablePortal
                      id="combobox-end-year"
                      name="endYear"
                      color="secondary"
                      value={data.endYear || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleEndYearChange("endYear", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Document Validity Expiry Date")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={updateChecked}
                          onChange={() => setUpdateChecked(!updateChecked)}
                          name="checkbox"
                          color="info"
                        />
                      }
                      label={t("Updated already saved motorcycles")}
                    />
                  </Grid>

                  {/* Upload Documents Button */}
                  <>
                    <Grid item xs={12} sm={12}>
                      <Button
                        variant="contained"
                        onClick={() => setOpenDocumentsDialog(true)}
                        fullWidth
                        sx={{
                          mt: 1,
                          mb: 0,
                          backgroundColor: "#F6B411",
                          color: "#fff",
                          height: "50px",
                        }}
                      >
                        <PostAddOutlinedIcon />
                        <Typography
                          component="h1"
                          variant="r16"
                          paddingLeft="10px"
                        >
                          {t("Upload Documents")}
                        </Typography>
                      </Button>
                    </Grid>

                    {/* File List */}
                    <Grid item xs={12}>
                      <List>
                        {documents.map((doc, index) => (
                          <ListItem
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            key={index}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDeleteFile(doc)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            }
                          >
                            <ListItemIcon>
                              <FileIcon />
                            </ListItemIcon>
                            <ListItemText primary={doc.name} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Create")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>

                  <Dialog
                    open={openDocumentsDialog}
                    onClose={() => setOpenDocumentsDialog(false)}
                  >
                    <DialogTitle>{t("Upload Documents")}</DialogTitle>
                    <DialogContent>
                      <input
                        type="file"
                        multiple
                        onChange={handleDocumentsSelection}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenDocumentsDialog(false)}
                        color="secondary"
                      >
                        {t("Close")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default AddDocuments;
