// src/screens/ProvideFeedback.jsx

import React, { useState } from "react";
import { storage } from "../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  //   useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import axios from "axios";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";

// 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

const ProvideFeedback = ({ onClose, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  //   const theme = useTheme();
  //   const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [documents, setDocuments] = useState([]);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [trelloData, setTrelloData] = useState({
    boardId: "2MBJjWDJ",
    cardName: "",
    cardDescription: "",
  });

  const token = "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"; // Be cautious with token security
  const repoOwner = "WAR10CKBS";
  const repoName = "bnb_garage_app";

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setTrelloData({ ...trelloData, [name]: value });
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setDocuments(files);
  };

  const validateFields = () => {
    const newErrors = {};
    // if (!trelloData.boardName)
    //   newErrors.boardName = t("Board name is required");
    if (!trelloData.cardName) newErrors.cardName = t("Card name is required");
    // if (!trelloData.cardDescription)
    //   newErrors.cardDescription = t("Card Description is required");
    return newErrors;
  };

  const handleClearData = () => {
    setTrelloData({
      boardId: "2MBJjWDJ",
      cardName: "",
      cardDescription: "",
    });
    setDocuments([]);
    setErrors({});
  };

  // Mark files for delete operation
  const handleDeleteFile = (filePath) => {
    setDocuments(documents.filter((file) => file !== filePath));
  };

  const handleAddCard = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);

      const apiKey = "456aa5487ede93e6642595beb0aa008e";
      const apiToken =
        "ATTA3874333077fcc851e654f92b3963c4886fe8b0433f0a464924917558f1a012d86F1C9DF5";
      //   console.log(apiKey, apiToken);
      try {
        // // Create the board
        // const boardResponse = await axios.post(
        //   `https://api.trello.com/1/boards/?name=${trelloData.boardName}&key=${apiKey}&token=${apiToken}`
        // );

        // const boardId = boardResponse.data.id;

        // console.log("Board Created:", boardResponse.data);

        // Create a card on the board's default list
        const listsResponse = await axios.get(
          `https://api.trello.com/1/boards/${trelloData.boardId}/lists?key=${apiKey}&token=${apiToken}`
        );

        const defaultListId = listsResponse.data[0].id;

        // console.log(defaultListId);

        const cardResponse = await axios.post(
          `https://api.trello.com/1/cards?idList=${defaultListId}&name=${trelloData.cardName}&desc=${trelloData.cardDescription}&key=${apiKey}&token=${apiToken}`
        );

        const cardId = cardResponse.data.id;

        // console.log("Card Created:", cardResponse.data);

        // Upload documents
        const documentUrls = [];
        for (const file of documents) {
          const documentRef = ref(
            storage,
            `feedbacks/trello/${trelloData.boardId}/${trelloData.cardName}/attachments/${file.name}`
          );
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          //   console.log(url);
          documentUrls.push(url);
          //   console.log(documentUrls);
        }

        // Add attachments to the card
        for (const url of documentUrls) {
          if (url) {
            try {
              //   const attachmentResponse =
              await axios.post(
                `https://api.trello.com/1/cards/${cardId}/attachments`,
                { url },
                {
                  params: {
                    key: apiKey,
                    token: apiToken,
                  },
                }
              );

              //   console.log("Attachment Added:", attachmentResponse.data);
            } catch (attachmentError) {
              console.error(
                "Error adding attachment:",
                attachmentError.response.data
              );
            }
          }
        }

        // console.log("Card, and Attachment Created Successfully!");

        // Construct the issue body with attachment URLs
        let issueBody = trelloData.cardDescription;
        if (documentUrls && documentUrls.length > 0) {
          issueBody += "\n\nAttachments:\n";
          documentUrls.forEach((url, index) => {
            issueBody += `- [Attachment ${index + 1}](${url})\n`;
          });
        }

        try {
          // const response =
          await axios.post(
            `https://api.github.com/repos/${repoOwner}/${repoName}/issues`,
            { title: trelloData.cardName, body: issueBody },
            {
              headers: {
                Authorization: `token ${token}`,
                Accept: "application/vnd.github.v3+json",
              },
            }
          );
          // console.log("Issue created:", response.data);
        } catch (error) {
          console.error("Error creating issue:", error);
        }

        handleClose();
      } catch (error) {
        console.error("Error creating board, card, or attachment:", error);
        alert("Failed to create board, card, or attachment.");
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 720,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* Container */}
                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Provide Feedback to Developer")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Card Name Field */}
                  <Grid item xs={6}>
                    <TextField
                      label={t("Card Name")}
                      name="cardName"
                      color="secondary"
                      value={trelloData.cardName}
                      onChange={handleInputChange}
                      fullWidth
                      required
                      error={!!errors.cardName}
                      helperText={errors.cardName}
                    />
                  </Grid>

                  {/* Attachments Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDocumentsDialog(true)}
                      fullWidth
                      sx={{
                        mb: 0,
                        backgroundColor: "#F6B411",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <PostAddOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Attachments")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Card Description Field */}
                  <Grid item xs={12}>
                    <TextField
                      label={t("Card Description")}
                      name="cardDescription"
                      color="secondary"
                      value={trelloData.cardDescription}
                      onChange={handleInputChange}
                      fullWidth
                      multiline
                      rows={4}
                      error={!!errors.cardDescription}
                      helperText={errors.cardDescription}
                    />
                  </Grid>
                  {/* File List */}
                  <Grid item xs={12}>
                    <List>
                      {documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteFile(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={doc.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleAddCard}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Send Feedback")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ProvideFeedback;
