// src/components/FlagIcon.js

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

// Import your flags
import trFlag from "../assets/flags/turkey.png";
import usFlag from "../assets/flags/usa.png";
import itFlag from "../assets/flags/italy.png";
import jpFlag from "../assets/flags/japan.png";

const flagImages = {
  trFlag,
  usFlag,
  itFlag,
  jpFlag,
};

const FlagIcon = ({ flag, size }) => {
  return (
    <Box
      component="img"
      src={flagImages[flag]}
      alt={flag}
      sx={{ width: size, height: size, objectFit: "contain" }}
    />
  );
};

FlagIcon.propTypes = {
  country: PropTypes.string.isRequired,
  size: PropTypes.number,
};

FlagIcon.defaultProps = {
  size: 24, // Default size if not provided
};

export default FlagIcon;
