/* eslint-disable react-hooks/exhaustive-deps */
// src/screens/EditPurchase.jsx

import React, { useCallback, useEffect, useState, useMemo } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
// import { v4 as uuidv4 } from "uuid";
import {
  deleteObject,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Avatar,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, { CustomToolbar } from "../components/Common";
import HoverImage from "../components/HoverImage";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO, setHours, setMinutes, setSeconds } from "date-fns";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/material/styles";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import { useData } from "../components/DataProvider";

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const StyledDate = styled(DesktopDatePicker)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color:
    theme.palette.mode === "light"
      ? theme.palette.secondary.dark
      : theme.palette.secondary.light,
}));

const EditPurchase = ({ onClose, purchaseDetails, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [selectedPart, setSelectedPart] = useState(null);
  const [partsData, setPartsData] = useState([]);
  const [dataCommit, setDataCommit] = useState([]);
  const [partToRemove, setPartToRemove] = useState("");
  const [quantity, setQuantity] = useState(purchaseDetails.quantity);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierRef, setSupplierRef] = useState(null);
  const { parts, suppliers } = useData();
  const [purchaseDate, setPurchaseDate] = useState(
    purchaseDetails.date.toDate()
  );
  const [errors, setErrors] = useState({});
  const [documents, setDocuments] = useState(purchaseDetails.documents);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const [purchaseData, setPurchaseData] = useState({
    documents: purchaseDetails.documents,
    id: purchaseDetails.id,
    date: purchaseDetails.date,
    parts: purchaseDetails.parts,
    quantity: purchaseDetails.quantity,
    purchase_price: purchaseDetails.purchase_price,
    sale_price: purchaseDetails.sale_price,
    supplier: purchaseDetails.supplier,
    total_price: purchaseDetails.total_price,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // Calculate the total amount
    const total = partsData.reduce((acc, item) => acc + item.totalPrice, 0);
    // console.log(total.toFixed(2));
    setPurchaseData({ ...purchaseData, total_price: total.toFixed(2) });
  }, [partsData]);

  const partNames = useMemo(
    () =>
      parts.map(
        (part) =>
          `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}`
      ),
    [parts]
  );

  // Memoize the supplier names array
  const supplierNames = useMemo(
    () => suppliers.map((supplier) => supplier.corporation),
    [suppliers]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const fetchData = async () => {
      let partsPurchasedData = [];
      try {
        const supplierDoc = await getDoc(purchaseDetails.supplier);

        if (supplierDoc.exists()) {
          setSelectedSupplier(supplierDoc.data().corporation);
          setSupplierRef(purchaseDetails.supplier);
        }

        // Get Parts Used
        const partsEntries = Object.entries(purchaseDetails.parts);
        // console.log("partsUsedEntries: ", partsUsedEntries);

        const partsPromises = partsEntries.map(async ([key, part]) => {
          try {
            // console.log("Fetching part: ", part[0]);
            // Index 0: Document Reference
            // Index 1: Quantity
            // Index 2: Purchase Price
            // Index 3: Sale Price

            const partDoc = await getDoc(part[0]);
            // console.log("Part Data: ", partDoc.data(), key);
            if (partDoc.exists()) {
              return {
                id: key, // Unique identifier for DataGrid rows
                part: partDoc.data(),
                quantity: part[1],
                purchasePrice: part[2],
                salePrice: part[3],
              };
              // biome-ignore lint/style/noUselessElse: <explanation>
            } else {
              console.error(`Part document ${part[0].id} does not exist`);
              return null;
            }
          } catch (error) {
            console.error(`Error fetching part document ${part[0].id}:`, error);
            return null;
          }
        });
        partsPurchasedData = await Promise.all(partsPromises);
      } catch (error) {
        console.error(
          "Error fetching client, motorcycle, or parts data:",
          error
        );
        return null;
      } finally {
        setLoading(false);
      }

      const transformedPartsPurchasedData = partsPurchasedData.map((item) => ({
        id: item.id,
        photo: item.part.photo,
        barcode: item.part.barcode,
        name: item.part.name,
        category: item.part.category,
        quantity: item.quantity,
        salePrice: item.salePrice,
        purchasePrice: item.purchasePrice,
        totalPrice: item.purchasePrice * item.quantity,
      }));

      //   console.log(transformedPartsUsedData);
      setPartsData(transformedPartsPurchasedData);
      setDataCommit(partsPurchasedData);
    };
    fetchData();
  }, []);

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  const handlePartComboboxChange = (event, value) => {
    if (value) {
      const selectedPartData = parts.find(
        (part) =>
          `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}}` ===
          value
      );
      if (selectedPartData) {
        setErrors({ ...errors, selectedPart: "" });
        setSelectedPart(value);
        setPurchaseData({
          ...purchaseData,
          sale_price: selectedPartData.sale_price,
          purchase_price: selectedPartData.purchase_price,
        });
      }
    }
  };

  const handleQuantityChange = (event) => {
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(event.target.value)) {
      setErrors({ ...errors, quantity: "" });
      setQuantity(event.target.value);
    }
  };

  const preValidateFields = () => {
    const newErrors = {};
    if (!quantity) newErrors.quantity = t("Quantity is required");
    if (Number(purchaseData.purchase_price) < 0)
      newErrors.purchase_price = t("Purchase Price is required");
    if (!selectedPart) newErrors.selectedPart = t("Part is required");
    if (!purchaseData.sale_price)
      newErrors.sale_price = t("Sale Price is required");
    return newErrors;
  };

  const handleSavePart = (event) => {
    setErrors({ ...errors, parts: "" });
    const err = preValidateFields();

    if (Object.keys(err).length === 0) {
      const id = uuidv4();
      if (selectedPart) {
        const selectedPartData = parts.find(
          (part) =>
            `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}` ===
            selectedPart
        );
        if (selectedPartData) {
          // console.log("Selected Part: ", selectedPartData);
          // console.log("Selected Part's Price: ", selectedPartData.sale_price);

          const purchasedPartData = {
            id: id,
            photo: selectedPartData.photo,
            barcode: selectedPartData.barcode,
            name: selectedPartData.name,
            category: selectedPartData.category,
            quantity: quantity,
            purchasePrice: purchaseData.purchase_price,
            totalPrice: quantity * purchaseData.purchase_price,
          };

          const dataStore = {
            id: id,
            part: selectedPartData,
            quantity: quantity,
            purchasePrice: purchaseData.purchase_price,
            salePrice: purchaseData.sale_price,
          };

          setDataCommit((prevDataCommit) => [...prevDataCommit, dataStore]);

          // console.log(dataCommit);

          setPartsData((prevPartsData) => [
            ...prevPartsData,
            purchasedPartData,
          ]);
        } else {
          console.error("Part not found among parts!");
        }
      } else {
        console.error("Selected Part is not valid!");
      }
    } else {
      // console.log(err);
      setErrors(err);
    }
  };

  const handlePartSelectionChange = async (newSelection) => {
    setPartToRemove(newSelection);
  };

  const handleRemovePart = (event) => {
    setPartsData(partsData.filter((part) => part.id !== partToRemove[0]));
    setDataCommit(dataCommit.filter((data) => data.id !== partToRemove[0]));
  };

  const handleSupplierChange = (event, value) => {
    let selectedPartData;

    if (value) {
      selectedPartData = suppliers.find(
        (supplier) => supplier.corporation === value
      );

      if (selectedPartData) {
        setErrors({ ...errors, supplier: "" });
        setSupplierRef(selectedPartData);
        setSelectedSupplier(value);
      } else {
        setErrors({
          ...errors,
          supplier: t("Supplier is not valid!"),
        });
      }
    } else {
      //   setErrors({ ...errors, selectedPart: t("Search result is not valid!") });
    }
  };

  const handlePurchaseDayChange = (dateSent) => {
    if (dateSent === null || Number.isNaN(dateSent.getTime())) {
      console.error("Not a valid date!");
    } else {
      // Get the current time
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();

      // Combine the selected date with the current time
      let combinedDate = setHours(dateSent, currentHour);
      combinedDate = setMinutes(combinedDate, currentMinute);
      combinedDate = setSeconds(combinedDate, currentSecond);

      // Convert the combined date-time to a Firestore timestamp
      const timestamp = new Date(combinedDate);

      setPurchaseDate(timestamp);
    }
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    // console.log(value);
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(value)) {
      const number = Number.parseFloat(value);
      if (Number.isNaN(number)) {
        console.error("Invalid number");
      } else {
        setPurchaseData({ ...purchaseData, [name]: value });
      }
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!partsData.length)
      newErrors.parts = t("You must indicate the parts you used");
    if (!supplierRef) newErrors.supplier = t("Supplier is required");
    if (!purchaseDate) newErrors.date = t("Purchase Date is required");
    return newErrors;
  };

  const handleClearData = () => {
    setPurchaseData({
      date: null,
      documents: [],
      id: "",
      part: null,
      purchase_price: "",
      quantity: "",
      sale_price: "",
      supplier: null,
    });
    setDocuments([]);
    setSelectedPart([]);
    setSelectedSupplier([]);
    setSupplierRef(null);
    setErrors({});
    setNewFiles([]);
    setPurchaseDate(null);
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setNewFiles(files);
  };

  // Mark files for delete operation
  const handleMarkFiles = (filePath) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      filePath: [...(prevFiles.filePath || []), filePath],
    }));
    // console.log(files);
    setDocuments(documents.filter((file) => file !== filePath));
  };

  // Delete new files from list
  const handleNewDeleteFile = (filePath) => {
    setNewFiles(newFiles.filter((file) => file !== filePath));
  };

  // Delete files
  const handleFileDelete = async () => {
    const docsRef = ref(storage, `purchases/${purchaseData.id}/doc`);

    try {
      // List all items (file paths) under the path
      const res = await listAll(docsRef);

      // Delete files that match
      await Promise.all(
        res.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);

          if (files.filePath.includes(downloadURL)) {
            await deleteObject(itemRef);
            // console.log("Deleted:", downloadURL);
          } else {
            // console.log("Not Deleted:", downloadURL);
          }
        })
      );

      // console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  function arrayToObject(arr) {
    const obj = {};
    for (let i = 0; i < arr.length; ++i) {
      obj[i] = arr[i];
    }
    return obj;
  }

  // Set selected IDs
  const getUsedParts = async () => {
    const itemRefs = await Promise.all(
      dataCommit.map(async (itemId) => {
        try {
          const partRef = collection(firestore, "parts");
          const q = query(partRef, where("id", "==", itemId.part.id));
          const querySnapshot = await getDocs(q);
          const partDoc = querySnapshot.docs[0];
          // console.log(partDoc);

          const partsUsedEntry = [
            doc(firestore, partDoc.ref.path),
            itemId.quantity, // Ensure you pass the correct quantity
            itemId.purchasePrice, // Ensure you pass the correct price
            itemId.salePrice,
          ];

          // console.log(partDoc.ref.path); // Reference
          // console.log(partDoc.data()); // Data itself
          // console.log(partsUsedEntry);

          return partsUsedEntry;
        } catch (error) {
          console.error("Error fetching document:", error);
          return null;
        }
      })
    );

    return itemRefs;
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    // Ooii Boii BRAIN FUCK INCOMING
    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Delete marked files
        await handleFileDelete();
        // Upload documents
        const documentUrls = [];
        for (const file of newFiles) {
          const documentRef = ref(
            storage,
            `purchases/${purchaseData.id}/doc/${file.name}`
          );
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          documentUrls.push(url);
        }

        if (documents.length) documents.map((file) => documentUrls.push(file));

        let tempDate;
        if (!purchaseDate) tempDate = purchaseDetails.date.toDate();
        else tempDate = purchaseDate;

        // Set photo and documents URL in purchaseData
        const updatedPurchaseData = {
          id: purchaseData.id,
          date: tempDate,
          documents: documentUrls,
          total_price: purchaseData.total_price,
          parts: arrayToObject(await getUsedParts()),
          supplier: supplierRef,
        };

        // console.log(updatedPurchaseData);

        // Create document reference
        const purchaseRef = collection(firestore, "purchases");
        // Search exact ID within documents
        const q = query(purchaseRef, where("id", "==", updatedPurchaseData.id));
        // Search the query
        const querySnapshot = await getDocs(q);
        // Find the exact document
        const purchaseDoc = querySnapshot.docs[0];
        // Create reference to this document
        const docRef = doc(firestore, "purchases", purchaseDoc.id);
        // Update the document
        await updateDoc(docRef, updatedPurchaseData);

        const oldPartsPurchased = Object.entries(purchaseDetails.parts);

        const partsOldPromises = oldPartsPurchased.map(async ([key, part]) => {
          try {
            // [0]: Dump
            // [1]:
            //  |--> Index 0: Document Reference
            //  |--> Index 1: Quantity
            //  |--> Index 2: Purchase Price
            //  |--> Index 3: Sale Price

            const partDoc = await getDoc(part[0]);
            // console.log("Part Data: ", partDoc.data(), key);
            if (partDoc.exists()) {
              const currentStock = Number(partDoc.data().current_stock);
              const updatedStock =
                currentStock - Number(oldPartsPurchased[key][1][1]);
              // console.log(
              //   "Current Stock: ",
              //   currentStock,
              //   "Updated Stock: ",
              //   updatedStock
              // );
              // Create reference to this document
              const docRef = doc(firestore, "parts", partDoc.id);
              // Update the parts document
              await updateDoc(docRef, {
                current_stock: updatedStock,
              });
            } else {
              console.error(`Part document ${part[0].id} does not exist`);
              onFeedback(t("Failed to Update Part"));
              return null;
            }
          } catch (error) {
            console.error(`Error fetching part document ${part[0].id}:`, error);
            onFeedback(t("Document fetching error"));
            return null;
          }
        });

        await Promise.all(partsOldPromises);

        const partsPurchasedEntries = Object.entries(updatedPurchaseData.parts);

        const partsPurchasedPromises = partsPurchasedEntries.map(
          async ([key, part]) => {
            // console.log(part[0], part[1], part[2], part[3]);
            try {
              // [0]: Dump
              // [1]:
              //  |--> Index 0: Document Reference
              //  |--> Index 1: Quantity
              //  |--> Index 2: Purchase Price
              //  |--> Index 3: Sale Price

              const partDoc = await getDoc(part[0]);
              // console.log("Part Data: ", partDoc.data(), key);
              if (partDoc.exists()) {
                const currentStock = Number(partDoc.data().current_stock);
                const updatedStock = currentStock + Number(part[1]);
                // console.log(
                //   "Current Stock: ",
                //   currentStock,
                //   "Updated Stock: ",
                //   updatedStock
                // );
                // Create reference to this document
                const docRef = doc(firestore, "parts", partDoc.id);
                // Update the parts document
                await updateDoc(docRef, {
                  current_stock: updatedStock,
                  purchase_price: part[2],
                  sale_price: part[3],
                });
              } else {
                console.error(`Part document ${part[0].id} does not exist`);
                onFeedback(t("Failed to Update Part"));
                return null;
              }
            } catch (error) {
              console.error(
                `Error fetching part document ${part[0].id}:`,
                error
              );
              onFeedback(t("Document fetching error"));
              return null;
            }
          }
        );

        await Promise.all(partsPurchasedPromises);

        // Clear Data
        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Update Purchase"));
      } finally {
        setLoading(false);
      }
    } else {
      // console.log(errors);
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  const partsColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "barcode",
      headerName: t("Barcode"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.barcode : "N/A";
      //   },
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.name : "N/A";
      //   },
    },
    {
      field: "category",
      headerName: t("Category"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.category : "N/A";
      //   },
    },
    // {
    //   field: "purchase_price",
    //   headerName: t("Purchase Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    // {
    //   field: "sale_price",
    //   headerName: t("Sale Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    {
      field: "quantity",
      headerName: t("Quantity"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
    {
      field: "purchasePrice",
      headerName: t("Unit Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
    {
      field: "totalPrice",
      headerName: t("Total Cost"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
  ];

  const datagridMaxHeight = 247;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 900,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Edit Purchase")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Parts Field */}
                  <Grid item xs={12}>
                    <Autocomplete
                      options={partNames}
                      fullWidth
                      disablePortal
                      //   multiple
                      id="combobox-part"
                      name="part"
                      color="secondary"
                      value={selectedPart}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={handlePartComboboxChange}
                      defaultValue={[]}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Part")}
                          error={!!errors.selectedPart}
                          helperText={errors.selectedPart}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Quantity Field */}
                  <Grid item xs={4}>
                    <TextField
                      label={t("Quantity")}
                      name="quantity"
                      id="textfield-quantity"
                      color="secondary"
                      required
                      value={quantity}
                      onChange={handleQuantityChange}
                      fullWidth
                      error={!!errors.quantity}
                      helperText={errors.quantity}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>

                  {/* Purchase Price Field */}
                  <Grid item xs={4}>
                    <TextField
                      label={t("Purchase Price")}
                      name="purchase_price"
                      id="textfield-purchase_price"
                      color="secondary"
                      required
                      value={purchaseData.purchase_price}
                      //   defaultValue={recommendedPrice}
                      onChange={handleNumericChange}
                      fullWidth
                      error={!!errors.purchase_price}
                      helperText={errors.purchase_price}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                      InputLabelProps={
                        purchaseData.purchase_price
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  </Grid>

                  {/* Sale Price Field */}
                  <Grid item xs={4}>
                    <TextField
                      label={t("Sale Price")}
                      name="sale_price"
                      color="secondary"
                      required
                      value={purchaseData.sale_price}
                      onChange={handleNumericChange}
                      fullWidth
                      error={!!errors.sale_price}
                      helperText={errors.sale_price}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                      InputLabelProps={
                        purchaseData.sale_price
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  </Grid>

                  {/* Data Grid for Parts Used Details */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        // component="h1"
                        variant="h6"
                        paddingLeft="10px"
                        color="red"
                      >
                        {errors.parts ? errors.parts : ""}
                      </Typography>
                    </Box>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        height: `${datagridMaxHeight}px`,
                        width: "100%",
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        checkboxSelection={true}
                        rows={partsData}
                        columns={partsColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        //   getRowHeight={calculateRowHeight}
                        onRowSelectionModelChange={(newSelection) =>
                          handlePartSelectionChange(newSelection)
                        }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  <HoverImage
                    src={hoveredImage}
                    isVisible={!!hoveredImage}
                    position={hoverPosition}
                  />

                  {/* Supplier Field */}
                  <Grid item xs={8} sm={8}>
                    <Autocomplete
                      options={supplierNames}
                      fullWidth
                      disablePortal
                      //   multiple
                      id="combobox-part"
                      name="part"
                      color="secondary"
                      value={selectedSupplier}
                      filterSelectedOptions
                      //   isOptionEqualToValue={(option, value) =>
                      //     option.label === value.value
                      //   }
                      onChange={handleSupplierChange}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Supplier")}
                          required
                          error={!!errors.supplier}
                          helperText={errors.supplier}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Date Field */}
                  <Grid item xs={4}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enGB}
                    >
                      <DesktopDatePicker
                        label={t("Date")}
                        name="date"
                        id="datepicker-date"
                        color="secondary"
                        value={parseISO(
                          format(purchaseDetails.date.toDate(), "yyyy-MM-dd")
                        )}
                        onChange={(newValue) =>
                          handlePurchaseDayChange(newValue)
                        }
                        required
                        slots={{
                          openPickerButton: StyledButton,
                          date: StyledDate,
                        }}
                        // renderInput={(params) => (
                        //   <TextField
                        //     {...params}
                        //     error={!!errors.date}
                        //     helperText={errors.date}
                        //   />
                        // )}
                        slotProps={{
                          clearable: true,
                          openPickerIcon: { fontSize: "large" },
                          openPickerButton: { color: "secondary" },
                          textField: {
                            variant: "outlined",
                            color: "secondary",
                            fullWidth: true,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.date}
                            helperText={errors.date}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* Save Part Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSavePart}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#0EC6EA",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Save Part")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Remove Part Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleRemovePart}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Remove Part")}
                      </Typography>
                    </Button>
                  </Grid>
                  
                  {/* Upload Documents Button */}
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDocumentsDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#F6B411",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <PostAddOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Documents")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* File List */}
                  <Grid item xs={12} md={4} container justifyContent="center">
                    {/* Old File List */}
                    <Grid item xs={6}>
                      <List
                        sx={{
                          width: "100%",
                          // maxWidth: 360,
                          bgcolor: "background.paper",
                          position: "relative",
                          overflow: "auto",
                          maxHeight: 100,
                          "& ul": { padding: 0 },
                        }}
                        subheader={<li />}
                      >
                        {documents.map((doc, index) => (
                          <ListItem
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            key={index}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleMarkFiles(doc)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            }
                          >
                            <ListItemIcon>
                              <FileIcon />
                            </ListItemIcon>
                            <ListItemText primary={getFileName(doc)} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>

                    {/* New File List */}
                    <Grid item xs={6}>
                      <List
                        sx={{
                          width: "100%",
                          // maxWidth: 360,
                          bgcolor: "background.paper",
                          position: "relative",
                          overflow: "auto",
                          maxHeight: 100,
                          "& ul": { padding: 0 },
                        }}
                        subheader={<li />}
                      >
                        {newFiles.map((doc, index) => (
                          <ListItem
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            key={index}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleNewDeleteFile(doc)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            }
                          >
                            <ListItemIcon>
                              <FileIcon />
                            </ListItemIcon>
                            <ListItemText primary={getFileName(doc.name)} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Save")}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EditPurchase;
