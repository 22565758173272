/* eslint-disable react-hooks/exhaustive-deps */
// src/screens/EditClient.jsx

import React, { useEffect, useState, useCallback } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import {
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDoc,
  getDocs,
} from "firebase/firestore";
import {
  deleteObject,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Switch,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, { CustomToolbar } from "../components/Common";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/material/styles";
// Images
import emptyPhoto from "../assets/no_image.png";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";
// import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const StyledDate = styled(DesktopDatePicker)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color:
    theme.palette.mode === "light"
      ? theme.palette.secondary.dark
      : theme.palette.secondary.light,
}));

const CorporateSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const EditClient = ({ onClose, clientDetails, onFeedback }) => {
  //   const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const { motorcycles } = useData();
  const [selectedRefs, setSelectedRefs] = useState(clientDetails.motorcycles);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedMotos, setSelectedMotos] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [documents, setDocuments] = useState(clientDetails.documents);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [switchText, setSwitchText] = useState(
    clientDetails.corporate ? t("Corporate") : t("Individual")
  );
  let [birthday, setBirthday] = React.useState(null);
  const [checked, setChecked] = React.useState(clientDetails.corporate);
  // const [searchOptions, setSearchOptions] = useState([]);
  // const [selectedSearchOption, setSelectedSearchOption] = useState(null);
  // const [search, setSearch] = useState("");
  // const [filteredMotorcycles, setFilteredMotorcycles] = useState(motorcycles);
  const [clientData, setClientData] = useState({
    address: clientDetails.address,
    birthdate: clientDetails.birthdate,
    city: clientDetails.city,
    corporate: clientDetails.corporate,
    corporation: clientDetails.corporation,
    country: clientDetails.country,
    description: clientDetails.description,
    documents: clientDetails.documents,
    driving_licence: clientDetails.driving_licence,
    full_name: clientDetails.full_name,
    id: clientDetails.id,
    motorcycles: clientDetails.motorcycles,
    phone: clientDetails.phone,
    photo: clientDetails.photo,
    tax_number: clientDetails.tax_number,
    tax_office: clientDetails.tax_office,
  });

  const datagridMaxHeight = 247;

  // Trigger to AutoUpdate
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const fetchData = async () => {
      const itemData = await Promise.all(
        clientDetails.motorcycles.map(async (docRef) => {
          let motoData;
          try {
            const motorcycleDoc = await getDoc(docRef);

            if (motorcycleDoc.exists()) {
              motoData = motorcycleDoc.data();
              // console.log(motorcycleDoc.ref.path); // Reference
              // console.log(motorcycleDoc.data()); // Data itself
              setSelectedMotos((prevSelectedMotos) => [
                ...prevSelectedMotos,
                ` ${motoData.brand} ${motoData.model} - ${motoData.license_plate}`,
              ]);

              return motoData;
            }
          } catch (error) {
            console.error("Error fetching document:", error);
            return null;
          }
        })
      );

      const validData = itemData.filter((ref) => ref !== null);

      const motos = validData.map(
        (ref) => ` ${ref.brand} ${ref.model} - ${ref.license_plate}`
      );
      setSelectedMotos(motos);

      const selectedIds = validData.map((moto) => moto.id);
      setSelectionModel(selectedIds);
    };
    fetchData();
  }, []);

  // Set selected IDs
  const handleSelectionChange = async (newSelection) => {
    const itemRefs = await Promise.all(
      newSelection.map(async (itemId) => {
        try {
          const motoRef = collection(firestore, "motorcycles");
          const q = query(motoRef, where("id", "==", itemId));
          const querySnapshot = await getDocs(q);
          const motoDoc = querySnapshot.docs[0];
          // console.log(motoDoc.ref.path); // Reference
          // console.log(motoDoc.data()); // Data itself
          setSelectedMotos((prevSelectedMotos) => [
            ...prevSelectedMotos,
            `${motoDoc.data().brand} ${motoDoc.data().model} - ${
              motoDoc.data().license_plate
            }`,
          ]);

          setSelectedRefs((prevSelectedRefs) => [
            ...prevSelectedRefs,
            motoDoc.ref.path,
          ]);
          return motoDoc;
        } catch (error) {
          console.error("Error fetching document:", error);
          return null;
        }
      })
    );

    const validItemRefs = itemRefs.filter((ref) => ref !== null);
    // const validItemRefs = itemRefs.map((ref) => ref.ref.path);
    setSelectedRefs(validItemRefs);

    const motos = validItemRefs.map(
      (ref) =>
        ` ${ref.data().brand} ${ref.data().model} - ${ref.data().license_plate}`
    );
    setSelectedMotos(motos);

    const selectedIds = validItemRefs.map((moto) => moto.data().id);
    setSelectionModel(selectedIds);
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  // const handleSearchChange = (event, value) => {
  //   setSelectedSearchOption(value);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // if (name === "search") setSearch(value);
    // else
    setClientData({ ...clientData, [name]: value });
  };

  const handleBirthdayChange = (dateSent) => {
    // Parse the date string to a Date object
    // const parsedDate = parse(dateSent, "dd/MM/yyyy", new Date());
    // Format the date object to ensure it is parsed correctly
    const formattedDate = format(dateSent, "yyyy-MM-dd");
    // Convert the Date object to a Firestore timestamp
    const timestamp = new Date(formattedDate);

    setBirthday(timestamp);
  };

  // Regex pattern to match and format phone number with country code and spaces
  const phoneRegex = /^\+90 \d{3} \d{3} \d{2} \d{2}$/;
  // const phoneRegex = /^\+\d{2} \d{3} \d{3} \d{2} \d{2}$/;

  // Function to format phone number based on regex
  const formatPhoneNumber = (inputNumber) => {
    // Remove all non-digit characters
    // const cleaned = inputNumber.replace(/\D/g, "");
    // Remove "+" and "90"
    let cleaned = inputNumber.replace(/[^\d]/g, "").slice(2);

    // Ignore the first 0 if it exists
    if (cleaned.startsWith("0")) {
      cleaned = cleaned.slice(1);
    }

    // Format the cleaned number according to regex pattern
    // let formattedNumber = "+";
    let formattedNumber = "+90 ";
    for (let i = 0; i < cleaned.length; i++) {
      // Insert spaces according to regex pattern
      // if (i === 2 || i === 5 || i === 8 || i === 10) {
      //   formattedNumber += " ";
      // }
      if (i === 3 || i === 6 || i === 8 || i === 10) {
        formattedNumber += " ";
      }
      formattedNumber += cleaned[i];
    }

    // Limit to 16 characters to prevent overflow
    // Limit to 14 characters to prevent overflow (+90 XXX XXX XX XX)
    return formattedNumber.slice(0, 16);
  };

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(value)) {
      const number = Number.parseFloat(value);
      if (Number.isNaN(number)) {
        console.error("Invalid number");
      } else {
        setClientData({ ...clientData, [name]: value });
      }
    }
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
    !checked ? setSwitchText(t("Corporate")) : setSwitchText(t("Individual"));
  };

  const handlePhoneNumberChange = (event) => {
    let inputNumber = event.target.value;

    // Truncate input beyond 16 characters
    if (inputNumber.length > 17) {
      inputNumber = inputNumber.slice(0, 17);
    }

    // Check if input matches the regex pattern
    if (phoneRegex.test(inputNumber)) {
      setClientData({ ...clientData, phone: inputNumber });
    } else {
      // If not matching, format the input to match the regex pattern
      const formattedNumber = formatPhoneNumber(inputNumber);
      setClientData({ ...clientData, phone: formattedNumber });
    }
  };

  const handlePhotoSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file);
    }
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setNewFiles(files);
  };

  // const columnMapping = [
  //   { field: "brand", headerName: t("Brand") },
  //   { field: "model", headerName: t("Model") },
  //   { field: "year", headerName: t("Year") },
  //   { field: "license_plate", headerName: t("License Plate") },
  //   { field: "chasis_no", headerName: t("Chasis Number") },
  //   { field: "motor_no", headerName: t("Motor Number") },
  // ];

  // Create a mapping from headerName to field
  // const headerToFieldMap = columnMapping.reduce((acc, column) => {
  //   acc[column.headerName] = column.field;
  //   return acc;
  // }, {});

  // const handleSearchButtonClicked = () => {
  //   const field = headerToFieldMap[selectedSearchOption] || "";
  //   if (!selectedSearchOption) setFilteredMotorcycles(motorcycles);
  //   else {
  //     const filtered = motorcycles.filter((motorcycle) => {
  //       return motorcycle[field]
  //         ?.toString()
  //         .toLowerCase()
  //         .includes(search.toLowerCase());
  //     });
  //     setFilteredMotorcycles(filtered);
  //   }
  // };

  const validateFields = () => {
    const newErrors = {};
    if (!clientData.full_name) newErrors.full_name = t("Full name is required");
    // if (!birthday) newErrors.birthdate = t("Birthday is required");
    if (!clientData.phone) newErrors.phone = t("Phone is required");
    if (checked) {
      if (!clientData.city) newErrors.city = t("City is required");
      if (!clientData.country) newErrors.country = t("Country is required");
      if (!clientData.corporation)
        newErrors.corporation = t("Corporation is required");
      if (!clientData.tax_number)
        newErrors.tax_number = t("Tax Number is required");
      if (!clientData.tax_office)
        newErrors.tax_office = t("Tax Office is required");
    }
    return newErrors;
  };

  const handleClearData = () => {
    setClientData({
      address: "",
      birthdate: "",
      city: "",
      corporation: "",
      country: "",
      description: "",
      documents: [],
      driving_licence: "",
      full_name: "",
      id: null,
      motorcycles: [],
      phone: "+90 ",
      photo: "",
      tax_number: "",
      tax_office: "",
    });
    setPhoto(null);
    setNewFiles([]);
    setDocuments([]);
    setSelectedMotos([]);
    setSelectedRefs([]);
    setBirthday(null);
    setErrors({});
  };

  // Mark files for delete operation
  const handleMarkFiles = (filePath) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      filePath: [...(prevFiles.filePath || []), filePath],
    }));
    // console.log(files);
    setDocuments(documents.filter((file) => file !== filePath));
  };

  // Delete new files from list
  const handleNewDeleteFile = (filePath) => {
    setNewFiles(newFiles.filter((file) => file !== filePath));
  };

  // Delete files
  const handleFileDelete = async () => {
    const docsRef = ref(storage, `clients/${clientData.id}/doc`);

    try {
      // List all items (file paths) under the path
      const res = await listAll(docsRef);

      // Delete files that match
      await Promise.all(
        res.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);

          if (files.filePath.includes(downloadURL)) {
            await deleteObject(itemRef);
            console.log("Deleted:", downloadURL);
          } else {
            console.log("Not Deleted:", downloadURL);
          }
        })
      );

      // console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const handlePicDelete = async () => {
    const picsRef = ref(storage, `clients/${clientData.id}/pic`);

    try {
      // List all items (file paths) under the path
      const res = await listAll(picsRef);

      // Delete files that match
      await Promise.all(
        res.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);

          if (downloadURL === clientDetails.photo) {
            await deleteObject(itemRef);
            console.log("Deleted:", downloadURL);
          } else {
            console.log("Not Deleted:", downloadURL);
          }
        })
      );

      // console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Delete marked files
        await handleFileDelete();

        // Upload photo
        let photoUrl = clientData.photo;
        if (photo) {
          handlePicDelete();

          const photoRef = ref(
            storage,
            `clients/${clientData.id}/pic/${photo.name}`
          );
          await uploadBytes(photoRef, photo);
          photoUrl = await getDownloadURL(photoRef);
        }

        // Upload documents
        const documentUrls = [];
        for (const file of newFiles) {
          const documentRef = ref(
            storage,
            `clients/${clientData.id}/doc/${file.name}`
          );
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          documentUrls.push(url);
        }

        if (documents.length) documents.map((file) => documentUrls.push(file));

        const motoRefs = [];
        // console.log("Selected refs: ", selectedRefs);
        // console.log("Pre-loaded: ", clientData.motorcycles);

        for (const ref of selectedRefs) {
          // console.log("Ref itself: ", ref);
          if (clientData.motorcycles.includes(ref)) {
            // console.log("ref = ", ref);
            motoRefs.push(doc(firestore, ref.path));
          } else {
            // console.log("ref.ref = ", ref.ref);
            motoRefs.push(doc(firestore, ref.ref.path));
          }
        }

        if (!birthday) birthday = clientDetails.birthdate;

        // Set photo and documents URL in clientData
        const updatedClientData = {
          ...clientData,
          photo: photoUrl,
          documents: documentUrls,
          birthdate: birthday,
          motorcycles: motoRefs,
        };

        // console.log(updatedClientData);

        // Create document reference
        const clientRef = collection(firestore, "clients");
        // Search exact ID within documents
        const q = query(clientRef, where("id", "==", updatedClientData.id));
        // Search the query
        const querySnapshot = await getDocs(q);
        // Find the exact document
        const clientDoc = querySnapshot.docs[0];
        // Create reference to this document
        const docRef = doc(firestore, "clients", clientDoc.id);
        // Update the document
        await updateDoc(docRef, updatedClientData);
        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Update Client"));
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isSM = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMD = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLG = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXL = useMediaQuery(theme.breakpoints.up("lg"));

  const imageSize = {
    width: isXS ? 200 : isSM ? 250 : isMD ? 350 : isLG ? 400 : isXL ? 500 : 200,
    height: isXS
      ? 200
      : isSM
      ? 250
      : isMD
      ? 350
      : isLG
      ? 400
      : isXL
      ? 500
      : 200,
  };

  const renderPhoto = () => {
    if (photo) {
      return (
        <img
          src={URL.createObjectURL(photo)}
          alt="Client"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (clientDetails.photo) {
      return (
        <img
          src={clientDetails.photo}
          alt="Client"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <img
          src={emptyPhoto}
          alt="Empty"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "model",
      headerName: t("Model"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "year",
      headerName: t("Year"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "odometer",
      headerName: t("Current KM"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "license_plate",
      headerName: t("License Plate"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "chasis_no",
      headerName: t("Chasis Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "motor_no",
      headerName: t("Motor Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  // useEffect(() => {
  //   setSearchOptions(
  //     columns
  //       .filter(
  //         (column) =>
  //           column.field !== "id" &&
  //           column.field !== "description" &&
  //           column.field !== "odometer"
  //       )
  //       .map((column) => t(column.headerName))
  //   );
  // }, [t]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 1200,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* Container */}
                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Edit Client")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Photo Section */}
                  <Grid item xs={4} container justifyContent="center">
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {renderPhoto()}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid container spacing={2}>
                      {/* Full Name Field */}
                      <Grid item xs={6}>
                        <TextField
                          label={t("Full Name")}
                          name="full_name"
                          color="secondary"
                          value={clientData.full_name}
                          onChange={handleInputChange}
                          fullWidth
                          required
                          error={!!errors.full_name}
                          helperText={errors.full_name}
                        />
                      </Grid>

                      {/* Date of Birth Field */}
                      <Grid item xs={6}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={enGB}
                        >
                          <DesktopDatePicker
                            label={t("Date of Birth")}
                            name="birthdate"
                            color="secondary"
                            // defaultValue={clientDetails.birthdate}
                            value={parseISO(
                              format(
                                clientDetails.birthdate.toDate(),
                                "yyyy-MM-dd"
                              )
                            )}
                            onChange={(newValue) =>
                              handleBirthdayChange(newValue)
                            }
                            required
                            error={!!errors.birthdate}
                            helperText={errors.birthdate}
                            slots={{
                              openPickerButton: StyledButton,
                              date: StyledDate,
                            }}
                            slotProps={{
                              clearable: true,
                              openPickerIcon: { fontSize: "large" },
                              openPickerButton: { color: "secondary" },
                              textField: {
                                variant: "outlined",
                                color: "secondary",
                                fullWidth: true,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>

                      {/* Driving License Field */}
                      <Grid item xs={6}>
                        <TextField
                          label={t("Driving License")}
                          name="driving_licence"
                          color="secondary"
                          value={clientData.driving_licence}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>

                      {/* Phone Field */}
                      <Grid item xs={6}>
                        <TextField
                          label={t("Phone")}
                          name="phone"
                          color="secondary"
                          value={clientData.phone}
                          onChange={handlePhoneNumberChange}
                          fullWidth
                          required
                          error={!!errors.phone}
                          helperText={errors.phone}
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">
                          //       <Flag
                          //         code="NL"
                          //         gradient="real-linear"
                          //         size="m"
                          //         hasDropShadow
                          //       />
                          //     </InputAdornment>
                          //   ),
                          // }}
                        />
                      </Grid>

                      {/* Address Field */}
                      <Grid item xs={8}>
                        <TextField
                          label={t("Address")}
                          name="address"
                          color="secondary"
                          value={clientData.address}
                          onChange={handleInputChange}
                          fullWidth
                          multiline
                        />
                      </Grid>

                      {/* City Field */}
                      <Grid item xs={2}>
                        <TextField
                          label={t("City")}
                          name="city"
                          color="secondary"
                          value={clientData.city}
                          required={checked}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!errors.city}
                          helperText={errors.city}
                        />
                      </Grid>

                      {/* Country Field */}
                      <Grid item xs={2}>
                        <TextField
                          label={t("Country")}
                          name="country"
                          color="secondary"
                          value={clientData.country}
                          required={checked}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!errors.country}
                          helperText={errors.country}
                        />
                      </Grid>

                      {/* Description Field */}
                      <Grid item xs={10}>
                        <TextField
                          label={t("Description")}
                          name="description"
                          color="secondary"
                          value={clientData.description}
                          onChange={handleInputChange}
                          fullWidth
                          multiline
                        />
                      </Grid>

                      {/* Corporate Switch */}
                      <Grid item xs={2} marginTop={"7px"}>
                        <FormControlLabel
                          control={
                            <CorporateSwitch
                              onChange={handleSwitchChange}
                              checked={checked}
                            />
                          }
                          label={switchText}
                        />
                      </Grid>

                      {/* Corporation Name */}
                      {checked && (
                        <Grid item xs={4}>
                          <TextField
                            label={t("Corporation")}
                            name="corporation"
                            color="secondary"
                            value={clientData.corporation}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            error={!!errors.corporation}
                            helperText={errors.corporation}
                          />
                        </Grid>
                      )}

                      {/* Tax Number */}
                      {checked && (
                        <Grid item xs={4}>
                          <TextField
                            label={t("Tax Number")}
                            name="tax_number"
                            color="secondary"
                            value={clientData.tax_number}
                            onChange={handleNumericChange}
                            fullWidth
                            required
                            error={!!errors.tax_number}
                            helperText={errors.tax_number}
                          />
                        </Grid>
                      )}

                      {/* Tax Office */}
                      {checked && (
                        <Grid item xs={4}>
                          <TextField
                            label={t("Tax Office")}
                            name="tax_office"
                            color="secondary"
                            value={clientData.tax_office}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            error={!!errors.tax_office}
                            helperText={errors.tax_office}
                          />
                        </Grid>
                      )}

                      {/* Upload Photo Button */}
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          onClick={() => setOpenPhotoDialog(true)}
                          fullWidth
                          sx={{
                            mt: 1,
                            mb: 0,
                            backgroundColor: "#0EC6EA",
                            color: "#fff",
                            height: "50px",
                          }}
                        >
                          <AddAPhotoOutlinedIcon />
                          <Typography
                            component="h1"
                            variant="r16"
                            paddingLeft="10px"
                          >
                            {t("Upload Photo")}
                          </Typography>
                        </Button>
                      </Grid>

                      {/* Upload Documents Button */}
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          onClick={() => setOpenDocumentsDialog(true)}
                          fullWidth
                          sx={{
                            mt: 1,
                            mb: 0,
                            backgroundColor: "#F6B411",
                            color: "#fff",
                            height: "50px",
                          }}
                        >
                          <PostAddOutlinedIcon />
                          <Typography
                            component="h1"
                            variant="r16"
                            paddingLeft="10px"
                          >
                            {t("Upload Documents")}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Old File List */}
                  <Grid item xs={6}>
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 100,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleMarkFiles(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={getFileName(doc)} />
                        </ListItem>
                      ))}
                      {/* {[0, 1, 2, 3, 4].map((sectionId) => (
                        <li key={`section-${sectionId}`}>
                          <ul>
                            <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            {[0, 1, 2].map((item) => (
                              <ListItem key={`item-${sectionId}-${item}`}>
                                <ListItemText primary={`Item ${item}`} />
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      ))} */}
                    </List>
                  </Grid>

                  {/* New File List */}
                  <Grid item xs={6}>
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 100,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {newFiles.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleNewDeleteFile(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={getFileName(doc.name)} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Search Options Autocomplete */}
                  {/* <Grid item xs={4}>
                    <Autocomplete
                      options={searchOptions}
                      fullWidth
                      disablePortal
                      id="combobox-search"
                      name="search"
                      color="secondary"
                      value={selectedSearchOption || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleSearchChange(
                          "searchOption",
                          newValue ? newValue : ""
                        )
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Search with...")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid> */}

                  {/* Search TextField */}
                  {/* <Grid item xs={6}>
                    <TextField
                      label={t("Search")}
                      name="search"
                      color="secondary"
                      value={search}
                      onChange={handleInputChange}
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchButtonClicked();
                        }
                      }}
                    />
                  </Grid> */}

                  {/* Search Button */}
                  {/* <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleSearchButtonClicked}
                      fullWidth
                      sx={{
                        backgroundColor: "#555555",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <ManageSearchOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft={"10px"}
                        sx={{
                          fontSize: {
                            xs: "0.5rem", // font size for extra-small screens
                            sm: "0.7rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Search")}
                      </Typography>
                    </Button>
                  </Grid> */}

                  {/* Data Grid for Motorcycle Details */}
                  <Grid item xs={12}>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        height: `${datagridMaxHeight}px`,
                        width: "100%", // Adjust width as needed
                        "& .MuiDataGrid-root": {
                          overflow: "auto",
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        rows={motorcycles}
                        columns={columns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection={true}
                        disableSelectionOnClick
                        onRowSelectionModelChange={(newSelection) =>
                          handleSelectionChange(newSelection)
                        }
                        rowSelectionModel={selectionModel}
                        style={{
                          height: `${datagridMaxHeight}px`,
                          width: "100%",
                        }}
                      />
                    </Box>
                  </Grid>

                  {/* Selected Motorcycles Field */}
                  <Grid item xs={12}>
                    <TextField
                      label={t("Please Select Motorcycles From Table Above")}
                      name="motorcycles"
                      color="secondary"
                      value={selectedMotos}
                      fullWidth
                      multiline
                      disabled
                    />
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Update Client")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openPhotoDialog}
                  onClose={() => setOpenPhotoDialog(false)}
                >
                  <DialogTitle> {t("Upload Photo")} </DialogTitle>
                  <DialogContent>
                    <input type="file" onChange={handlePhotoSelection} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenPhotoDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EditClient;
