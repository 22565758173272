// src/i18n.jsx
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translation files
import translationEN from "./locales/en/translation.json";
import translationTR from "./locales/tr/translation.json";
import translationIT from "./locales/it/translation.json";
import translationJP from "./locales/jp/translation.json";

// Define resources
const resources = {
  tr: {
    translation: translationTR,
  },
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
  jp: {
    translation: translationJP,
  },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: "tr", // Default language
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
