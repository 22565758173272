// src/screens/DeleteAppointment.jsx

import React, { useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  //   useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
} from "@mui/material";
// import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";

const DeleteAppointment = ({ onClose, appointmentToDelete, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  //   const theme = useTheme();
  //   const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    // Get Parts Collection
    const appointmentRef = collection(firestore, "appointments");
    // Search exact ID within documents
    const q = query(appointmentRef, where("id", "==", appointmentToDelete));
    // Search the query
    const querySnapshot = await getDocs(q);
    // Find the exact document
    const appointmentDoc = querySnapshot.docs[0];
    // Create reference to this document
    const docRef = doc(firestore, "appointments", appointmentDoc.id);
    // Delete document
    await deleteDoc(docRef);
    setLoading(false);
    onFeedback(t("Operation Successfull!"));
    handleClose();
  };

  const handleCancel = async () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                // backgroundImage: 'url("https://source.unsplash.com/random")',
                // backgroundSize: "cover",
                // backgroundPosition: "center",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography component="h1" variant="r16">
                        {t("Delete Part")}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Typography component="h1" variant="r32">
                      {t("Are you sure about deleting this appointment?")}
                    </Typography>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Confirm")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default DeleteAppointment;
