// src/screens/Motorcycles.jsx

import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Box, useTheme, Typography, Grid, Button } from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import CustomLinearProgress, {
  CustomMessage,
  CustomToolbar,
} from "../components/Common";
import AddMoto from "./AddMoto";
import ViewMotoDetails from "./ViewMotoDetails";
import EditMoto from "./EditMoto";
import { useData } from "../components/DataProvider";
import HoverImage from "../components/HoverImage";
import "../App.css";
import ViewMotorcycleWorks from "./ViewMotorcycleWorks";

const Motorcycles = () => {
  const { currentUser } = useAuth();
  const { motorcycles, loading } = useData();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isWorksOpen, setIsWorksOpen] = useState(false);
  const [motoDetails, setMotoDetails] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });

  const closeModal = () => {
    setIsAddOpen(false);
    setIsUpdateOpen(false);
    setIsDetailsOpen(false);
    setIsWorksOpen(false);
  };

  // Set selected IDs
  const handleSelectionChange = (newSelection) => {
    setSelectedRowIds(newSelection);
  };

  const handleCreateNewMoto = async () => {
    setIsAddOpen(true);
  };

  const handleUpdateMoto = async () => {
    if (selectedRowIds[0]) {
      // Get Motocycles Collection
      const motoRef = collection(firestore, "motorcycles");
      // Search exact ID within documents
      const q = query(motoRef, where("id", "==", selectedRowIds[0]));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const motoDoc = querySnapshot.docs[0].data();
      // Set ID to edit
      setMotoDetails(motoDoc);
      // Open Modal
      setIsUpdateOpen(true);
    }
  };

  const handleViewDetails = async () => {
    if (selectedRowIds[0]) {
      // Get Motocycles Collection
      const motoRef = collection(firestore, "motorcycles");
      // Search exact ID within documents
      const q = query(motoRef, where("id", "==", selectedRowIds[0]));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const motoDoc = querySnapshot.docs[0].data();
      // Set ID to edit
      setMotoDetails(motoDoc);
      // Open Modal
      setIsDetailsOpen(true);
    }
  };

  const handleViewWorks = async () => {
    if (selectedRowIds[0]) {
      // Get Motocycles Collection
      const motoRef = collection(firestore, "motorcycles");
      // Search exact ID within documents
      const q = query(motoRef, where("id", "==", selectedRowIds[0]));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const motorcycleRef = querySnapshot.docs[0].ref.path;
      // Set Ref to View
      setMotoDetails(motorcycleRef);
      // Open Modal
      setIsWorksOpen(true);
    }
  };

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 85,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "model",
      headerName: t("Model"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "year",
      headerName: t("Year"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "odometer",
      headerName: t("Current KM"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "license_plate",
      headerName: t("License Plate"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "chasis_no",
      headerName: t("Chasis Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "motor_no",
      headerName: t("Motor Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    // {
    //   field: "documents",
    //   headerName: t("Documents"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 400,
    //   renderCell: (params) => (
    //     <>
    //       {params.value.map((doc, index) => (
    //         <ListItem
    //           // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
    //           key={index}
    //           secondaryAction={
    //             <IconButton
    //               edge="end"
    //               aria-label="delete"
    //               onClick={() => handleFileOpenClick(doc)}
    //             >
    //               <FileIcon />
    //             </IconButton>
    //           }
    //         >
    //           <ListItemText primary={getFileName(doc)} />
    //         </ListItem>
    //       ))}
    //     </>
    //   ),
    // },
  ];

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const datagridMaxHeight = 616;

  if (currentUser.purchasedProducts.includes("motorcyclesModule")) {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95vh",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {/* Feedback Mechanism */}
              <CustomMessage
                open={openMessage}
                onClose={handleMessageClose}
                message={message}
              />
              <Grid item xs={2}>
                <Box mb="30px">
                  <Typography
                    variant="h2"
                    color={colors.grey[200]}
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // font size for extra-small screens
                        sm: "2rem", // font size for small screens
                        md: "3rem", // font size for medium screens
                        lg: "3.5rem", // font size for large screens
                        xl: "3.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                    }}
                  >
                    {t("Motorcycles")}
                  </Typography>
                  <Typography
                    variant="r16"
                    color={colors.greenAccent[400]}
                    sx={{
                      fontSize: {
                        xs: "1rem", // font size for extra-small screens
                        sm: "1.2rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t("All Motorcycles")}
                  </Typography>
                </Box>
              </Grid>

              {/* Create Moto Modal */}
              {isAddOpen && (
                <AddMoto onClose={closeModal} onFeedback={handleFeedback} />
              )}

              {/* View Details Modal */}
              {isDetailsOpen && (
                <ViewMotoDetails
                  onClose={closeModal}
                  motoDetails={motoDetails}
                />
              )}

              {/* Update Moto Modal */}
              {isUpdateOpen && (
                <EditMoto
                  onClose={closeModal}
                  motoDetails={motoDetails}
                  onFeedback={handleFeedback}
                />
              )}

              {/* View Details Modal */}
              {isWorksOpen && (
                <ViewMotorcycleWorks
                  onClose={closeModal}
                  motoRef={motoDetails}
                />
              )}

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Details Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.motorcycles % 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleViewDetails}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.greenAccent[600],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.greenAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.6rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("View Details")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* Create New Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.motorcycles & 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleCreateNewMoto}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.blueAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.blueAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.6rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Create New Moto")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* Update Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.motorcycles > 3) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleUpdateMoto}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.redAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.redAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.6rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Edit Moto")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* View Works Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.motorcycles % 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleViewWorks}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.redAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.redAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.6rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("View Works")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <Box
            m="0px 0px 0px 0px"
            display="flex"
            flex-direction="column"
            //height="100vh" /* Full height of the viewport */
            min-height="400" /* Ensure it can shrink */
            width="100%"
            overflow={"auto"}
            sx={{
              height: {
                xs: `${datagridMaxHeight / 1.25}px`,
                sm: `${datagridMaxHeight / 1.25}px`,
                md: `${datagridMaxHeight}px`,
                lg: `${datagridMaxHeight}px`,
                xl: `${datagridMaxHeight * 1.5}px`,
              },
              width: "100%",
              "& .MuiDataGrid-root": {
                fontSize: {
                  xs: "0.7rem", // font size for extra-small screens
                  sm: "0.8rem", // font size for small screens
                  md: "0.9rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
                ...(theme.palette.mode === "dark"
                  ? {
                      border: "1px solid rgba(62, 67, 150, 1)",
                    }
                  : { border: "1px solid rgba(175, 63, 59, 1)" }),
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                fontFamily: "Poppins",
                fontSize: {
                  xs: "0.7rem", // font size for extra-small screens
                  sm: "0.8rem", // font size for small screens
                  md: "0.9rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                fontFamily: "Poppins",
                fontSize: {
                  xs: "0.5rem", // font size for extra-small screens
                  sm: "0.7rem", // font size for small screens
                  md: "0.8rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
                ...(theme.palette.mode === "dark"
                  ? {
                      color: colors.greenAccent[500],
                    }
                  : { color: colors.redAccent[500] }),
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                ...(theme.palette.mode === "dark"
                  ? {
                      backgroundColor: colors.blueAccent[700],
                    }
                  : { backgroundColor: colors.redAccent[800] }),
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-cell:hover": {
                ...(theme.palette.mode === "dark"
                  ? {
                      color: colors.blueAccent[500],
                    }
                  : { color: colors.redAccent[500] }),
              },
            }}
          >
            <DataGrid
              columnVisibilityModel={{
                id: false,
              }}
              checkboxSelection={true}
              disableMultipleRowSelection
              rows={motorcycles}
              columns={columns}
              slots={{
                toolbar: CustomToolbar,
                loadingOverlay: CustomLinearProgress,
              }}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[10, 20]}
              disableSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                handleSelectionChange(newSelection)
              }
              style={{ height: "100%", width: "100%" }}
            />
            <HoverImage
              src={hoveredImage}
              isVisible={!!hoveredImage}
              position={hoverPosition}
            />
          </Box>
        </Box>
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95vh",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {/* Feedback Mechanism */}
              <CustomMessage
                open={openMessage}
                onClose={handleMessageClose}
                message={message}
              />
              <Grid item xs={2}>
                <Box mb="30px">
                  <Typography
                    variant="h2"
                    color={colors.redAccent[500]}
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // font size for extra-small screens
                        sm: "2rem", // font size for small screens
                        md: "3rem", // font size for medium screens
                        lg: "3.5rem", // font size for large screens
                        xl: "3.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("ACCESS DENIED!")}
                  </Typography>
                  <Typography
                    variant="r16"
                    color={colors.grey[300]}
                    sx={{
                      fontSize: {
                        xs: "0.9rem", // font size for extra-small screens
                        sm: "1.1rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t(
                      "Unauthorized login attempt! You may purchase this module."
                    )}
                  </Typography>
                </Box>
              </Grid>

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Buy Button */}
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  // onClick={handleRedirection("motorcycles")}
                  fullWidth
                  sx={{
                    mt: {
                      xs: 0,
                      sm: 0,
                      md: 1,
                      lg: 1,
                      xl: 1,
                    },
                    backgroundColor: colors.greenAccent[600],
                    color: colors.grey[100],
                    height: "50px",
                    "&:hover": {
                      backgroundColor: colors.greenAccent[800],
                    },
                  }}
                >
                  <Typography
                    component="h1"
                    variant="r16"
                    sx={{
                      fontSize: {
                        xs: "0.7rem", // font size for extra-small screens
                        sm: "0.8rem", // font size for small screens
                        md: "0.9rem", // font size for medium screens
                        lg: "1.2rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                    }}
                  >
                    {t("Purchase This Module")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Motorcycles;
