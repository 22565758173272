// src/firebaseConfig.jsx

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD93tkzfYxEaQ8Qn1YaBxIEf5HvGKko9p8",
  authDomain: "bnb-spada-app.firebaseapp.com",
  projectId: "bnb-spada-app",
  storageBucket: "bnb-spada-app.appspot.com",
  messagingSenderId: "137132728811",
  appId: "1:137132728811:web:2af911fcac57a6d3a6f9ae",
  measurementId: "G-1B69JFNHCC",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBMK5hmZFbmEsuVmRUuP6u5pH-KeIhEyUY",
//   authDomain: "bnb-garage-app.firebaseapp.com",
//   projectId: "bnb-garage-app",
//   storageBucket: "bnb-garage-app.appspot.com",
//   messagingSenderId: "129918018453",
//   appId: "1:129918018453:web:04226015b413a6b1c3cc9c",
//   measurementId: "G-MZEC3CHLC0"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { firestore, storage, auth };
