// src/screens/ViewSaleDetails.jsx

import React, { useEffect, useState, useCallback } from "react";
// import { useAuth } from "../components/AuthContext";
import { storage } from "../firebaseConfig";
import { getDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import {
  Avatar,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemText,
} from "@mui/material";
import { format } from "date-fns";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, {
  CustomToolbar,
  BankChip,
} from "../components/Common";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import HoverImage from "../components/HoverImage";
import "../App.css";

const ViewSaleDetails = ({ onClose, saleDetails }) => {
  //   const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientLoading, setClientLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  // const [saleDate, setSaleDate] = useState("");
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const saleDate = format(saleDetails.date.toDate(), "dd/MM/yyyy");
  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const handleFileOpenClick = (url) => {
    window.open(url, getFileName(url), "noopener,noreferrer");
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  const handleCancel = async () => {
    handleClose();
  };

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  // Trigger to AutoUpdate
  useEffect(() => {
    const fetchData = async () => {
      let partsData = [];
      try {
        setLoading(true);

        const partEntries = Object.entries(saleDetails.parts);
        // console.log("partsUsedEntries: ", partsUsedEntries);

        const partsPurchasedPromises = partEntries.map(async ([key, part]) => {
          try {
            // console.log("Fetching part: ", part[0]);
            // Index 0: Document Reference
            // Index 1: Quantity
            // Index 2: Purchase Price
            // Index 3: Sale Price

            const partDoc = await getDoc(part[0]);
            // console.log("Part Data: ", partDoc.data(), key);
            if (partDoc.exists()) {
              return {
                id: key, // Unique identifier for DataGrid rows
                part: partDoc.data(),
                quantity: part[1],
                salePrice: part[2],
              };
              // biome-ignore lint/style/noUselessElse: <explanation>
            } else {
              console.error(`Part document ${part[0].id} does not exist`);
              return null;
            }
          } catch (error) {
            console.error(`Error fetching part document ${part[0].id}:`, error);
            return null;
          }
        });

        partsData = await Promise.all(partsPurchasedPromises);
      } catch {
        console.error(`Sale document ${saleDetails.part} does not exist`);
      } finally {
        setLoading(false);
      }

      const transformedPartsData = partsData.map((item) => ({
        id: item.id,
        photo: item.part.photo,
        barcode: item.part.barcode,
        brand: item.part.brand,
        origin: item.part.origin,
        name: item.part.name,
        category: item.part.category,
        internal_pn: item.part.internal_pn,
        unit: item.part.unit,
        minimum_stock: item.part.minimum_stock,
        current_stock: item.part.current_stock,
        purchase_price: item.part.purchase_price,
        sale_price: item.salePrice,
        quantity: item.quantity,
        totalPrice: item.salePrice * item.quantity,
      }));
      setData(transformedPartsData);
    };
    fetchData();
  }, [saleDetails]);

  // Trigger to AutoUpdate
  useEffect(() => {
    const fetchData = async () => {
      try {
        setClientLoading(true);
        // setPurchaseDate(format(purchaseDetails.date.toDate(), "dd/MM/yyyy"));
        const clientDoc = await getDoc(saleDetails.client);

        if (clientDoc.exists()) {
          // console.log(partDoc.data());
          setClient([clientDoc.data()]);
        }
      } catch {
        console.error(`Purchase document ${saleDetails.client} does not exist`);
      } finally {
        setClientLoading(false);
      }
    };
    fetchData();
  }, [saleDetails]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "barcode",
      headerName: t("Barcode"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "origin",
      headerName: t("Origin"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "category",
      headerName: t("Category"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "internal_pn",
      headerName: t("Internal Part Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "unit",
      headerName: t("Unit"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "minimum_stock",
      headerName: t("Minimum Stock"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => <BankChip {...params} color="info" />,
    },
    {
      field: "current_stock",
      headerName: t("Stock"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => (
        <div>
          {params.value ? (
            <div>
              {params.value <= params.row.minimum_stock ? (
                <BankChip {...params} color="error" />
              ) : (
                <BankChip {...params} color="success" />
              )}
            </div>
          ) : (
            <BankChip value="0" color="error" />
          )}
        </div>
      ),
    },
    {
      field: "purchase_price",
      headerName: t("Purchase Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => <div>{params.value ? params.value : 0}</div>,
    },
    {
      field: "sale_price",
      headerName: t("Sale Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => <div>{params.value ? params.value : 0}</div>,
    },
    {
      field: "quantity",
      headerName: t("Quantity"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => <div>{params.value ? params.value : 0}</div>,
    },
    {
      field: "totalPrice",
      headerName: t("Total Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => <div>{params.value ? params.value : 0}</div>,
    },
  ];

  const clientColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: t("Full Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "corporate",
      headerName: t("Type"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
      valueGetter: (params) => {
        const type = params;
        return type ? t("Corporate") : t("Individual");
      },
    },
    // {
    //   field: "motorcycles_all",
    //   headerName: t("Motorcycles"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 200,
    //   valueGetter: (params) => {
    //     const motorcycles = params;

    //     return motorcycles
    //       ? motorcycles
    //           .map((motorcycle) => `${motorcycle.brand} - ${motorcycle.model}`)
    //           .join(", ")
    //       : "N/A";
    //   },
    // },
    {
      field: "phone",
      headerName: t("Phone Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
  ];

  const datagridMaxHeight = 247;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 1200,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* Container */}
                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("View Details")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Date Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Date of Purchase")}
                      name="date"
                      color="secondary"
                      readOnly
                      value={saleDate}
                      fullWidth
                    />
                  </Grid>

                  {/* Total Price Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Total Price")}
                      name="total_price"
                      color="secondary"
                      readOnly
                      value={saleDetails.total_price}
                      fullWidth
                    />
                  </Grid>

                  {/* File List */}
                  <Grid item xs={12}>
                    <List>
                      {saleDetails.documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleFileOpenClick(doc)}
                            >
                              <FileIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={getFileName(doc)} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Data Grid for Part Details */}
                  <Grid item xs={12}>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        height: `${datagridMaxHeight * 1.65}px`,
                        width: "100%",
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        rows={data}
                        columns={columns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        //   getRowHeight={calculateRowHeight}
                        //   onRowSelectionModelChange={(newSelection) =>
                        //     handleSelectionChange(newSelection)
                        //   }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  {/* Data Grid for Client Details */}
                  <Grid item xs={12}>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        rows={client}
                        columns={clientColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={clientLoading}
                        pageSize={5}
                        autoHeight
                        autoWidth
                        disableSelectionOnClick
                        //   getRowHeight={calculateRowHeight}
                        //   onRowSelectionModelChange={(newSelection) =>
                        //     handleSelectionChange(newSelection)
                        //   }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  <HoverImage
                    src={hoveredImage}
                    isVisible={!!hoveredImage}
                    position={hoverPosition}
                  />

                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Close")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ViewSaleDetails;
