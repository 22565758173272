// src/screens/EditSupplier.jsx

import React, { useCallback, useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import {
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  deleteObject,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO, setHours, setMinutes, setSeconds } from "date-fns";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/material/styles";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";
// Images
import emptyPhoto from "../assets/no_image.png";

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const StyledDate = styled(DesktopDatePicker)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color:
    theme.palette.mode === "light"
      ? theme.palette.secondary.dark
      : theme.palette.secondary.light,
}));

const EditSupplier = ({ onClose, supplierDetails, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { categories } = useData();
  let [businessStartDate, setBusinessStartDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [photo, setPhoto] = useState(null);
  const [documents, setDocuments] = useState(supplierDetails.documents);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [suppliersData, setSuppliersData] = useState({
    address: supplierDetails.address,
    business_commencement: supplierDetails.business_commencement,
    categories: supplierDetails.categories,
    contact_name: supplierDetails.contact_name,
    contentment: supplierDetails.contentment,
    corporation: supplierDetails.corporation,
    description: supplierDetails.description,
    documents: supplierDetails.documents,
    email: supplierDetails.email,
    id: supplierDetails.id,
    phone: supplierDetails.phone,
    photo: supplierDetails.photo,
  });

  const handleDateChange = (dateSent) => {
    if (dateSent === null || Number.isNaN(dateSent.getTime())) {
      console.error("Not a valid date!");
    } else {
      // Get the current time
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();

      // Combine the selected date with the current time
      let combinedDate = setHours(dateSent, currentHour);
      combinedDate = setMinutes(combinedDate, currentMinute);
      combinedDate = setSeconds(combinedDate, currentSecond);

      // Convert the combined date-time to a Firestore timestamp
      const timestamp = new Date(combinedDate);

      setBusinessStartDate(timestamp);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setSuppliersData({ ...suppliersData, [name]: value });
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    setErrors({ ...errors, [name]: "" });
    const number = Number.parseFloat(value);
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(value) && number <= 100) {
      const number = Number.parseFloat(value);
      if (Number.isNaN(number)) {
        console.error("Invalid number");
      } else {
        setSuppliersData({ ...suppliersData, [name]: value });
      }
    }
  };

  const handleCategoriesComboboxChange = (name, newValue) => {
    setErrors({ ...errors, [name]: "" });
    setSuppliersData((prevpartData) => ({
      ...prevpartData,
      [name]: newValue,
    }));
  };

  // Regex pattern to match and format phone number with country code and spaces
  const phoneRegex = /^\+90 \d{3} \d{3} \d{2} \d{2}$/;
  // const phoneRegex = /^\+\d{2} \d{3} \d{3} \d{2} \d{2}$/;

  // Function to format phone number based on regex
  const formatPhoneNumber = (inputNumber) => {
    // Remove all non-digit characters
    // const cleaned = inputNumber.replace(/\D/g, "");
    // Remove "+" and "90"
    let cleaned = inputNumber.replace(/[^\d]/g, "").slice(2);

    // Ignore the first 0 if it exists
    if (cleaned.startsWith("0")) {
      cleaned = cleaned.slice(1);
    }

    // Format the cleaned number according to regex pattern
    // let formattedNumber = "+";
    let formattedNumber = "+90 ";
    for (let i = 0; i < cleaned.length; i++) {
      // Insert spaces according to regex pattern
      // if (i === 2 || i === 5 || i === 8 || i === 10) {
      //   formattedNumber += " ";
      // }
      if (i === 3 || i === 6 || i === 8 || i === 10) {
        formattedNumber += " ";
      }
      formattedNumber += cleaned[i];
    }

    // Limit to 16 characters to prevent overflow
    // Limit to 14 characters to prevent overflow (+90 XXX XXX XX XX)
    return formattedNumber.slice(0, 16);
  };

  const handlePhoneNumberChange = (event) => {
    let inputNumber = event.target.value;
    setErrors({ ...errors, phone: "" });
    // Truncate input beyond 16 characters
    if (inputNumber.length > 17) {
      inputNumber = inputNumber.slice(0, 17);
    }

    // Check if input matches the regex pattern
    if (phoneRegex.test(inputNumber)) {
      setSuppliersData({ ...suppliersData, phone: inputNumber });
    } else {
      // If not matching, format the input to match the regex pattern
      const formattedNumber = formatPhoneNumber(inputNumber);
      setSuppliersData({ ...suppliersData, phone: formattedNumber });
    }
  };

  const handlePhotoSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file);
    }
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setNewFiles(files);
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  const validateFields = () => {
    const newErrors = {};
    if (!suppliersData.corporation)
      newErrors.corporation = t("Corporation is required");
    if (suppliersData.phone.length < 17)
      newErrors.phone = t("Phone is required");
    if (!businessStartDate && !suppliersData.business_commencement)
      newErrors.business_commencement = t("Business Commencement is required");
    return newErrors;
  };

  const handleClearData = () => {
    setSuppliersData({
      address: "",
      business_commencement: "",
      categories: [],
      contact_name: "",
      contentment: "",
      corporation: "",
      description: "",
      documents: [],
      email: "",
      id: "",
      phone: "+90 ",
      photo: "",
    });
    setBusinessStartDate(null);
    setFiles([]);
    setNewFiles([]);
    setDocuments([]);
    setPhoto(null);
    setErrors({});
  };

  const imageSize = {
    width: 400, // Adjust the width as needed
    height: 400, // Adjust the height as needed
  };

  const renderPhoto = () => {
    if (photo) {
      return (
        <img
          src={URL.createObjectURL(photo)}
          alt="Client"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (supplierDetails.photo) {
      return (
        <img
          src={supplierDetails.photo}
          alt="Client"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <img
          src={emptyPhoto}
          alt="Empty"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
    }
  };

  // Mark files for delete operation
  const handleMarkFiles = (filePath) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      filePath: [...(prevFiles.filePath || []), filePath],
    }));
    // console.log(files);
    setDocuments(documents.filter((file) => file !== filePath));
  };

  // Delete new files from list
  const handleNewDeleteFile = (filePath) => {
    setNewFiles(newFiles.filter((file) => file !== filePath));
  };

  // Delete files
  const handleFileDelete = async () => {
    const docsRef = ref(storage, `suppliers/${suppliersData.id}/doc`);

    try {
      // List all items (file paths) under the path
      const res = await listAll(docsRef);

      // Delete files that match
      if (res) {
        await Promise.all(
          res.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);

            if (files.filePath.includes(downloadURL)) {
              await deleteObject(itemRef);
              // console.log("Deleted:", downloadURL);
            } else {
              // console.log("Not Deleted:", downloadURL);
            }
          })
        );
      }
      // console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const handlePicDelete = async () => {
    const picsRef = ref(storage, `suppliers/${suppliersData.id}/pic`);

    try {
      // List all items (file paths) under the path
      const res = await listAll(picsRef);

      // Delete files that match
      if (res) {
        await Promise.all(
          res.items.map(async (itemRef) => {
            const downloadURL = await getDownloadURL(itemRef);

            if (downloadURL === supplierDetails.photo) {
              await deleteObject(itemRef);
              // console.log("Deleted:", downloadURL);
            } else {
              // console.log("Not Deleted:", downloadURL);
            }
          })
        );
      }
      // console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Delete marked files
        await handleFileDelete();

        // Upload photo
        let photoUrl = suppliersData.photo;
        if (photo) {
          handlePicDelete();

          const photoRef = ref(
            storage,
            `suppliers/${suppliersData.id}/pic/${photo.name}`
          );
          // console.log(photoRef);
          await uploadBytes(photoRef, photo);
          photoUrl = await getDownloadURL(photoRef);
        }

        // Upload documents
        const documentUrls = [];
        for (const file of newFiles) {
          const documentRef = ref(
            storage,
            `suppliers/${suppliersData.id}/doc/${file.name}`
          );
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          documentUrls.push(url);
        }

        if (documents.length) documents.map((file) => documentUrls.push(file));

        if (!businessStartDate)
          businessStartDate = supplierDetails.business_commencement;

        // Set photo and documents URL in partData
        const updatedSupplierData = {
          ...suppliersData,
          business_commencement: businessStartDate,
          documents: documentUrls,
          photo: photoUrl,
        };

        // console.log(updatedSupplierData);

        // Create document reference
        const supplierRef = collection(firestore, "suppliers");
        // Search exact ID within documents
        const q = query(supplierRef, where("id", "==", updatedSupplierData.id));
        // Search the query
        const querySnapshot = await getDocs(q);
        // Find the exact document
        const supplierDoc = querySnapshot.docs[0];
        // Create reference to this document
        const docRef = doc(firestore, "suppliers", supplierDoc.id);
        // Update the document
        await updateDoc(docRef, updatedSupplierData);

        handleClearData();
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Update Supplier"));
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Edit Supplier")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Photo Section */}
                  <Grid item xs={12} md={12} container justifyContent="center">
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {renderPhoto()}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Corporation Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Corporation")}
                      name="corporation"
                      color="secondary"
                      required
                      value={suppliersData.corporation}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.corporation}
                      helperText={errors.corporation}
                    />
                  </Grid>

                  {/* Contact Name Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Contact Name")}
                      name="contact_name"
                      color="secondary"
                      value={suppliersData.contact_name}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.contact_name}
                      helperText={errors.contact_name}
                    />
                  </Grid>

                  {/* E-Mail Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("E-Mail")}
                      name="email"
                      color="secondary"
                      value={suppliersData.email}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Grid>

                  {/* Phone Field */}
                  <Grid item xs={6}>
                    <TextField
                      label={t("Phone")}
                      name="phone"
                      color="secondary"
                      value={suppliersData.phone}
                      onChange={handlePhoneNumberChange}
                      fullWidth
                      required
                      error={!!errors.phone}
                      helperText={errors.phone}
                    />
                  </Grid>

                  {/* Date Field */}
                  <Grid item xs={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enGB}
                    >
                      <DesktopDatePicker
                        label={t("Business Commencement")}
                        name="business_commencement"
                        id="datepicker-date"
                        color="secondary"
                        value={parseISO(
                          format(
                            supplierDetails.business_commencement.toDate(),
                            "yyyy-MM-dd"
                          )
                        )}
                        onChange={(newValue) => handleDateChange(newValue)}
                        required
                        slots={{
                          openPickerButton: StyledButton,
                          date: StyledDate,
                        }}
                        slotProps={{
                          clearable: true,
                          openPickerIcon: { fontSize: "large" },
                          openPickerButton: { color: "secondary" },
                          textField: {
                            variant: "outlined",
                            color: "secondary",
                            fullWidth: true,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.date}
                            helperText={errors.date}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* Contentment Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Contentment")}
                      name="contentment"
                      color="secondary"
                      value={suppliersData.contentment}
                      onChange={handleNumericChange}
                      fullWidth
                      error={!!errors.contentment}
                      helperText={errors.contentment}
                    />
                  </Grid>

                  {/* Category Field */}
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      options={categories}
                      fullWidth
                      disablePortal
                      multiple
                      id="combobox-category"
                      name="category"
                      color="secondary"
                      value={suppliersData.categories || []}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={(event, newValue) =>
                        handleCategoriesComboboxChange(
                          "categories",
                          newValue ? newValue : ""
                        )
                      }
                      defaultValue={[]}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Category")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Address Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Address")}
                      name="address"
                      color="secondary"
                      value={suppliersData.address}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.address}
                      helperText={errors.address}
                    />
                  </Grid>

                  {/* Description Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Description")}
                      name="description"
                      color="secondary"
                      value={suppliersData.description}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.description}
                      helperText={errors.description}
                    />
                  </Grid>

                  {/* Upload Photo Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenPhotoDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#0EC6EA",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <AddAPhotoOutlinedIcon paddingRight="10px" />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Photo")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Upload Documents Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDocumentsDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#F6B411",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <PostAddOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Documents")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Old File List */}
                  <Grid item xs={6}>
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 100,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleMarkFiles(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={getFileName(doc)} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* New File List */}
                  <Grid item xs={6}>
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 100,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {newFiles.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleNewDeleteFile(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={getFileName(doc.name)} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Confirm")}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openPhotoDialog}
                  onClose={() => setOpenPhotoDialog(false)}
                >
                  <DialogTitle> {t("Upload Photo")} </DialogTitle>
                  <DialogContent>
                    <input type="file" onChange={handlePhotoSelection} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenPhotoDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EditSupplier;
