// src/screens/EditPart.jsx

import React, { useEffect, useState, useCallback } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import {
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  deleteObject,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
// Images
import emptyPhoto from "../assets/no_image.png";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";

const EditPart = ({ onClose, partData2Update, onFeedback }) => {
  //   const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [units, setUnits] = useState([]);
  const { categories, partBrands, partOrigins } = useData();
  const [errors, setErrors] = useState({});
  const [photo, setPhoto] = useState(null);
  const [documents, setDocuments] = useState(partData2Update.documents);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [partData, setPartData] = useState({
    brand: partData2Update.brand,
    origin: partData2Update.origin,
    barcode: partData2Update.barcode,
    category: partData2Update.category,
    current_stock: partData2Update.current_stock,
    description: partData2Update.description,
    internal_pn: partData2Update.internal_pn,
    minimum_stock: partData2Update.minimum_stock,
    name: partData2Update.name,
    purchase_price: partData2Update.purchase_price,
    sale_price: partData2Update.sale_price,
    storage_location: partData2Update.storage_location,
    unit: partData2Update.unit,
    photo: partData2Update.photo,
    documents: partData2Update.documents,
    id: partData2Update.id,
  });
  // const isSmallerScreen = useMediaQuery("(max-width:600px)");

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  //  Get files in storage
  //   useEffect(() => {
  //     const fetchFiles = async () => {
  //       try {
  //         const listRef = ref(storage, `parts/${partData2Update.id}/doc/`);
  //         const res = await listAll(listRef);
  //         const filePromises = res.items.map(async (itemRef) => {
  //           const url = await getDownloadURL(itemRef);
  //           return { name: itemRef.name, url, fullPath: itemRef.fullPath };
  //         });
  //         const files = await Promise.all(filePromises);
  //         setFiles(files);
  //       } catch (error) {
  //         console.log(error.message);
  //       }
  //     };

  //     fetchFiles();
  //   }, [partData2Update]);

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    // console.log(httpsReference.name);
    return httpsReference.name;
    // // console.log(documents);
    // if (url2process.includes("https://firebasestorage.googleapis.com")) {
    //   const httpsReference = ref(storage, url2process);
    //   // console.log(httpsReference.name);
    //   return httpsReference.name;
    //   // biome-ignore lint/style/noUselessElse: <explanation>
    // } else {
    //   // console.log(url2process);
    //   return url2process;
    // }
  }, []);

  // // Get documents as file names
  // useEffect(() => {
  //   const names = partData.documents.map((file) => getFileName(file));
  //   setDocuments(names);
  // }, [partData.documents, getFileName]);

  // Get units from JSON file
  useEffect(() => {
    fetch("/Units.json")
      .then((response) => response.json())
      .then((data) => {
        setUnits(data.units);
      })
      .catch((error) => console.error("Error fetching units:", error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPartData({ ...partData, [name]: value });
  };

  const handlePartComboboxChange = (name, newValue) => {
    setPartData((prevpartData) => ({
      ...prevpartData,
      [name]: newValue,
    }));
  };

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(value)) {
      setPartData({ ...partData, [name]: value });
    }
  };

  const handlePhotoSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file);
    }
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setNewFiles(files);
    // const names = files.map((file) => file.name);

    // console.log(files);
    // console.log(documents);
    // console.log(names);

    // setDocuments((prevDocuments) => ({
    //   ...prevDocuments,
    //   names,
    // }));
    // console.log(documents);
  };

  const validateFields = () => {
    const newErrors = {};
    // if (!partData.category) newErrors.category = "Category is required";
    if (partData.current_stock === null || partData.current_stock === "")
      newErrors.current_stock = t("Current stock is required");
    if (!partData.minimum_stock)
      newErrors.minimum_stock = t("Minimum stock is required");
    if (!partData.name) newErrors.name = t("Name is required");
    if (Number(partData.purchase_price) < 0)
      newErrors.purchase_price = t("Purchase price is required");
    if (!partData.sale_price)
      newErrors.sale_price = t("Sale price is required");
    if (!partData.origin) newErrors.origin = t("Origin is required");
    if (!partData.brand) newErrors.brand = t("Brand is required");
    // if (!partData.unit) newErrors.unit = "Unit is required";
    return newErrors;
  };

  const handleClearData = () => {
    setPartData({
      brand: "",
      origin: "",
      barcode: "",
      category: "",
      current_stock: "",
      description: "",
      internal_pn: "",
      minimum_stock: "",
      name: "",
      purchase_price: "",
      sale_price: "",
      storage_location: "",
      unit: "",
      photo: "",
      documents: [],
      id: "",
    });
    setPhoto(null);
    setDocuments([]); // Needed for hold the names (old and new)
    setNewFiles([]); // Needed for new uploads (new files)
    setFiles([]); // Needed for mark and delete functions (already installed files)
    setErrors({});
  };

  // Mark files for delete operation
  const handleMarkFiles = (filePath) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      filePath: [...(prevFiles.filePath || []), filePath],
    }));
    // console.log(files);
    setDocuments(documents.filter((file) => file !== filePath));
  };

  // Delete new files from list
  const handleNewDeleteFile = (filePath) => {
    setNewFiles(newFiles.filter((file) => file !== filePath));
  };

  // Delete files
  const handleFileDelete = async () => {
    const docsRef = ref(storage, `parts/${partData.id}/doc`);
    try {
      // List all items (file paths) under the path
      const res = await listAll(docsRef);

      // Delete files that match
      await Promise.all(
        res.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);

          if (files.filePath.includes(downloadURL)) {
            await deleteObject(itemRef);
            console.log("Deleted:", downloadURL);
          } else {
            console.log("Not Deleted:", downloadURL);
          }
        })
      );

      console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  // const getDocumentsFromStorage = async () => {
  //   const listRef = ref(storage, `parts/${partData.id}/doc`);
  //   // List all items (file paths) under the path
  //   listAll(listRef)
  //     .then((res) => {
  //       return Promise.all(res.items.map((itemRef) => getDownloadURL(itemRef)));
  //     })
  //     .then((documentUrls) => {
  //       return documentUrls; // Return the URLs if you need to use them further
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "Error fetching documents from Firestore Storage: ",
  //         error
  //       );
  //     });
  // };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Delete marked files
        await handleFileDelete();

        // Upload photo
        let photoUrl = partData.photo;
        if (photo) {
          const photoRef = ref(
            storage,
            `parts/${partData.id}/pic/${photo.name}`
          );
          await uploadBytes(photoRef, photo);
          photoUrl = await getDownloadURL(photoRef);
        }

        // Upload documents
        const documentUrls = [];
        for (const file of newFiles) {
          const documentRef = ref(
            storage,
            `parts/${partData.id}/doc/${file.name}`
          );
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          documentUrls.push(url);
        }

        if (documents.length) documents.map((file) => documentUrls.push(file));

        // Set photo and documents URL in partData
        const updatedPartData = {
          ...partData,
          photo: photoUrl,
          documents: documentUrls,
        };

        // console.log(updatedPartData);

        // Create document reference
        const partsRef = collection(firestore, "parts");
        // Search exact ID within documents
        const q = query(partsRef, where("id", "==", updatedPartData.id));
        // Search the query
        const querySnapshot = await getDocs(q);
        // Find the exact document
        const partDoc = querySnapshot.docs[0];
        // Create reference to this document
        const docRef = doc(firestore, "parts", partDoc.id);
        // Update the document
        await updateDoc(docRef, updatedPartData);
        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Update Part"));
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  const imageSize = {
    width: 400, // Adjust the width as needed
    height: 400, // Adjust the height as needed
  };

  const renderPhoto = () => {
    if (photo) {
      return (
        <img
          src={URL.createObjectURL(photo)}
          alt="Part"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down",
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (partData.photo) {
      return (
        <img
          src={partData.photo}
          alt="Part"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down",
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <img
          src={emptyPhoto}
          alt="Empty"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down",
            paddingBottom: "10px",
          }}
        />
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Edit Part")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {renderPhoto()}
                    </Box>
                  </Grid>

                  {/* Brand Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={partBrands}
                      fullWidth
                      disablePortal
                      id="combobox-brands"
                      name="brand"
                      color="secondary"
                      value={partData.brand || []}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={(event, newValue) =>
                        handlePartComboboxChange(
                          "brand",
                          newValue ? newValue : ""
                        )
                      }
                      defaultValue={[]}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Brand")}
                          error={!!errors.brand}
                          helperText={errors.brand}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Origin Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={partOrigins}
                      fullWidth
                      disablePortal
                      id="combobox-origin"
                      name="origin"
                      color="secondary"
                      value={partData.origin || []}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={(event, newValue) =>
                        handlePartComboboxChange(
                          "origin",
                          newValue ? newValue : ""
                        )
                      }
                      defaultValue={[]}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Origin")}
                          error={!!errors.origin}
                          helperText={errors.origin}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Barcode Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Barcode")}
                      name="barcode"
                      color="secondary"
                      value={partData.barcode}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  {/* Name Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Part Name")}
                      name="name"
                      color="secondary"
                      required
                      value={partData.name}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Grid>

                  {/* Part Number Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Internal Part Number")}
                      name="internal_pn"
                      color="secondary"
                      value={partData.internal_pn}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  {/* Unit Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={units.map((item) => t(item))}
                      fullWidth
                      disablePortal
                      id="combobox-unit"
                      name="unit"
                      color="secondary"
                      value={partData.unit || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handlePartComboboxChange(
                          "unit",
                          newValue ? newValue : ""
                        )
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Unit")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Minimum Stock Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Minimum Stock")}
                      name="minimum_stock"
                      color="secondary"
                      required
                      value={partData.minimum_stock}
                      onChange={handleNumericChange}
                      fullWidth
                      error={!!errors.minimum_stock}
                      helperText={errors.minimum_stock}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>

                  {/* Current Stock Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Stock")}
                      name="current_stock"
                      color="secondary"
                      required
                      value={partData.current_stock}
                      onChange={handleNumericChange}
                      fullWidth
                      error={!!errors.current_stock}
                      helperText={errors.current_stock}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>

                  {/* Category Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={categories}
                      fullWidth
                      disablePortal
                      multiple
                      id="combobox-category"
                      name="category"
                      color="secondary"
                      value={partData.category || []}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={(event, newValue) =>
                        handlePartComboboxChange(
                          "category",
                          newValue ? newValue : ""
                        )
                      }
                      defaultValue={[]}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Category")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Storage Location Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Storage Location")}
                      name="storage_location"
                      color="secondary"
                      value={partData.storage_location}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  {/* Purchase Price Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Purchase Price")}
                      name="purchase_price"
                      color="secondary"
                      required
                      value={partData.purchase_price}
                      onChange={handleNumericChange}
                      fullWidth
                      error={!!errors.purchase_price}
                      helperText={errors.purchase_price}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>

                  {/* Sale Price Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Sale Price")}
                      name="sale_price"
                      color="secondary"
                      required
                      value={partData.sale_price}
                      onChange={handleNumericChange}
                      fullWidth
                      error={!!errors.sale_price}
                      helperText={errors.sale_price}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>

                  {/* Description Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Description")}
                      name="description"
                      color="secondary"
                      multiline="true"
                      value={partData.description}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  {/* Upload Photo Button */}
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenPhotoDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#0EC6EA",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <AddAPhotoOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Photo")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Upload Documents Button */}
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDocumentsDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#F6B411",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <PostAddOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Documents")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Old File List */}
                  <Grid item xs={6}>
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 100,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleMarkFiles(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={getFileName(doc)} />
                        </ListItem>
                      ))}
                      {/* {[0, 1, 2, 3, 4].map((sectionId) => (
                        <li key={`section-${sectionId}`}>
                          <ul>
                            <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            {[0, 1, 2].map((item) => (
                              <ListItem key={`item-${sectionId}-${item}`}>
                                <ListItemText primary={`Item ${item}`} />
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      ))} */}
                    </List>
                  </Grid>

                  {/* New File List */}
                  <Grid item xs={6}>
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 100,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {newFiles.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleNewDeleteFile(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={getFileName(doc.name)} />
                        </ListItem>
                      ))}
                      {/* {[0, 1, 2, 3, 4].map((sectionId) => (
                        <li key={`section-${sectionId}`}>
                          <ul>
                            <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            {[0, 1, 2].map((item) => (
                              <ListItem key={`item-${sectionId}-${item}`}>
                                <ListItemText primary={`Item ${item}`} />
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      ))} */}
                    </List>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Update Part")}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openPhotoDialog}
                  onClose={() => setOpenPhotoDialog(false)}
                >
                  <DialogTitle> {t("Upload Photo")} </DialogTitle>
                  <DialogContent>
                    <input type="file" onChange={handlePhotoSelection} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenPhotoDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EditPart;
