// src/screens/LoadingWindow.jsx

import React, { useState } from "react";
import {
  CircularProgress,
  Box,
  Grid,
  Paper,
  Typography,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const LoadingWindow = ({ onClose }) => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                // backgroundImage: 'url("https://source.unsplash.com/random")',
                // backgroundSize: "cover",
                // backgroundPosition: "center",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                }}
              >
                <Grid item xs={12} md={12}>
                  <CircularProgress color="success" />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography component="h1" variant="r16">
                    {t("Loading...")}
                  </Typography>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default LoadingWindow;
