// src/screens/ViewClientDetails.jsx

import React, { useEffect, useState, useCallback } from "react";
// import { useAuth } from "../components/AuthContext";
import { storage } from "../firebaseConfig";
import { getDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemText,
} from "@mui/material";
import { format } from "date-fns";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, { CustomToolbar } from "../components/Common";
// Images
import emptyPhoto from "../assets/no_image.png";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import FileIcon from "@mui/icons-material/InsertDriveFile";

const ViewClientDetails = ({ onClose, clientDetails }) => {
  //   const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const birthday = format(clientDetails.birthdate.toDate(), "dd/MM/yyyy");

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const handleFileOpenClick = (url) => {
    window.open(url, getFileName(url), "noopener,noreferrer");
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  const handleCancel = async () => {
    handleClose();
  };

  const imageSize = {
    width: 400, // Adjust the width as needed
    height: 400, // Adjust the height as needed
  };

  const renderPhoto = () => {
    if (clientDetails.photo) {
      return (
        <img
          src={clientDetails.photo}
          alt="Part"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <img
          src={emptyPhoto}
          alt="Empty"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const itemData = await Promise.all(
        clientDetails.motorcycles.map(async (docRef) => {
          try {
            const motorcycleDoc = await getDoc(docRef);

            if (motorcycleDoc.exists()) {
              return motorcycleDoc.data();
            }
          } catch (error) {
            console.error("Error fetching document:", error);
            return null;
          } finally {
            setLoading(false);
          }
        })
      );
      setData(itemData);
    };
    fetchData();
  }, [clientDetails]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "model",
      headerName: t("Model"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "year",
      headerName: t("Year"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "odometer",
      headerName: t("Current KM"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "license_plate",
      headerName: t("License Plate"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "chasis_no",
      headerName: t("Chasis Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "motor_no",
      headerName: t("Motor Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 720,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* Container */}
                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("View Details")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {renderPhoto()}
                    </Box>
                  </Grid>

                  {/* Full Name Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Full Name")}
                      name="full_name"
                      color="secondary"
                      value={clientDetails.full_name}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Date of Birth Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Date of Birth")}
                      name="birthdate"
                      color="secondary"
                      value={birthday}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Driving License Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Driving License")}
                      name="driving_licence"
                      color="secondary"
                      value={clientDetails.driving_licence}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Phone Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Phone")}
                      name="phone"
                      color="secondary"
                      multiline
                      value={clientDetails.phone}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Address Field */}
                  <Grid item xs={8}>
                    <TextField
                      label={t("Address")}
                      name="address"
                      color="secondary"
                      value={clientDetails.address}
                      readOnly
                      fullWidth
                      multiline
                    />
                  </Grid>

                  {/* City Field */}
                  <Grid item xs={2}>
                    <TextField
                      label={t("City")}
                      name="city"
                      color="secondary"
                      value={clientDetails.city}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Country Field */}
                  <Grid item xs={2}>
                    <TextField
                      label={t("Country")}
                      name="country"
                      color="secondary"
                      value={clientDetails.country}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Description Field */}
                  <Grid item xs={10}>
                    <TextField
                      label={t("Description")}
                      name="description"
                      color="secondary"
                      value={clientDetails.description}
                      readOnly
                      fullWidth
                      multiline
                    />
                  </Grid>

                  {/* Corporate Switch */}
                  <Grid item xs={2}>
                    <TextField
                      label={t("Type")}
                      name="type"
                      color="secondary"
                      value={
                        clientDetails.corporate
                          ? t("Corporate")
                          : t("Individual")
                      }
                      readOnly
                      fullWidth
                      multiline
                    />
                  </Grid>

                  {/* Corporation Name */}
                  {clientDetails.corporate && (
                    <Grid item xs={4}>
                      <TextField
                        label={t("Corporation")}
                        name="corporation"
                        color="secondary"
                        value={clientDetails.corporation}
                        readOnly
                        fullWidth
                      />
                    </Grid>
                  )}

                  {/* Tax Number */}
                  {clientDetails.corporate && (
                    <Grid item xs={4}>
                      <TextField
                        label={t("Tax Number")}
                        name="tax_number"
                        color="secondary"
                        value={clientDetails.tax_number}
                        readOnly
                        fullWidth
                        required
                      />
                    </Grid>
                  )}

                  {/* Tax Office */}
                  {clientDetails.corporate && (
                    <Grid item xs={4}>
                      <TextField
                        label={t("Tax Office")}
                        name="tax_office"
                        color="secondary"
                        value={clientDetails.tax_office}
                        readOnly
                        fullWidth
                        required
                      />
                    </Grid>
                  )}

                  {/* File List */}
                  <Grid item xs={12}>
                    <List>
                      {clientDetails.documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleFileOpenClick(doc)}
                            >
                              <FileIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={getFileName(doc)} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Data Grid for Motorcycle Details */}
                  <Grid item xs={12}>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        rows={data}
                        columns={columns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        pageSize={5}
                        autoHeight
                        autoWidth
                        disableSelectionOnClick
                        //   getRowHeight={calculateRowHeight}
                        //   onRowSelectionModelChange={(newSelection) =>
                        //     handleSelectionChange(newSelection)
                        //   }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Close")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ViewClientDetails;
