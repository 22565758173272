// src/screens/AddCategories.jsx

import React, { useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { arrayUnion, doc, updateDoc, setDoc } from "firebase/firestore";
import {
  Box,
  TextField,
  Grid,
  Paper,
  Typography,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import LoadingWindow from "../components/LoadingWindow";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const AddCategories = ({ onClose, onFeedback }) => {
  // const { currentUser } = useAuth();
  const { categories, docCategories } = useData();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [partCategory, setPartCategory] = useState("");
  const [docCategory, setDocCategory] = useState("");
  // const [confirmVisible, setConfirmVisible] = useState(false);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    if (name === "partCategory") setPartCategory(value);
    else if (name === "docCategory") setDocCategory(value);
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleClearData = () => {
    setPartCategory("");
    setDocCategory("");
  };

  const updatePartCategoriesDocument = async (category, operation) => {
    // console.log(category);
    const docRef = doc(firestore, "categories", "vsZr8KRfxtxhUJitMQC6");
    const newCategory = categories.find((c) => c.category === category);

    if (operation === "Add") {
      if (!newCategory && category !== "") {
        //   console.log("New Category");
        await setDoc(
          docRef,
          {
            category: arrayUnion(category),
          },
          { merge: true }
        );
      }
    } else if (operation === "Update" || operation === "Delete") {
      await updateDoc(docRef, {
        category: category,
      });
    }
  };

  const updateDocCategoriesDocument = async (category, operation) => {
    // console.log(category);
    const docRef = doc(firestore, "docCategories", "LfcAlMIcJjA35VYE5U6j");
    const newCategory = docCategories.find((c) => c.category === category);

    if (operation === "Add") {
      if (!newCategory && category !== "") {
        // console.log("New Category");
        await setDoc(
          docRef,
          {
            category: arrayUnion(category),
          },
          { merge: true }
        );
      }
    } else if (operation === "Update" || operation === "Delete") {
      await updateDoc(docRef, {
        category: category,
      });
    }
  };

  const handleCategoryAdd = async (type) => {
    if (type === "part") {
      //   console.log(categories);
      //   console.log(partCategory);
      //   console.log(type);
      updatePartCategoriesDocument(partCategory, "Add");
      setPartCategory("");
    } else if (type === "doc") {
      //   console.log(docCategories);
      //   console.log(docCategory);
      //   console.log(type);
      updateDocCategoriesDocument(docCategory, "Add");
      setDocCategory("");
    }
  };

  const handleCategoryDelete = async (cat, type) => {
    if (type === "part") {
      updatePartCategoriesDocument(
        categories.filter((category) => category !== cat),
        "Delete"
      );
    } else if (type === "doc") {
      updateDocCategoriesDocument(
        docCategories.filter((category) => category !== cat),
        "Delete"
      );
    }
    // setIsAddOpen(true);
    // addBrandData("BrandX", modelData);
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  {/* Text */}
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant="r16"
                          marginBottom={1}
                          sx={{
                            fontSize: {
                              xs: "0.7rem", // font size for extra-small screens
                              sm: "0.8rem", // font size for small screens
                              md: "0.9rem", // font size for medium screens
                              lg: "1.2rem", // font size for large screens
                              xl: "1.5rem", // font size for extra-large screens
                            },
                          }}
                        >
                          {t("Part Categories")}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* File List */}

                    <Box
                      sx={{
                        width: "100%",
                        // minHeight: 300,
                        maxHeight: 600,
                        minWidth: 200,
                        maxWidth: 400,
                        bgcolor: "background.paper",
                        p: 2,
                        overflowY: "auto", // Add vertical scrollbar if needed
                        overflowX: "hidden", // Hide horizontal scrollbar
                        borderRadius: 10,
                      }}
                    >
                      <List>
                        {categories.sort().map((cat, index) => (
                          <ListItem
                            key={cat}
                            secondaryAction={
                              <>
                                {/* <Tooltip title={t("Edit Category")}>
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                sx={{ marginLeft: 1 }}
                                onClick={() => handleUpdateClicked(cat, "part")}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip> */}

                                <Tooltip title={t("Delete Category")}>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{ marginLeft: 1 }}
                                    onClick={() =>
                                      handleCategoryDelete(cat, "part")
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            {/* <ListItemIcon>
                        <FileIcon />
                      </ListItemIcon> */}
                            <Typography
                              variant="body1" // Default variant
                              marginRight={7}
                              sx={{
                                fontSize: {
                                  xs: "0.75rem", // Smaller font size on extra-small screens
                                  sm: "0.875rem", // Slightly larger font size on small screens
                                  md: "1rem", // Default font size on medium screens
                                  lg: "1rem", // Larger font size on large screens
                                  xl: "1.5rem", // Largest font size on extra-large screens
                                },
                              }}
                            >
                              {cat}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                      {/* Add Button */}
                      <Box
                        marginTop="10px"
                        marginBottom="10px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          label={t("Category")}
                          name="partCategory"
                          color="info"
                          value={partCategory}
                          onChange={handleInputChange}
                          fullWidth
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleCategoryAdd("part");
                            }
                          }}
                        />
                        {/* {!confirmVisible && ( */}
                        <Tooltip title={t("Add Category")}>
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={() => handleCategoryAdd("part")}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        {/* )} */}
                        {/* {confirmVisible && (
                      <Tooltip title={t("Confirm Change")}>
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={() => handleCategoryUpdate()}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                    )} */}
                      </Box>
                    </Box>
                  </Grid>
                  {/* Text */}
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          // component="h1"
                          variant="r16"
                          marginBottom={1}
                          sx={{
                            fontSize: {
                              xs: "0.7rem", // font size for extra-small screens
                              sm: "0.8rem", // font size for small screens
                              md: "0.9rem", // font size for medium screens
                              lg: "1.2rem", // font size for large screens
                              xl: "1.5rem", // font size for extra-large screens
                            },
                          }}
                        >
                          {t("Document Categories")}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* File List */}
                    <Box
                      sx={{
                        width: "100%",
                        // minHeight: 300,
                        maxHeight: 600,
                        minWidth: 200,
                        maxWidth: 400,
                        bgcolor: "background.paper",
                        p: 2,
                        overflowY: "auto", // Add vertical scrollbar if needed
                        overflowX: "hidden", // Hide horizontal scrollbar
                        borderRadius: 10,
                      }}
                    >
                      <List>
                        {docCategories.sort().map((cat, index) => (
                          <ListItem
                            key={cat}
                            secondaryAction={
                              <>
                                {/* <Tooltip title={t("Edit Category")}>
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                sx={{ marginLeft: 1 }}
                                onClick={() => handleUpdateClicked(cat, "doc")}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip> */}

                                <Tooltip title={t("Delete Category")}>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{ marginLeft: 1 }}
                                    onClick={() =>
                                      handleCategoryDelete(cat, "doc")
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            {/* <ListItemIcon>
                        <FileIcon />
                      </ListItemIcon> */}
                            <Typography
                              variant="body1" // Default variant
                              marginRight={7}
                              sx={{
                                fontSize: {
                                  xs: "0.75rem", // Smaller font size on extra-small screens
                                  sm: "0.875rem", // Slightly larger font size on small screens
                                  md: "1rem", // Default font size on medium screens
                                  lg: "1rem", // Larger font size on large screens
                                  xl: "1.5rem", // Largest font size on extra-large screens
                                },
                              }}
                            >
                              {cat}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>

                      {/* Add Button */}
                      <Box
                        marginTop="10px"
                        marginBottom="10px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          label={t("Category")}
                          name="docCategory"
                          color="info"
                          value={docCategory}
                          onChange={handleInputChange}
                          fullWidth
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleCategoryAdd("doc");
                            }
                          }}
                        />
                        {/* {!confirmVisible && ( */}
                        <Tooltip title={t("Add Category")}>
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={() => handleCategoryAdd("doc")}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        {/* )} */}
                        {/* {confirmVisible && (
                      <Tooltip title={t("Confirm Change")}>
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={() => handleCategoryUpdate()}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                    )} */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default AddCategories;
