// src/screens/AddMoto.jsx

import React, { useEffect, useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
// Images
import emptyPhoto from "../assets/no_image.png";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";

const AddMoto = ({ onClose, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const { brandsAndModels } = useData();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [years, setYears] = useState({});
  const [errors, setErrors] = useState({});
  const [photo, setPhoto] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [motoData, setMotoData] = useState({
    brand: "",
    chasis_no: "",
    description: "",
    documents: [],
    id: "",
    license_plate: "",
    model: "",
    motor_no: "",
    odometer: "",
    year: "",
  });

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  // Get years from JSON file
  useEffect(() => {
    fetch("/Years.json")
      .then((response) => response.json())
      .then((data) => {
        setYears(data.years);
      })
      .catch((error) => console.error("Error fetching years:", error));
  }, []);

  // Get motorcycles brands from Database
  useEffect(() => {
    setBrands(Object.keys(brandsAndModels).sort());
  }, [brandsAndModels]);

  // Get motorcycles models from Database according to brand
  useEffect(() => {
    if (selectedBrand && brandsAndModels) {
      setModels(brandsAndModels[selectedBrand].sort());
    }
  }, [selectedBrand, brandsAndModels]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMotoData({ ...motoData, [name]: value });
  };

  const handleModelChange = (name, newValue) => {
    setMotoData((prevmotoData) => ({
      ...prevmotoData,
      [name]: newValue,
    }));
  };

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(value)) {
      setMotoData({ ...motoData, [name]: value });
    }
  };

  const handleBrandChange = (event, value) => {
    setSelectedBrand(value);
    const brand = brands.find((b) => b.brand === value);
    setModels(brand ? brand.models : []);
    setMotoData({ ...motoData, brand: value });
  };

  const handlePhotoSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file);
    }
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setDocuments(files);
  };

  const validateFields = () => {
    const newErrors = {};
    // if (!motoData.brand) newErrors.brand = t("Brand is required");
    if (!motoData.model) newErrors.model = t("Model is required");
    if (!motoData.year) newErrors.year = t("Year is required");
    if (!motoData.odometer) newErrors.odometer = t("Current KM is required");
    if (!motoData.license_plate) {
      if (!motoData.chasis_no) {
        if (!motoData.motor_no) {
          newErrors.license_plate = t(
            "You should provide at least one of those: License Plate, Chasis Number or Motor Number"
          );
        }
      }
    }
    return newErrors;
  };

  const handleClearData = () => {
    setMotoData({
      brand: "",
      chasis_no: "",
      description: "",
      documents: [],
      id: "",
      license_plate: "",
      model: "",
      motor_no: "",
      odometer: "",
      year: "",
    });
    setPhoto(null);
    setDocuments([]);
    setSelectedBrand(null);
    setErrors({});
  };

  // Mark files for delete operation
  const handleDeleteFile = (filePath) => {
    setDocuments(documents.filter((file) => file !== filePath));
  };

  const processDocumentData = (data) => {
    const knownStringFields = ["startYear", "endYear"];
    const arrays = {};

    for (const key in data) {
      if (!knownStringFields.includes(key)) {
        arrays[key] = data[key];
      }
    }

    return arrays;
  };

  async function updateReferenceDocuments() {
    try {
      // console.log(motoData.brand, motoData.model);
      // Get the reference to the specific document
      const refDocRef = doc(firestore, "refDocs", motoData.brand);
      const docSnap = await getDoc(refDocRef);
      // Check if document exists
      if (docSnap.exists()) {
        const models = docSnap.data();
        // console.log(models);
        const smodel = models[motoData.model];
        // console.log(smodel);
        let categories;

        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(smodel)) {
          const { startYear, endYear } = value;
          // console.log(motoData.year, startYear, endYear);
          if (motoData.year >= startYear && motoData.year <= endYear) {
            // console.log("Found!", value, key);
            categories = value;
          }
        }
        // console.log(categories);
        const dataToSave = processDocumentData(categories);
        // console.log(dataToSave);
        // console.log("All relevant documents have been processed.");
        return dataToSave;
        // biome-ignore lint/style/noUselessElse: <explanation>
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error updating documents: ", error);
      return null;
    }
  }

  const handleSubmit = async () => {
    const fieldErrors = validateFields();
    const id = uuidv4();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Upload photo
        let photoUrl = "";
        if (photo) {
          const photoRef = ref(storage, `motorcycles/${id}/pic/${photo.name}`);
          await uploadBytes(photoRef, photo);
          photoUrl = await getDownloadURL(photoRef);
        }

        // Upload documents
        const documentUrls = [];
        for (const file of documents) {
          const documentRef = ref(
            storage,
            `motorcycles/${id}/doc/${file.name}`
          );
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          documentUrls.push(url);
        }

        // Get Product Reference Documents
        const referenceDocuments = await updateReferenceDocuments();
        let updatedmotoData;
        if (referenceDocuments) {
          // Set photo and documents URL in motoData
          updatedmotoData = {
            ...motoData,
            photo: photoUrl,
            referenceDocuments: referenceDocuments,
            documents: documentUrls,
            brand: selectedBrand,
            id: id,
            createdAt: new Date(),
          };
        } else {
          // Set photo and documents URL in motoData
          updatedmotoData = {
            ...motoData,
            photo: photoUrl,
            documents: documentUrls,
            brand: selectedBrand,
            id: id,
            createdAt: new Date(),
          };
        }

        console.log(updatedmotoData);
        await addDoc(collection(firestore, "motorcycles"), updatedmotoData);
        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Create New Motorcycle"));
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  const imageSize = {
    width: 400, // Adjust the width as needed
    height: 400, // Adjust the height as needed
  };

  const renderPhoto = () => {
    if (photo) {
      return (
        <img
          src={URL.createObjectURL(photo)}
          alt="Motorcycle"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <img
          src={emptyPhoto}
          alt="Empty"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Create New Moto")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {renderPhoto()}
                    </Box>
                  </Grid>
                  {/* Brand Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={brands}
                      fullWidth
                      disablePortal
                      id="combobox-brand"
                      name="brand"
                      color="secondary"
                      value={selectedBrand || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleBrandChange("brand", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Brand")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* Model Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={models}
                      fullWidth
                      disablePortal
                      id="combobox-model"
                      name="model"
                      color="secondary"
                      value={motoData.model || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleModelChange("model", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Model")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* Year Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={years}
                      fullWidth
                      disablePortal
                      id="combobox-year"
                      name="year"
                      color="secondary"
                      value={motoData.year || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleModelChange("year", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Year")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* Current KM Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Current KM")}
                      name="odometer"
                      color="secondary"
                      value={motoData.odometer}
                      onChange={handleNumericChange}
                      fullWidth
                      required
                      error={!!errors.odometer}
                      helperText={errors.odometer}
                    />
                  </Grid>
                  {/* Chasis Number Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Chasis Number")}
                      name="chasis_no"
                      color="secondary"
                      value={motoData.chasis_no}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.chasis_no}
                      helperText={errors.chasis_no}
                    />
                  </Grid>
                  {/* Motor Number Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Motor Number")}
                      name="motor_no"
                      color="secondary"
                      value={motoData.motor_no}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.motor_no}
                      helperText={errors.motor_no}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>
                  {/* License Plate Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("License Plate")}
                      name="license_plate"
                      color="secondary"
                      value={motoData.license_plate}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.license_plate}
                      helperText={errors.license_plate}
                    />
                  </Grid>
                  {/* Description Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Description")}
                      name="description"
                      color="secondary"
                      multiline
                      value={motoData.description}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  {/* Upload Photo Button */}
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenPhotoDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#0EC6EA",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <AddAPhotoOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Photo")}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* Upload Documents Button */}
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDocumentsDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#F6B411",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <PostAddOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Documents")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* File List */}
                  <Grid item xs={12}>
                    <List>
                      {documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteFile(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={doc.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Add Motorcycle")}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openPhotoDialog}
                  onClose={() => setOpenPhotoDialog(false)}
                >
                  <DialogTitle> {t("Upload Photo")} </DialogTitle>
                  <DialogContent>
                    <input type="file" onChange={handlePhotoSelection} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenPhotoDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default AddMoto;
