/* eslint-disable react-hooks/exhaustive-deps */
// src/screens/EditAppointment.jsx

import React, { useEffect, useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import {
  collection,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  Avatar,
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  Stack,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, { CustomToolbar } from "../components/Common";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/material/styles";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import HoverImage from "../components/HoverImage";
// import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import "../App.css";
import AppointmentToWork from "./AppointmentToWork";

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const StyledDateTime = styled(DateTimePicker)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color:
    theme.palette.mode === "light"
      ? theme.palette.secondary.dark
      : theme.palette.secondary.light,
}));

const EditAppointment = ({ onClose, appointmentDetails, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [selectedClientRef, setSelectedClientRef] = useState(
    appointmentDetails.client
  );
  const [selectedMotoRef, setSelectedMotoRef] = useState(
    appointmentDetails.motorcycle
  );
  const [appointmentDateTime, setAppointmentDateTime] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [motoData, setMotoData] = useState([]);
  const [motoSelectionModel, setMotoSelectionModel] = useState([]);
  const [isWorkHookOpen, setIsWorkHookOpen] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  // const [motoSearchOptions, setMotoSearchOptions] = useState([]);
  // const [selectedMotoSearchOption, setSelectedMotoSearchOption] =
  //   useState(null);
  // const [motoSearch, setMotoSearch] = useState("");
  // const [filteredMotos, setFilteredMotos] = useState([]);
  const [appointmentData, setAppointmentData] = useState({
    client: null,
    datetime: appointmentDetails.datetime,
    id: appointmentDetails.id,
    motorcycle: null,
    notes: appointmentDetails.notes,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get Client
        const clientDoc = await getDoc(appointmentDetails.client);
        const clientDetails = clientDoc.data();

        // console.log(dataClient);
        setClientData([clientDetails] || []);

        // Get Motorcycles and check the selected one
        const itemData = await Promise.all(
          clientDetails.motorcycles.map(async (docRef) => {
            let motoData;
            try {
              const motorcycleDoc = await getDoc(docRef);

              if (motorcycleDoc.exists()) {
                motoData = motorcycleDoc.data();
                return motoData;
              }
            } catch (error) {
              console.error("Error fetching document:", error);
              return null;
            }
          })
        );

        const motorcycleDoc = await getDoc(appointmentDetails.motorcycle);
        const motoDataL = motorcycleDoc.data();

        const validData = itemData.filter((ref) => ref !== null);
        setMotoData(validData);
        // setFilteredMotos(validData);
        // console.log(motoDataL.id);
        setMotoSelectionModel(motoDataL.id);
      } catch (error) {
        console.error(
          "Error fetching client, motorcycle, or parts data:",
          error
        );
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [appointmentDetails]);

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
    setIsWorkHookOpen(false);
  };

  const closeDoneModal = async () => {
    setIsWorkHookOpen(false);
    // Create document reference
    const appointmentRef = collection(firestore, "appointments");
    // Search exact ID within documents
    const q = query(appointmentRef, where("id", "==", appointmentDetails.id));
    // Search the query
    const querySnapshot = await getDocs(q);
    // Find the exact document
    const appointmentDoc = querySnapshot.docs[0];
    // Create reference to this document
    const docRef = doc(firestore, "appointments", appointmentDoc.id);
    // Update the document
    await updateDoc(docRef, { status: "2" });
  };

  const handleCompleteAppointment = async () => {
    // Open Modal
    setIsWorkHookOpen(true);
  };

  const handleCancelAppointment = async () => {
    // Create document reference
    const appointmentRef = collection(firestore, "appointments");
    // Search exact ID within documents
    const q = query(appointmentRef, where("id", "==", appointmentDetails.id));
    // Search the query
    const querySnapshot = await getDocs(q);
    // Find the exact document
    const appointmentDoc = querySnapshot.docs[0];
    // Create reference to this document
    const docRef = doc(firestore, "appointments", appointmentDoc.id);
    // Update the document
    await updateDoc(docRef, { status: "3" });
  };

  // const handleSearchMotoChange = (event, value) => {
  //   setSelectedMotoSearchOption(value);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // if (name === "motoSearch") setMotoSearch(value);
    // else {
    setAppointmentData({ ...appointmentData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    // }
  };

  const handleDateTimeChange = (datetimeSent) => {
    if (datetimeSent === null || Number.isNaN(datetimeSent.getTime())) {
      console.error("Not a valid date!");
    } else {
      setAppointmentDateTime(datetimeSent);
    }
  };

  //   User may not be able to change client
  //   const handleClientSelectionChange = async (newSelection) => {
  //     setErrors({ ...errors, client: "" });

  //     if (newSelection.length) {
  //       newSelection.map(async (itemId) => {
  //         try {
  //           const clientRef = collection(firestore, "clients");
  //           const q = query(clientRef, where("id", "==", itemId));
  //           const querySnapshot = await getDocs(q);
  //           const clientDoc = querySnapshot.docs[0];

  //           //   console.log(doc(firestore, clientDoc.ref.path));
  //           setSelectedClientRef(doc(firestore, clientDoc.ref.path));

  //           const motoRef = clientDoc.data().motorcycles;
  //           //   console.log(motoRef);

  //           const itemData = await Promise.all(
  //             motoRef.map(async (docRef) => {
  //               let motoData;
  //               try {
  //                 const motorcycleDoc = await getDoc(docRef);

  //                 if (motorcycleDoc.exists()) {
  //                   motoData = motorcycleDoc.data();

  //                   return motoData;
  //                 }
  //               } catch (error) {
  //                 console.error("Error fetching document:", error);
  //                 return null;
  //               }
  //             })
  //           );
  //           setMotoData(itemData);
  //           //   console.log(itemData);
  //         } catch (error) {
  //           console.error("Error fetching document:", error);
  //           return null;
  //         }
  //       });
  //     } else {
  //       setSelectedClientRef(null);
  //       setMotoData([]);
  //     }
  //   };

  // const motoColumnMapping = [
  //   { field: "brand", headerName: t("Brand") },
  //   { field: "model", headerName: t("Model") },
  //   { field: "year", headerName: t("Year") },
  //   { field: "license_plate", headerName: t("License Plate") },
  //   { field: "chasis_no", headerName: t("Chasis Number") },
  //   { field: "motor_no", headerName: t("Motor Number") },
  // ];

  // Create a mapping from headerName to field
  // const motoHeaderToFieldMap = motoColumnMapping.reduce((acc, column) => {
  //   acc[column.headerName] = column.field;
  //   return acc;
  // }, {});

  // const handleMotoSearchButtonClicked = () => {
  //   const field = motoHeaderToFieldMap[selectedMotoSearchOption] || "";
  //   if (!selectedMotoSearchOption) setFilteredMotos(motoData);
  //   else {
  //     const filtered = motoData.filter((motorcycle) => {
  //       return motorcycle[field]
  //         ?.toString()
  //         .toLowerCase()
  //         .includes(motoSearch.toLowerCase());
  //     });
  //     setFilteredMotos(filtered);
  //   }
  // };

  // Set selected IDs
  const handleMotoSelectionChange = async (newSelection) => {
    setErrors({ ...errors, motorcycle: "" });

    if (newSelection.length) {
      newSelection.map(async (itemId) => {
        try {
          const ref = collection(firestore, "motorcycles");
          const q = query(ref, where("id", "==", itemId));
          const querySnapshot = await getDocs(q);
          const refDoc = querySnapshot.docs[0];

          // console.log(doc(firestore, refDoc.ref.path));
          setSelectedMotoRef(doc(firestore, refDoc.ref.path));
          setMotoSelectionModel(refDoc.data().id);
        } catch (error) {
          console.error("Error fetching document:", error);
          return null;
        }
      });
    } else {
      setSelectedMotoRef(null);
    }
  };

  const validateFields = () => {
    const newErrors = {};

    if (!selectedClientRef || selectedClientRef.length < 2)
      newErrors.client = t("Client is required");
    if (!selectedMotoRef || selectedMotoRef.length < 2)
      newErrors.motorcycle = t("Motorcycle is required");
    if (!appointmentDateTime && !appointmentDetails.datetime)
      newErrors.date = t("Date - Time is required");
    return newErrors;
  };

  const handleClearData = () => {
    setAppointmentData({
      client: null,
      datetime: null,
      id: "",
      motorcycle: null,
      notes: "",
    });
    setSelectedMotoRef(null);
    setSelectedClientRef(null);
    setAppointmentDateTime(null);
    // setSelectedMotoSearchOption(null);
    // setMotoSearch("");
    setMotoData([]);
    setErrors({});
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Set photo and documents URL in appointmentData
        const updatedAppointmentData = {
          ...appointmentData,
          client: selectedClientRef,
          datetime: appointmentDateTime
            ? appointmentDateTime
            : appointmentDetails.datetime,
          motorcycle: selectedMotoRef,
        };

        // console.log(updatedAppointmentData);

        // Create document reference
        const appointmentRef = collection(firestore, "appointments");
        // Search exact ID within documents
        const q = query(
          appointmentRef,
          where("id", "==", updatedAppointmentData.id)
        );
        // Search the query
        const querySnapshot = await getDocs(q);
        // Find the exact document
        const appointmentDoc = querySnapshot.docs[0];
        // Create reference to this document
        const docRef = doc(firestore, "appointments", appointmentDoc.id);
        // Update the document
        await updateDoc(docRef, updatedAppointmentData);
        handleClearData();
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error update document: ", error);
        onFeedback(t("Failed to Update Appointment"));
      } finally {
        setLoading(false);
      }
    } else {
      // console.log(errors);
      // console.log(selectedClientRef);
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  const clientColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: t("Full Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "phone",
      headerName: t("Phone Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  const motoColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "model",
      headerName: t("Model"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "year",
      headerName: t("Year"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "odometer",
      headerName: t("Current KM"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "license_plate",
      headerName: t("License Plate"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  // useEffect(() => {
  //   setMotoSearchOptions(
  //     motoColumns
  //       .filter(
  //         (column) =>
  //           column.field !== "id" &&
  //           column.field !== "description" &&
  //           column.field !== "odometer" &&
  //           column.field !== "photo"
  //       )
  //       .map((column) => t(column.headerName))
  //   );
  // }, [t]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 900,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                {/* Work Hook Modal */}
                {isWorkHookOpen && (
                  <AppointmentToWork
                    onClose={closeModal}
                    extHookData={appointmentDetails}
                    onFeedback={onFeedback}
                    extraFeedback={closeDoneModal}
                  />
                )}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* Container */}
                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Edit Appointment")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Data Grid for Client Details */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        // component="h1"
                        variant="h6"
                        paddingLeft="10px"
                        color="red"
                      >
                        {errors.client ? errors.client : ""}
                      </Typography>
                    </Box>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        checkboxSelection={false}
                        disableMultipleRowSelection
                        rows={clientData || []}
                        columns={clientColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5]}
                        disableSelectionOnClick
                        // onRowSelectionModelChange={(newSelection) =>
                        //   handleClientSelectionChange(newSelection)
                        // }
                        //   getRowHeight={calculateRowHeight}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  {/* Search Moto Options Autocomplete */}
                  {/* <Grid item xs={4}>
                    <Autocomplete
                      options={motoSearchOptions}
                      fullWidth
                      disablePortal
                      id="combobox-motoSearch"
                      name="motoSearch"
                      color="secondary"
                      value={selectedMotoSearchOption || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleSearchMotoChange(
                          "motoSearchOption",
                          newValue ? newValue : ""
                        )
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Search with...")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid> */}

                  {/* Search Moto TextField */}
                  {/* <Grid item xs={6}>
                    <TextField
                      label={t("Search")}
                      name="motoSearch"
                      color="secondary"
                      value={motoSearch}
                      onChange={handleInputChange}
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleMotoSearchButtonClicked();
                        }
                      }}
                    />
                  </Grid> */}

                  {/* Search Client Button */}
                  {/* <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleMotoSearchButtonClicked}
                      fullWidth
                      sx={{
                        backgroundColor: "#555555",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <ManageSearchOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft={"10px"}
                        sx={{
                          fontSize: {
                            xs: "0.5rem", // font size for extra-small screens
                            sm: "0.7rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Search")}
                      </Typography>
                    </Button>
                  </Grid> */}

                  {/* Data Grid for Motorcycle Details */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        // component="h1"
                        variant="h6"
                        paddingLeft="10px"
                        color="red"
                      >
                        {errors.motorcycle ? errors.motorcycle : ""}
                      </Typography>
                    </Box>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        checkboxSelection={true}
                        disableMultipleRowSelection
                        rows={motoData || []}
                        columns={motoColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        onRowSelectionModelChange={(newSelection) =>
                          handleMotoSelectionChange(newSelection)
                        }
                        rowSelectionModel={motoSelectionModel}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  <HoverImage
                    src={hoveredImage}
                    isVisible={!!hoveredImage}
                    position={hoverPosition}
                  />

                  <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                      {/* Date - Time Picker */}
                      <Grid item xs={6}>
                        <Stack spacing={2}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={enGB}
                          >
                            <DateTimePicker
                              label={t("Date & Time")}
                              name="datetime"
                              id="datetimepicker"
                              color="secondary"
                              value={parseISO(
                                format(
                                  appointmentDetails.datetime.toDate(),
                                  "yyyy-MM-dd HH:mm:ss"
                                )
                              )}
                              onChange={(newValue) =>
                                handleDateTimeChange(newValue)
                              }
                              required
                              slots={{
                                openPickerButton: StyledButton,
                                date: StyledDateTime,
                              }}
                              slotProps={{
                                clearable: true,
                                openPickerIcon: { fontSize: "large" },
                                openPickerButton: { color: "secondary" },
                                textField: {
                                  variant: "outlined",
                                  color: "secondary",
                                  fullWidth: true,
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.date}
                                  helperText={errors.date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          <Stack direction="row" spacing={2}>
                            <Grid item xs={6}>
                              <Button
                                variant="outlined"
                                onClick={handleCompleteAppointment}
                                color="info"
                                fullWidth
                                sx={{
                                  mt: 1,
                                  mb: 0,
                                  // height: "50px",
                                }}
                              >
                                <Typography
                                  component="h1"
                                  variant="r16"
                                  paddingLeft="10px"
                                >
                                  {t("Complete")}
                                </Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="outlined"
                                onClick={handleCancelAppointment}
                                color="warning"
                                fullWidth
                                sx={{
                                  mt: 1,
                                  mb: 0,
                                  // height: "50px",
                                }}
                              >
                                <Typography
                                  component="h1"
                                  variant="r16"
                                  paddingLeft="10px"
                                >
                                  {t("Cancel1")}
                                </Typography>
                              </Button>
                            </Grid>
                          </Stack>
                        </Stack>
                      </Grid>

                      {/* Notes Field */}
                      <Grid item xs={6}>
                        <TextField
                          label={t("Notes")}
                          name="notes"
                          id="textfield-notes"
                          color="secondary"
                          value={appointmentData.notes}
                          onChange={handleInputChange}
                          fullWidth
                          multiline
                          rows={4}
                          InputLabelProps={
                            appointmentData.notes
                              ? { shrink: true }
                              : { shrink: false }
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Confirm")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EditAppointment;
