// src/screens/AddBrandAndOrigin.jsx

import React, { useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { arrayUnion, doc, updateDoc, setDoc } from "firebase/firestore";
import {
  Box,
  TextField,
  Grid,
  Paper,
  Typography,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import LoadingWindow from "../components/LoadingWindow";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const AddBrandAndOrigin = ({ onClose, onFeedback }) => {
  // const { currentUser } = useAuth();
  const { partBrands, partOrigins } = useData();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [partBrand, setPartBrand] = useState("");
  const [partOrigin, setPartOrigin] = useState("");
  // const [confirmVisible, setConfirmVisible] = useState(false);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    if (name === "partBrand") setPartBrand(value);
    else if (name === "partOrigin") setPartOrigin(value);
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleClearData = () => {
    setPartBrand("");
    setPartOrigin("");
  };

  const updatePartBrandsDocument = async (brand, operation) => {
    // console.log(category);
    const docRef = doc(firestore, "partElements", "g99hwS1QRRqZiXYpPwsq");
    const newBrand = partBrands.find((p) => p.brand === brand);

    if (operation === "Add") {
      if (!newBrand && brand !== "") {
        //   console.log("New Category");
        await setDoc(
          docRef,
          {
            brand: arrayUnion(brand),
          },
          { merge: true }
        );
      }
    } else if (operation === "Update" || operation === "Delete") {
      await updateDoc(docRef, {
        brand: brand,
      });
    }
  };

  const updatePartOriginsDocument = async (origin, operation) => {
    // console.log(category);
    const docRef = doc(firestore, "partElements", "TAGe6Aek0pX4amRYZWuG");
    const newOrigin = partOrigins.find((p) => p.origin === origin);

    if (operation === "Add") {
      if (!newOrigin && origin !== "") {
        // console.log("New Category");
        await setDoc(
          docRef,
          {
            origin: arrayUnion(origin),
          },
          { merge: true }
        );
      }
    } else if (operation === "Update" || operation === "Delete") {
      await updateDoc(docRef, {
        origin: origin,
      });
    }
  };

  const handleAdd = async (type) => {
    if (type === "brand") {
      //   console.log(categories);
      //   console.log(partBrand);
      //   console.log(type);
      updatePartBrandsDocument(partBrand, "Add");
      setPartBrand("");
    } else if (type === "origin") {
      //   console.log(docCategories);
      //   console.log(partOrigin);
      //   console.log(type);
      updatePartOriginsDocument(partOrigin, "Add");
      setPartOrigin("");
    }
  };

  const handleDelete = async (inObj, type) => {
    if (type === "brand") {
      updatePartBrandsDocument(
        partBrands.filter((brand) => brand !== inObj),
        "Delete"
      );
    } else if (type === "origin") {
      updatePartOriginsDocument(
        partOrigins.filter((origin) => origin !== inObj),
        "Delete"
      );
    }
    // setIsAddOpen(true);
    // addBrandData("BrandX", modelData);
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  {/* Text */}
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant="r16"
                          marginBottom={1}
                          sx={{
                            fontSize: {
                              xs: "0.7rem", // font size for extra-small screens
                              sm: "0.8rem", // font size for small screens
                              md: "0.9rem", // font size for medium screens
                              lg: "1.2rem", // font size for large screens
                              xl: "1.5rem", // font size for extra-large screens
                            },
                          }}
                        >
                          {t("Part Brands")}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* File List */}

                    <Box
                      sx={{
                        width: "100%",
                        // minHeight: 300,
                        maxHeight: 600,
                        minWidth: 200,
                        maxWidth: 400,
                        bgcolor: "background.paper",
                        p: 2,
                        overflowY: "auto", // Add vertical scrollbar if needed
                        overflowX: "hidden", // Hide horizontal scrollbar
                        borderRadius: 10,
                      }}
                    >
                      <List>
                        {partBrands.sort().map((brand, index) => (
                          <ListItem
                            key={brand}
                            secondaryAction={
                              <>
                                {/* <Tooltip title={t("Edit Category")}>
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                sx={{ marginLeft: 1 }}
                                onClick={() => handleUpdateClicked(brand, "brand")}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip> */}

                                <Tooltip title={t("Delete Brand")}>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{ marginLeft: 1 }}
                                    onClick={() => handleDelete(brand, "brand")}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            {/* <ListItemIcon>
                        <FileIcon />
                      </ListItemIcon> */}
                            <Typography
                              variant="body1" // Default variant
                              marginRight={7}
                              sx={{
                                fontSize: {
                                  xs: "0.75rem", // Smaller font size on extra-small screens
                                  sm: "0.875rem", // Slightly larger font size on small screens
                                  md: "1rem", // Default font size on medium screens
                                  lg: "1rem", // Larger font size on large screens
                                  xl: "1.5rem", // Largest font size on extra-large screens
                                },
                              }}
                            >
                              {brand}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                      {/* Add Button */}
                      <Box
                        marginTop="10px"
                        marginBottom="10px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          label={t("Brand")}
                          name="partBrand"
                          color="info"
                          value={partBrand}
                          onChange={handleInputChange}
                          fullWidth
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleAdd("brand");
                            }
                          }}
                        />
                        {/* {!confirmVisible && ( */}
                        <Tooltip title={t("Add Brand")}>
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={() => handleAdd("brand")}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        {/* )} */}
                        {/* {confirmVisible && (
                      <Tooltip title={t("Confirm Change")}>
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={() => handleCategoryUpdate()}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                    )} */}
                      </Box>
                    </Box>
                  </Grid>
                  {/* Text */}
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          // component="h1"
                          variant="r16"
                          marginBottom={1}
                          sx={{
                            fontSize: {
                              xs: "0.7rem", // font size for extra-small screens
                              sm: "0.8rem", // font size for small screens
                              md: "0.9rem", // font size for medium screens
                              lg: "1.2rem", // font size for large screens
                              xl: "1.5rem", // font size for extra-large screens
                            },
                          }}
                        >
                          {t("Part Origins")}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* File List */}
                    <Box
                      sx={{
                        width: "100%",
                        // minHeight: 300,
                        maxHeight: 600,
                        minWidth: 200,
                        maxWidth: 400,
                        bgcolor: "background.paper",
                        p: 2,
                        overflowY: "auto", // Add vertical scrollbar if needed
                        overflowX: "hidden", // Hide horizontal scrollbar
                        borderRadius: 10,
                      }}
                    >
                      <List>
                        {partOrigins.sort().map((origin, index) => (
                          <ListItem
                            key={origin}
                            secondaryAction={
                              <>
                                {/* <Tooltip title={t("Edit Category")}>
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                sx={{ marginLeft: 1 }}
                                onClick={() => handleUpdateClicked(origin, "origin")}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip> */}

                                <Tooltip title={t("Delete Origin")}>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{ marginLeft: 1 }}
                                    onClick={() =>
                                      handleDelete(origin, "origin")
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            {/* <ListItemIcon>
                        <FileIcon />
                      </ListItemIcon> */}
                            <Typography
                              variant="body1" // Default variant
                              marginRight={7}
                              sx={{
                                fontSize: {
                                  xs: "0.75rem", // Smaller font size on extra-small screens
                                  sm: "0.875rem", // Slightly larger font size on small screens
                                  md: "1rem", // Default font size on medium screens
                                  lg: "1rem", // Larger font size on large screens
                                  xl: "1.5rem", // Largest font size on extra-large screens
                                },
                              }}
                            >
                              {origin}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>

                      {/* Add Button */}
                      <Box
                        marginTop="10px"
                        marginBottom="10px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          label={t("Origin")}
                          name="partOrigin"
                          color="info"
                          value={partOrigin}
                          onChange={handleInputChange}
                          fullWidth
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleAdd("origin");
                            }
                          }}
                        />
                        {/* {!confirmVisible && ( */}
                        <Tooltip title={t("Add Origin")}>
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={() => handleAdd("origin")}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        {/* )} */}
                        {/* {confirmVisible && (
                      <Tooltip title={t("Confirm Change")}>
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={() => handleCategoryUpdate()}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                    )} */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default AddBrandAndOrigin;
