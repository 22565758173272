// src/screens/ProjectManagement.jsx
import React from "react";
import Board from "../components/Board";

const ProjectManagement = () => {
  return (
    <div>
      <Board />
    </div>
  );
};

export default ProjectManagement;
