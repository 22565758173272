// src/screens/Stocks.jsx

import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Box, useTheme, Typography, Grid, Button } from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import CustomLinearProgress, {
  CustomToolbar,
  BankChip,
  CustomMessage,
} from "../components/Common";
import AddPart from "./AddPart";
import EditPart from "./EditPart";
import Confirmation from "./Confirmation";
import HoverImage from "../components/HoverImage";
import { useData } from "../components/DataProvider";
// import AddCategories from "./AddCategories";
import "../App.css";

const Stocks = () => {
  const { currentUser } = useAuth();
  const { parts } = useData();
  const { loading } = useData();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isAddPartOpen, setIsAddPartOpen] = useState(false);
  const [isEditPartOpen, setIsEditPartOpen] = useState(false);
  const [isDeletePartOpen, setIsDeletePartOpen] = useState(false);
  const [editData, setEditData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });

  const closeModal = () => {
    setIsAddPartOpen(false);
    setIsEditPartOpen(false);
    setIsDeletePartOpen(false);
  };

  // Set selected IDs
  const handleSelectionChange = (newSelection) => {
    // console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  // Part Create
  const handleCreateNewPart = async () => {
    setIsAddPartOpen(true);
  };

  // Part Update
  const handleUpdate = async () => {
    if (selectedRowIds[0]) {
      // Get Parts Collection
      const usersRef = collection(firestore, "parts");
      // Search exact ID within documents
      const q = query(usersRef, where("id", "==", selectedRowIds[0]));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const partDoc = querySnapshot.docs[0].data();
      // Set ID to edit
      setEditData(partDoc);
      // Open Modal
      setIsEditPartOpen(true);
    }
  };

  // Part Delete
  const handleDelete = async () => {
    // selectedRowIds.forEach(async (id) => {
    if (selectedRowIds[0]) setIsDeletePartOpen(true);
    // });
  };

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 85,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    // {
    //   field: "barcode",
    //   headerName: t("Barcode"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "origin",
      headerName: t("Origin"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "category",
      headerName: t("Category"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "internal_pn",
      headerName: t("Internal Part Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "unit",
      headerName: t("Unit"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => t(params.row.unit),
    },
    {
      field: "minimum_stock",
      headerName: t("Minimum Stock"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      type: "number",
      renderCell: (params) => <BankChip {...params} color="info" />,
    },
    {
      field: "current_stock",
      headerName: t("Stock"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      type: "number",
      renderCell: (params) => (
        <div>
          {params.value ? (
            <div>
              {params.value <= params.row.minimum_stock ? (
                <BankChip {...params} color="error" />
              ) : (
                <BankChip {...params} color="success" />
              )}
            </div>
          ) : (
            <BankChip value="0" color="error" />
          )}
        </div>
      ),
    },
    {
      field: "storage_location",
      headerName: t("Storage Location"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    // {
    //   field: "purchase_price",
    //   headerName: t("Purchase Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    //   renderCell: (params) => <div>{params.value ? params.value : 0}</div>,
    // },
    {
      field: "sale_price",
      headerName: t("Sale Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
      renderCell: (params) => <div>{params.value ? params.value : 0}</div>,
    },
  ];

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const datagridMaxHeight = 616;

  if (currentUser.purchasedProducts.includes("stocksModule")) {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95vh",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {/* Feedback Mechanism */}
              <CustomMessage
                open={openMessage}
                onClose={handleMessageClose}
                message={message}
              />
              <Grid item xs={2}>
                <Box mb="30px">
                  <Typography
                    variant="h2"
                    color={colors.grey[200]}
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // font size for extra-small screens
                        sm: "2rem", // font size for small screens
                        md: "3rem", // font size for medium screens
                        lg: "3.5rem", // font size for large screens
                        xl: "3.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                    }}
                  >
                    {t("Stocks")}
                  </Typography>
                  <Typography
                    variant="r16"
                    color={colors.greenAccent[400]}
                    sx={{
                      fontSize: {
                        xs: "1rem", // font size for extra-small screens
                        sm: "1.2rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t("All Stocks")}
                  </Typography>
                </Box>
              </Grid>
              {/* Create Part Modal */}
              {isAddPartOpen && (
                <AddPart onClose={closeModal} onFeedback={handleFeedback} />
              )}

              {/* Edit Part Modal */}
              {isEditPartOpen && (
                <EditPart
                  onClose={closeModal}
                  partData2Update={editData}
                  onFeedback={handleFeedback}
                />
              )}

              {/* Delete Part Modal */}
              {isDeletePartOpen && (
                <Confirmation
                  onClose={closeModal}
                  partToDelete={selectedRowIds[0]}
                  onFeedback={handleFeedback}
                />
              )}

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Create New Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.stocks % 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleCreateNewPart}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.greenAccent[600],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.greenAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Create New Part")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* Edit Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.stocks & 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.blueAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.blueAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Edit Part")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* Delete Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.stocks > 3) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleDelete}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.redAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.redAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Delete Part")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <Box
            m="0px 0px 0px 0px"
            display="flex"
            flex-direction="column"
            //height="100vh" /* Full height of the viewport */
            min-height="0" /* Ensure it can shrink */
            width="100%"
            sx={{
              height: {
                xs: `${datagridMaxHeight / 1.25}px`,
                sm: `${datagridMaxHeight / 1.25}px`,
                md: `${datagridMaxHeight}px`,
                lg: `${datagridMaxHeight}px`,
                xl: `${datagridMaxHeight * 1.5}px`,
              },
              width: "100%",
              "& .MuiDataGrid-root": {
                fontSize: {
                  xs: "0.7rem", // font size for extra-small screens
                  sm: "0.8rem", // font size for small screens
                  md: "0.9rem", // font size for medium screens
                  lg: "1.2rem", // font size for large screens
                  xl: "1.5rem", // font size for extra-large screens
                },
                ...(theme.palette.mode === "dark"
                  ? {
                      border: "1px solid rgba(62, 67, 150, 1)",
                    }
                  : { border: "1px solid rgba(175, 63, 59, 1)" }),
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                fontFamily: "Poppins",
                fontSize: {
                  xs: "0.7rem", // font size for extra-small screens
                  sm: "0.8rem", // font size for small screens
                  md: "0.9rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                fontFamily: "Poppins",
                fontSize: {
                  xs: "0.5rem", // font size for extra-small screens
                  sm: "0.7rem", // font size for small screens
                  md: "0.8rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
                ...(theme.palette.mode === "dark"
                  ? {
                      color: colors.greenAccent[500],
                    }
                  : { color: colors.redAccent[500] }),
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                ...(theme.palette.mode === "dark"
                  ? {
                      backgroundColor: colors.blueAccent[700],
                    }
                  : { backgroundColor: colors.redAccent[800] }),
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-cell:hover": {
                ...(theme.palette.mode === "dark"
                  ? {
                      color: colors.blueAccent[500],
                    }
                  : { color: colors.redAccent[500] }),
              },
            }}
          >
            <DataGrid
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              }}
              checkboxSelection={true}
              disableMultipleRowSelection
              rows={parts}
              columns={columns}
              slots={{
                toolbar: CustomToolbar,
                loadingOverlay: CustomLinearProgress,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[10, 20]}
              loading={loading}
              disableSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                handleSelectionChange(newSelection)
              }
              // onSelectionModelChange={(newSelection) =>
              //   handleSelectionChange(newSelection)
              // }
              style={{ height: "100%", width: "100%" }}
            />
            <HoverImage
              src={hoveredImage}
              isVisible={!!hoveredImage}
              position={hoverPosition}
            />
          </Box>
        </Box>
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95vh",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {/* Feedback Mechanism */}
              <CustomMessage
                open={openMessage}
                onClose={handleMessageClose}
                message={message}
              />
              <Grid item xs={2}>
                <Box mb="30px">
                  <Typography
                    variant="h2"
                    color={colors.redAccent[500]}
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // font size for extra-small screens
                        sm: "2rem", // font size for small screens
                        md: "3rem", // font size for medium screens
                        lg: "3.5rem", // font size for large screens
                        xl: "3.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("ACCESS DENIED!")}
                  </Typography>
                  <Typography
                    variant="r16"
                    color={colors.grey[300]}
                    sx={{
                      fontSize: {
                        xs: "0.9rem", // font size for extra-small screens
                        sm: "1.1rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t(
                      "Unauthorized login attempt! You may purchase this module."
                    )}
                  </Typography>
                </Box>
              </Grid>

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Buy Button */}
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  // onClick={handleRedirection("stocks")}
                  fullWidth
                  sx={{
                    mt: {
                      xs: 0,
                      sm: 0,
                      md: 1,
                      lg: 1,
                      xl: 1,
                    },
                    backgroundColor: colors.greenAccent[600],
                    color: colors.grey[100],
                    height: "50px",
                    "&:hover": {
                      backgroundColor: colors.greenAccent[800],
                    },
                  }}
                >
                  <Typography
                    component="h1"
                    variant="r16"
                    sx={{
                      fontSize: {
                        xs: "0.7rem", // font size for extra-small screens
                        sm: "0.8rem", // font size for small screens
                        md: "0.9rem", // font size for medium screens
                        lg: "1.2rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                    }}
                  >
                    {t("Purchase This Module")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Stocks;
