// src/components/Common.jsx

import React from "react";
import { LinearProgress, TextField } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Chip,
  Button,
  Switch,
  Snackbar,
  IconButton,
  Typography,
  // useMediaQuery,
} from "@mui/material";
import {
  GridToolbarContainer,
  // GridToolbarColumnsButton,
  // GridToolbarFilterButton,
  GridToolbarExport,
  // GridToolbarDensitySelector,
  // useGridApiContext,
  GridFilterPanel,
  // GridLogicOperator,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/ReportProblemOutlined";
import { t } from "i18next";
// import SearchIcon from "@mui/icons-material/ManageSearch";
// import DownloadIcon from "@mui/icons-material/FileDownload";

export function CustomFilterPanel(props) {
  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="h6" gutterBottom>
        {t("Search Panel")}
      </Typography>
      <TextField
        variant="outlined"
        color="secondary"
        label={t("Search")}
        fullWidth
        sx={{ marginBottom: "16px" }}
      />
      <GridFilterPanel {...props} />
    </Box>
  );
}

export function CustomToolbar() {
  const buttonProps = {
    variant: "outlined",
    color: "secondary",
    sx: {
      // padding: '12px 24px', // Adjust padding
      fontSize: "12px", // Adjust font size
      // marginBottom: "5px", // Adjust margin if needed
    },
  };

  // const FilterButton = () => {
  //   const apiRef = useGridApiContext();
  //   const handleClick = () => {
  //     apiRef.current.showFilterPanel();
  //   };

  //   return (
  //     <Button {...buttonProps} onClick={handleClick}>
  //       <SearchIcon fontSize="large" sx={{ marginRight: 2 }} />
  //       {t("Search")}
  //     </Button>
  //   );
  // };

  // const ExportButton = () => {
  //   const apiRef = useGridApiContext();

  //   const handleClick = () => {
  //     apiRef.current.exportDataAsCsv();
  //   };

  //   return (
  //     <Button {...buttonProps} onClick={handleClick}>
  //       <DownloadIcon fontSize="medium" sx={{ marginRight: 2 }} />
  //       {t("Download")}
  //     </Button>
  //   );
  // };

  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton
        slotProps={{
          button: {
            variant: "outlined",
            color: "secondary",
            padding: "12px 24px", // Adjust padding
            fontSize: "18px", // Adjust font size
            margin: "5px", // Adjust margin if needed
          },
        }}
      /> */}
      {/* <FilterButton /> */}
      {/* <GridToolbarDensitySelector
        slotProps={{
          tooltip: { title: "Change density" },
          button: {
            variant: "outlined",
            color: "secondary",
            padding: "12px 24px", // Adjust padding
            fontSize: "18px", // Adjust font size
            margin: "5px", // Adjust margin if needed
          },
        }}
      /> */}
      {/* <Box sx={{ flexGrow: 1 }} /> */}

      <GridToolbarQuickFilter
        color={"secondary"}
        // variant={"outlined"}
        sx={{
          marginLeft: "10px",
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
      {/* <ExportButton /> */}
      <GridToolbarExport slotProps={{ button: buttonProps }} />
    </GridToolbarContainer>
  );
}

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  "& .MuiLinearProgress-bar": {
    ...(theme.palette.mode === "dark"
      ? {
          backgroundColor: "#4C0AC6", // Your desired color
        }
      : {
          backgroundColor: "#ff6f61", // Your desired color
        }),
  },
  backgroundColor: "#ffcccb", // Background color of the progress bar
}));

export const OvalButton = ({ onClick, label, color, variant }) => (
  <Button
    onClick={onClick}
    variant={variant ? variant : "contained"}
    color={"success"}
    sx={{
      fontSize: {
        xs: "0.5rem", // font size for extra-small screens
        sm: "0.7rem", // font size for small screens
        md: "0.8rem", // font size for medium screens
        lg: "0.9rem", // font size for large screens
        xl: "1rem", // font size for extra-large screens
      },
    }}
    style={
      variant
        ? {
            borderRadius: 999,
            minWidth: 36,
            padding: 6,
            backgroundColor: color,
            color: "white", // Text color
            marginRight: 8,
          }
        : {
            borderRadius: 999,
            minWidth: 36,
            padding: 6,
            backgroundColor: color,
            color: "white", // Text color
            marginRight: 8,
          }
    }
  >
    {label}
  </Button>
);

// Custom cell renderer for status using Chip
export const StatusChip = (params, color) => {
  const { value } = params;

  return (
    <Chip
      label={value}
      color={value === "Waiting" ? "success" : "error"}
      variant="outlined"
      style={{ fontSize: 16 }}
    />
  );
};

// Custom cell renderer for status using Chip
export const AuthChip = (params) => {
  const { value } = params;

  return (
    <Chip
      label={value}
      color={
        value === "Admin" || value === "All"
          ? "info"
          : value === "User"
          ? "success"
          : value === "Guest"
          ? "warning"
          : "error"
      }
      variant="outlined"
      style={{ fontSize: 16 }}
    />
  );
};

export const BankChip = ({ key, value, color }) => {
  // const theme = useTheme();
  // console.log(key, value, color);

  let chipColor;
  switch (key) {
    case 0:
      chipColor = "success";
      break;
    case 1:
      chipColor = "warning";
      break;
    case 2:
      chipColor = "primary";
      break;
    case 3:
      chipColor = "error";
      break;
    default:
      chipColor = "default";
  }

  return (
    <Chip
      label={value}
      color={color || chipColor}
      variant="outlined"
      sx={{
        fontSize: {
          xs: 8, // font size for extra-small screens
          sm: 12, // font size for small screens
          md: 16, // font size for medium screens
          lg: 16, // font size for large screens
          xl: 20, // font size for extra-large screens
        },
        // color: theme.palette[chipColor].contrastText,
        // borderColor: theme.palette[chipColor].main,
      }}
    />
  );
};

export const SpadaSwitch = styled(Switch)(
  ({ theme, foreColor, trackColor, iconColor }) => ({
    width: 75, // Adjust width
    height: 32, // Adjust height
    padding: 5, // Adjust padding if necessary
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(40px)",
      color: foreColor,
      "&:hover": {
        backgroundColor: alpha(foreColor, theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-thumb": {
      width: 16, // Adjust thumb width
      height: 14, // Adjust thumb height
      borderRadius: 8, // Adjust borderRadius for rounded rectangle
    },
    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 20, // Increase icon size
        height: 20, // Increase icon size
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          // theme.palette.getContrastText(theme.palette.primary.main)
          theme.palette.getContrastText(iconColor)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 6, // Adjust position
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          // theme.palette.getContrastText(theme.palette.primary.main)
          theme.palette.getContrastText(iconColor)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 10, // Adjust position
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: foreColor,
    },
  })
);

export const CustomMessage = ({ theme, open, onClose, message }) => {
  const { t } = useTranslation();
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const snackbarAction = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleMessageClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // Snackbar colors based on the theme
  const snackbarStyle = {
    // ...(theme.palette.mode === "dark"
    //   ? {
    //       backgroundColor: "#333", // Your desired color
    //     }
    //   : {
    //       backgroundColor: "#fff", // Your desired color
    //     }),
    // color: prefersDarkMode ? "#fff" : "#000", // Dark mode or light mode text color
    backgroundColor: "#333",
    display: "flex",
    alignItems: "center",
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      message={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {message === t("Operation Successfull!") ? (
            <>
              <CheckIcon sx={{ marginRight: 1, color: "green" }} />
              <Typography sx={{ color: "green" }}>{message}</Typography>
            </>
          ) : (
            <>
              <ErrorIcon sx={{ marginRight: 1, color: "red" }} />
              <Typography sx={{ color: "red" }}>{message}</Typography>
            </>
          )}
        </Box>
      }
      action={snackbarAction}
      sx={snackbarStyle}
    />
  );
};

export default CustomLinearProgress;
