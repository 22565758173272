// src/screens/Appointments.jsx

import React, { useEffect, useState } from "react";
import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import {
  collection,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Badge,
  Box,
  useTheme,
  Typography,
  Grid,
  Button,
  Stack,
  FormControl,
  FormGroup,
  styled,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import CustomLinearProgress, {
  BankChip,
  CustomMessage,
  CustomToolbar,
  OvalButton,
  SpadaSwitch,
} from "../components/Common";
import ViewAppointmentDetails from "./ViewAppointmentDetails";
import AddAppointment from "./AddAppointments";
import EditAppointment from "./EditAppointments";
import DeleteAppointment from "./DeleteAppointment";
import AppointmentToWork from "./AppointmentToWork";
import { useData } from "../components/DataProvider";
import HoverImage from "../components/HoverImage";
import "../App.css";

const StyledBadge = styled(Badge)(({ theme, foreColor, bgColor }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: bgColor,
    color: foreColor,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Appointments = () => {
  const { currentUser } = useAuth();
  const { appointments, loading } = useData();
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [filteredData, setFilteredData] = useState(appointments);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [idToClean, setIdToClean] = useState("");
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isWorkHookOpen, setIsWorkHookOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(false);
  const [hideCompletedChecked, setHideCompletedChecked] = useState(false);
  const [hideCancelledChecked, setHideCancelledChecked] = useState(false);
  const [hideExpiredChecked, setHideExpiredChecked] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });

  const closeModal = () => {
    setIsAddOpen(false);
    setIsUpdateOpen(false);
    setIsDetailsOpen(false);
    setIsWorkHookOpen(false);
  };

  const closeDoneModal = async () => {
    setIsWorkHookOpen(false);
    // Create document reference
    const appointmentRef = collection(firestore, "appointments");
    // Search exact ID within documents
    const q = query(appointmentRef, where("id", "==", idToClean));
    // Search the query
    const querySnapshot = await getDocs(q);
    // Find the exact document
    const appointmentDoc = querySnapshot.docs[0];
    // Create reference to this document
    const docRef = doc(firestore, "appointments", appointmentDoc.id);
    // Update the document
    await updateDoc(docRef, { status: "2" });

    setIdToClean("");
  };

  // Set selected IDs
  const handleSelectionChange = (newSelection) => {
    setSelectedRowIds(newSelection);
  };

  const handleCreateNew = async () => {
    setIsAddOpen(true);
  };

  const handleDelete = async () => {
    if (selectedRowIds[0]) setIsDeleteOpen(true);
  };

  const handleCompleteAppointment = async (selectedID) => {
    // console.log(selectedID);
    setIdToClean(selectedID);

    if (selectedID) {
      // Get Parts Collection
      const appointmentsRef = collection(firestore, "appointments");
      // Search exact ID within documents
      const q = query(appointmentsRef, where("id", "==", selectedID));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const appointmentsDoc = querySnapshot.docs[0].data();
      // console.log(selectedRowIds[0]);
      // console.log(appointmentsDoc);
      // Set ID to edit
      setAppointmentDetails(appointmentsDoc);
      // Open Modal
      setIsWorkHookOpen(true);
    }
  };

  const handleCancelAppointment = async (selectedID) => {
    // Create document reference
    const appointmentRef = collection(firestore, "appointments");
    // Search exact ID within documents
    const q = query(appointmentRef, where("id", "==", selectedID));
    // Search the query
    const querySnapshot = await getDocs(q);
    // Find the exact document
    const appointmentDoc = querySnapshot.docs[0];
    // Create reference to this document
    const docRef = doc(firestore, "appointments", appointmentDoc.id);
    // Update the document
    await updateDoc(docRef, { status: "3" });
  };

  useEffect(() => {
    let newData = appointments;

    if (hideExpiredChecked) {
      newData = newData.filter((appointment) => appointment.status !== "1");
    }
    if (hideCompletedChecked) {
      newData = newData.filter((appointment) => appointment.status !== "2");
    }
    if (hideCancelledChecked) {
      newData = newData.filter((appointment) => appointment.status !== "3");
    }

    setFilteredData(newData);
  }, [
    hideCompletedChecked,
    hideCancelledChecked,
    hideExpiredChecked,
    appointments,
  ]);

  const handleUpdate = async () => {
    if (selectedRowIds[0]) {
      // Get Parts Collection
      const appointmentsRef = collection(firestore, "appointments");
      // Search exact ID within documents
      const q = query(appointmentsRef, where("id", "==", selectedRowIds[0]));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const appointmentsDoc = querySnapshot.docs[0].data();
      // Set ID to edit
      setAppointmentDetails(appointmentsDoc);
      // Open Modal
      setIsUpdateOpen(true);
    }
  };

  const handleViewDetails = async () => {
    if (selectedRowIds[0]) {
      // Get Parts Collection
      const appointmentsRef = collection(firestore, "appointments");
      // Search exact ID within documents
      const q = query(appointmentsRef, where("id", "==", selectedRowIds[0]));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const appointmentsDoc = querySnapshot.docs[0].data();
      // Set ID to edit
      setAppointmentDetails(appointmentsDoc);
      // Open Modal
      setIsDetailsOpen(true);
    }
  };

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 85,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: t("Full Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "motorcycle",
      headerName: t("Motorcycle"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
      valueGetter: (params) => {
        const motorcycle = params;

        return motorcycle
          ? `${motorcycle.brand} ${motorcycle.model} - ${motorcycle.license_plate}`
          : "N/A";
      },
    },
    {
      field: "phone",
      headerName: t("Phone Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 170,
    },
    {
      field: "datetime",
      headerName: t("Date & Time"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 190,
      renderCell: (params) =>
        format(params.row.datetime.toDate(), "dd/MM/yyyy HH:mm:ss"),
    },
    {
      field: "status",
      headerName: t("Status"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => {
        const currentDate = new Date();
        const appointmentDate = params.row.datetime.toDate();

        if (appointmentDate < currentDate) {
          switch (params.row.status) {
            case "2":
              return <BankChip {...params} value={t("Done")} color="success" />;
            case "3":
              return (
                <BankChip {...params} value={t("Cancelled")} color="warning" />
              );
            default:
              return (
                <>
                  <StyledBadge
                    bgColor="#a71e06"
                    foreColor="#ed2c0a"
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    sx={{ marginRight: "10px" }}
                  />
                  <OvalButton
                    label={t("Complete")}
                    color={colors.greenAccent[500]}
                    onClick={() => handleCompleteAppointment(params.row.id)}
                  />
                  <OvalButton
                    label={t("Cancel")}
                    color={colors.redAccent[500]}
                    onClick={() => handleCancelAppointment(params.row.id)}
                  />
                </>
              );
          }
          // biome-ignore lint/style/noUselessElse: <explanation>
        } else {
          switch (params.row.status) {
            case "0":
              if (
                appointmentDate.getMonth() === currentDate.getMonth() &&
                appointmentDate.getDate() === currentDate.getDate()
              ) {
                return (
                  <>
                    <StyledBadge
                      bgColor="#44b700"
                      foreColor="#44b700"
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                      sx={{ marginRight: "10px" }}
                    />
                    <OvalButton
                      label={t("Complete")}
                      color={colors.greenAccent[500]}
                      onClick={() => handleCompleteAppointment(params.row.id)}
                    />
                    <OvalButton
                      label={t("Cancel")}
                      color={colors.redAccent[500]}
                      onClick={() => handleCancelAppointment(params.row.id)}
                    />
                  </>
                );
                // biome-ignore lint/style/noUselessElse: <explanation>
              } else {
                return (
                  <>
                    <OvalButton
                      label={t("Complete")}
                      color={colors.greenAccent[500]}
                      onClick={() => handleCompleteAppointment(params.row.id)}
                    />
                    <OvalButton
                      label={t("Cancel")}
                      color={colors.redAccent[500]}
                      onClick={() => handleCancelAppointment(params.row.id)}
                    />
                  </>
                );
              }

            case "2":
              return <BankChip {...params} value={t("Done")} color="success" />;
            case "3":
              return (
                <BankChip {...params} value={t("Cancelled")} color="warning" />
              );
            default:
              return null; // Return null or any default element if none of the conditions match
          }
        }
      },
    },
  ];

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const datagridMaxHeight = 616;

  if (currentUser.purchasedProducts.includes("appointmentsModule")) {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95vh",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {/* Feedback Mechanism */}
              <CustomMessage
                open={openMessage}
                onClose={handleMessageClose}
                message={message}
              />
              <Grid item xs={2}>
                <Box mb="30px">
                  <Typography
                    variant="h2"
                    color={colors.grey[200]}
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // font size for extra-small screens
                        sm: "2rem", // font size for small screens
                        md: "3rem", // font size for medium screens
                        lg: "3.5rem", // font size for large screens
                        xl: "3.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                    }}
                  >
                    {t("Appointments")}
                  </Typography>
                  <Typography
                    variant="r16"
                    color={colors.greenAccent[400]}
                    sx={{
                      fontSize: {
                        xs: "1rem", // font size for extra-small screens
                        sm: "1.2rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t("All Appointments")}
                  </Typography>
                </Box>
              </Grid>

              {/* Create Moto Modal */}
              {isAddOpen && (
                <AddAppointment
                  onClose={closeModal}
                  onFeedback={handleFeedback}
                />
              )}

              {/* View Details Modal */}
              {isDetailsOpen && (
                <ViewAppointmentDetails
                  onClose={closeModal}
                  appointmentDetails={appointmentDetails}
                />
              )}

              {/* Update Appointment Modal */}
              {isUpdateOpen && (
                <EditAppointment
                  onClose={closeModal}
                  appointmentDetails={appointmentDetails}
                  onFeedback={handleFeedback}
                />
              )}

              {/* Delete Appointment Modal */}
              {isDeleteOpen && (
                <DeleteAppointment
                  onClose={closeModal}
                  appointmentToDelete={selectedRowIds[0]}
                  onFeedback={handleFeedback}
                />
              )}

              {/* Work Hook Modal */}
              {isWorkHookOpen && (
                <AppointmentToWork
                  onClose={closeModal}
                  extHookData={appointmentDetails}
                  onFeedback={handleFeedback}
                  extraFeedback={closeDoneModal}
                />
              )}

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Details Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.appointments % 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleViewDetails}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.greenAccent[600],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.greenAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("View Details")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* Create New Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.appointments & 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleCreateNew}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.blueAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.blueAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Create New Appointment")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* Update Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.appointments > 3) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.redAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.redAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Edit Appointment")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Delete Button */}
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleDelete}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.redAccent[700],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.redAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Remove Appointment")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <Grid item xs={12} ml={"10px"}>
            <Stack direction="row">
              {/* Hide Completed Switch */}
              <FormControl component="fieldset" style={{ marginLeft: "0px" }}>
                <FormGroup>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="r12"
                      sx={{
                        fontSize: {
                          xs: "0.5rem",
                          sm: "0.6rem",
                          md: "0.7rem",
                          lg: "0.8rem",
                          xl: "1rem",
                        },
                      }}
                    >
                      {t("Hide Completed")}
                    </Typography>
                    <SpadaSwitch
                      foreColor={colors.greenAccent[500]}
                      iconColor={"#000000"}
                      onChange={() =>
                        setHideCompletedChecked(!hideCompletedChecked)
                      }
                      checked={hideCompletedChecked}
                    />
                  </Box>
                </FormGroup>
              </FormControl>

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Hide Cancelled Switch */}
              <FormControl component="fieldset" style={{ marginLeft: "0px" }}>
                <FormGroup>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="r12"
                      sx={{
                        fontSize: {
                          xs: "0.5rem",
                          sm: "0.6rem",
                          md: "0.7rem",
                          lg: "0.8rem",
                          xl: "1rem",
                        },
                      }}
                    >
                      {t("Hide Cancelled")}
                    </Typography>
                    <SpadaSwitch
                      foreColor={colors.redAccent[500]}
                      iconColor={"#000000"}
                      onChange={() =>
                        setHideCancelledChecked(!hideCancelledChecked)
                      }
                      checked={hideCancelledChecked}
                    />
                  </Box>
                </FormGroup>
              </FormControl>

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Hide Expired Switch */}
              <FormControl component="fieldset" style={{ marginLeft: "0px" }}>
                <FormGroup>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="r12"
                      sx={{
                        fontSize: {
                          xs: "0.5rem",
                          sm: "0.6rem",
                          md: "0.7rem",
                          lg: "0.8rem",
                          xl: "1rem",
                        },
                      }}
                    >
                      {t("Hide Expired")}
                    </Typography>
                    <SpadaSwitch
                      foreColor={colors.blueAccent[500]}
                      iconColor={"#000000"}
                      onChange={() =>
                        setHideExpiredChecked(!hideExpiredChecked)
                      }
                      checked={hideExpiredChecked}
                    />
                  </Box>
                </FormGroup>
              </FormControl>
            </Stack>
          </Grid>
          <Box
            m="0px 0px 0px 0px"
            display="flex"
            flex-direction="column"
            min-height="400px"
            width="100%"
            overflow={"auto"}
            sx={{
              height: {
                xs: `${datagridMaxHeight / 1.25}px`,
                sm: `${datagridMaxHeight / 1.25}px`,
                md: `${datagridMaxHeight}px`,
                lg: `${datagridMaxHeight}px`,
                xl: `${datagridMaxHeight * 1.5}px`,
              },
              width: "100%",
              "& .MuiDataGrid-root": {
                fontSize: {
                  xs: "0.7rem", // font size for extra-small screens
                  sm: "0.8rem", // font size for small screens
                  md: "0.9rem", // font size for medium screens
                  lg: "1.2rem", // font size for large screens
                  xl: "1.5rem", // font size for extra-large screens
                },
                ...(theme.palette.mode === "dark"
                  ? {
                      border: "1px solid rgba(62, 67, 150, 1)",
                    }
                  : { border: "1px solid rgba(175, 63, 59, 1)" }),
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                fontFamily: "Poppins",
                fontSize: {
                  xs: "0.7rem", // font size for extra-small screens
                  sm: "0.8rem", // font size for small screens
                  md: "0.9rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                fontFamily: "Poppins",
                fontSize: {
                  xs: "0.5rem", // font size for extra-small screens
                  sm: "0.7rem", // font size for small screens
                  md: "0.8rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
                ...(theme.palette.mode === "dark"
                  ? {
                      color: colors.greenAccent[500],
                    }
                  : { color: colors.redAccent[500] }),
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                ...(theme.palette.mode === "dark"
                  ? {
                      backgroundColor: colors.blueAccent[700],
                    }
                  : { backgroundColor: colors.redAccent[800] }),
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-cell:hover": {
                ...(theme.palette.mode === "dark"
                  ? {
                      color: colors.blueAccent[500],
                    }
                  : { color: colors.redAccent[500] }),
              },
            }}
          >
            <DataGrid
              columnVisibilityModel={{
                id: false,
              }}
              checkboxSelection={true}
              disableMultipleRowSelection
              rows={filteredData}
              columns={columns}
              slots={{
                toolbar: CustomToolbar,
                loadingOverlay: CustomLinearProgress,
              }}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 20]}
              disableSelectionOnClick
              disableExtendRowFullWidth={true}
              onRowSelectionModelChange={(newSelection) =>
                handleSelectionChange(newSelection)
              }
              style={{ height: "100%", width: "100%" }}
            />
            <HoverImage
              src={hoveredImage}
              isVisible={!!hoveredImage}
              position={hoverPosition}
            />
          </Box>
        </Box>
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95vh",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {/* Feedback Mechanism */}
              <CustomMessage
                open={openMessage}
                onClose={handleMessageClose}
                message={message}
              />
              <Grid item xs={2}>
                <Box mb="30px">
                  <Typography
                    variant="h2"
                    color={colors.redAccent[500]}
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // font size for extra-small screens
                        sm: "2rem", // font size for small screens
                        md: "3rem", // font size for medium screens
                        lg: "3.5rem", // font size for large screens
                        xl: "3.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("ACCESS DENIED!")}
                  </Typography>
                  <Typography
                    variant="r16"
                    color={colors.grey[300]}
                    sx={{
                      fontSize: {
                        xs: "0.9rem", // font size for extra-small screens
                        sm: "1.1rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t(
                      "Unauthorized login attempt! You may purchase this module."
                    )}
                  </Typography>
                </Box>
              </Grid>

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Buy Button */}
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  // onClick={handleRedirection("appointments")}
                  fullWidth
                  sx={{
                    mt: {
                      xs: 0,
                      sm: 0,
                      md: 1,
                      lg: 1,
                      xl: 1,
                    },
                    backgroundColor: colors.greenAccent[600],
                    color: colors.grey[100],
                    height: "50px",
                    "&:hover": {
                      backgroundColor: colors.greenAccent[800],
                    },
                  }}
                >
                  <Typography
                    component="h1"
                    variant="r16"
                    sx={{
                      fontSize: {
                        xs: "0.7rem", // font size for extra-small screens
                        sm: "0.8rem", // font size for small screens
                        md: "0.9rem", // font size for medium screens
                        lg: "1.2rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                    }}
                  >
                    {t("Purchase This Module")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Appointments;
