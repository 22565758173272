// src/screens/ViewSupplierDetails.jsx

import React, { useCallback, useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { storage } from "../firebaseConfig";
import { ref } from "firebase/storage";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemText,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import FileIcon from "@mui/icons-material/InsertDriveFile";
// Images
import emptyPhoto from "../assets/no_image.png";

const ViewSupplierDetails = ({ onClose, supplierDetails }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const businessStartDate = format(
    supplierDetails.business_commencement.toDate(),
    "dd/MM/yyyy"
  );

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleFileOpenClick = (url) => {
    window.open(url, getFileName(url), "noopener,noreferrer");
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  const imageSize = {
    width: 400, // Adjust the width as needed
    height: 400, // Adjust the height as needed
  };

  const renderPhoto = () => {
    if (supplierDetails.photo) {
      return (
        <img
          src={supplierDetails.photo}
          alt="Client"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <img
          src={emptyPhoto}
          alt="Empty"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
    }
  };

  const handleCancel = async () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("View Details")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Photo Section */}
                  <Grid item xs={12} md={12} container justifyContent="center">
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {renderPhoto()}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Corporation Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Corporation")}
                      name="corporation"
                      color="secondary"
                      value={supplierDetails.corporation}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Contact Name Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Contact Name")}
                      name="contact_name"
                      color="secondary"
                      value={supplierDetails.contact_name}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* E-Mail Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("E-Mail")}
                      name="email"
                      color="secondary"
                      value={supplierDetails.email}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Phone Field */}
                  <Grid item xs={6}>
                    <TextField
                      label={t("Phone")}
                      name="phone"
                      color="secondary"
                      value={supplierDetails.phone}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Phone Field */}
                  <Grid item xs={6}>
                    <TextField
                      label={t("Business Commencement")}
                      name="business_commencement"
                      color="secondary"
                      value={businessStartDate}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Contentment Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Contentment")}
                      name="contentment"
                      color="secondary"
                      value={supplierDetails.contentment}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Category Field */}
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      options={[]}
                      fullWidth
                      disablePortal
                      multiple
                      id="combobox-categories"
                      name="categories"
                      color="secondary"
                      value={supplierDetails.categories || []}
                      filterSelectedOptions
                      readOnly
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Categories")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Address Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Address")}
                      name="address"
                      color="secondary"
                      value={supplierDetails.address}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Description Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Description")}
                      name="description"
                      color="secondary"
                      value={supplierDetails.description}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* File List */}
                  <Grid item xs={12}>
                    <List>
                      {supplierDetails.documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleFileOpenClick(doc)}
                            >
                              <FileIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={getFileName(doc)} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ViewSupplierDetails;
