// src/screens/ViewMotorcycleWorks.jsx

import React, { useEffect, useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  TextField,
  Autocomplete,
} from "@mui/material";
import { format } from "date-fns";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import ViewWorkDetails from "./ViewWorkDetails";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import "../App.css";

const ViewMotorcycleWorks = ({ onClose, motoRef }) => {
  //   const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  //   const workDate = format(motoRef.date.toDate(), "dd/MM/yyyy");
  const [dates, setDates] = useState([]);
  const [works, setWorks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedWork, setSelectedWork] = useState(null);
  const [isWorkDetailsOpen, setIsWorkDetailsOpen] = useState(null);

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeWorkModal = () => {
    setIsWorkDetailsOpen(false);
  };

  const handleViewDetails = () => {
    setIsWorkDetailsOpen(true);
  };

  const handleDateChange = (event, value) => {
    setSelectedDate(value);

    if (value) {
      const work = works.find(
        (work) => format(work.date.toDate(), "dd/MM/yyyy HH:mm:ss") === value
      );

      setSelectedWork(work);
    } else {
      setSelectedWork(null);
    }
  };

  const handleCancel = async () => {
    setSelectedWork(null);
    setSelectedDate(null);
    setWorks([]);
    setDates([]);
    handleClose();
  };

  useEffect(() => {
    const docReference = doc(firestore, motoRef);
    // console.log(docReference);

    const fetchWorks = async () => {
      try {
        const q = query(
          collection(firestore, "works"),
          where("motorcycle", "==", docReference)
        );

        const querySnapshot = await getDocs(q);

        const worksData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          //   readableDate: format(doc.data().date, "yyyy-MM-dd"),
          ...doc.data(),
        }));

        // Extract unique dates and sort them
        const uniqueDates = Array.from(
          new Set(worksData.map((work) => work.date))
        )
          .sort((a, b) => new Date(b) - new Date(a))
          .map((date) => format(date.toDate(), "dd/MM/yyyy HH:mm:ss"));

        setDates(uniqueDates);

        setWorks(worksData);
        // console.log(worksData);
      } catch (error) {
        console.error("Error fetching works: ", error);
      } finally {
      }
    };

    if (motoRef) {
      fetchWorks();
    }
  }, [motoRef]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 480,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  minHeight: "15vh",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* View Details Modal */}
                {isWorkDetailsOpen && (
                  <ViewWorkDetails
                    onClose={closeWorkModal}
                    workDetails={selectedWork}
                  />
                )}

                {/* Container */}
                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("View Works By Dates")}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6} container justifyContent="center">
                    <Autocomplete
                      options={dates}
                      fullWidth
                      disablePortal
                      id="combobox-dates"
                      name="date"
                      color="secondary"
                      value={selectedDate || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleDateChange("dates", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Dates")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Show Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      onClick={handleViewDetails}
                      fullWidth
                      color="info"
                      sx={{
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Show Details")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Work Details Field */}
                  <Grid item xs={12}>
                    <TextField
                      label={t("Work Details")}
                      name="work_details"
                      color="secondary"
                      multiline
                      rows={4}
                      value={selectedWork ? selectedWork.work_details : ""}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Notes Field */}
                  <Grid item xs={12}>
                    <TextField
                      label={t("Notes")}
                      name="notes"
                      color="secondary"
                      multiline
                      rows={4}
                      value={selectedWork ? selectedWork.notes : ""}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Close")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ViewMotorcycleWorks;
