// src/components/AuthContext.jsx

import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore } from "../firebaseConfig";
import { onAuthStateChanged, getIdToken, signOut } from "firebase/auth";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch user data from Firestore
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docRef = collection(firestore, "users");
        const q = query(docRef, where("email", "==", user.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const docRef = doc.ref;

          const unsubscribeSnapshot = onSnapshot(
            docRef,
            async (docSnapshot) => {
              if (docSnapshot.exists()) {
                setCurrentUser(docSnapshot.data());
                const authToken = await getIdToken(user);
                setAuthToken(authToken);
              } else {
                setCurrentUser(null);
                setAuthToken(null);
              }
              setLoading(false);
            }
          );

          // Cleanup the onSnapshot listener when the component unmounts or when the user changes
          return () => unsubscribeSnapshot();
          // biome-ignore lint/style/noUselessElse: <explanation>
        } else {
          setCurrentUser(null);
          setAuthToken(null);
          setLoading(false);
        }
      } else {
        setCurrentUser(null);
        setAuthToken(null);
        setLoading(false);
      }
    });

    // Cleanup the onAuthStateChanged listener when the component unmounts
    return () => unsubscribeAuth();
  }, []);

  const handleSignOut = async () => {
    await signOut(auth);
    setCurrentUser(null);
    setAuthToken(null);
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, authToken, loading, signOut: handleSignOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};
