// src/components/Card.jsx
import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  TextField,
  Button,
  List,
  ListItem,
  Fade,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { firestore, storage } from "../firebaseConfig";
import { Draggable } from "react-beautiful-dnd";
import {
  deleteObject,
  ref,
  getDownloadURL,
  listAll,
  uploadBytes,
} from "firebase/storage";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";
// import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DescriptionIcon from "@mui/icons-material/Description";
import NotesIcon from "@mui/icons-material/Notes";
// import { v4 as uuidv4 } from "uuid"; // For unique attachment IDs
import "../App.css";

const Card = ({ card, columnId, deleteCard, index }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(card.content);
  const [description, setDescription] = useState(card.description);
  // const [comments, setComments] = useState(card.comments);
  const [attachments, setAttachments] = useState(card.attachments);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    try {
      // Delete marked files
      // await handleFileDelete();

      // Upload documents
      const documentUrls = [];
      for (const file of newFiles) {
        const documentRef = ref(
          storage,
          `Projects/Boards/Columns/Cards/${card.id}`
        );
        await uploadBytes(documentRef, file);
        const url = await getDownloadURL(documentRef);
        documentUrls.push(url);
      }

      if (attachments.length)
        attachments.map((file) => documentUrls.push(file));

      // Create document reference
      const columnRef = collection(firestore, "columns");
      // Search exact ID within documents
      const q = query(columnRef, where("id", "==", columnId));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const columnDoc = querySnapshot.docs[0];
      // Get Data
      const columnData = columnDoc.data();
      // Cards Data
      const cardData = columnData.cards;

      // console.log(cardData);

      for (const [key, value] of Object.entries(cardData)) {
        const { id } = value;

        // console.log("Card ID: ", id);

        if (id === card.id) {
          const updatedCardData = {
            id: card.id,
            content: content,
            description: description,
            attachments: documentUrls,
          };

          // console.log("New Card Data: ", updatedCardData);

          // const updatedColumn = {
          //   ...columnData,
          //   cards: [updatedCardData],
          // };

          const docRef = doc(firestore, "columns", columnDoc.id);
          // console.log(docRef);
          // Update
          await updateDoc(docRef, {
            cards: { ...columnData.cards, [key]: updatedCardData },
          });
          // console.log("Card Updated!");
        } else {
          console.error("Couldn't find the related id");
        }
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      handleClose();
    }
    handleClose();
  };

  const handleAddAttachment = (event) => {
    const files = Array.from(event.target.files);
    setAttachments(files);
  };

  const handleDeleteCard = () => {
    // Handle card deletion
    // handleFileDelete();
    deleteCard(card.id);
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  // Mark files for delete operation
  const handleMarkFiles = (filePath) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      filePath: [...(prevFiles.filePath || []), filePath],
    }));
    // console.log(files);
    setAttachments(attachments.filter((file) => file !== filePath));
  };

  // Delete files
  const handleFileDelete = async () => {
    const docsRef = ref(storage, `Projects/Boards/Columns/Cards/${card.id}`);

    try {
      // List all items (file paths) under the path
      const res = await listAll(docsRef);

      // Delete files that match
      await Promise.all(
        res.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);

          if (files.filePath.includes(downloadURL)) {
            await deleteObject(itemRef);
            // console.log("Deleted:", downloadURL);
          } else {
            // console.log("Not Deleted:", downloadURL);
          }
        })
      );

      // console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  // Delete new files from list
  const handleNewDeleteFile = (filePath) => {
    setNewFiles(newFiles.filter((file) => file !== filePath));
  };

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // className={`card ${snapshot.isDragging ? "dragging" : ""}`}
          sx={{
            border:
              theme.palette.mode !== "dark"
                ? "1px solid rgba(200, 200, 200, 0.87)"
                : "1px solid rgba(50, 50, 50, 0.87)",
            borderRadius: "10px",
            padding: "8px",
            marginBottom: "8px",
            backgroundColor:
              theme.palette.mode !== "dark"
                ? "rgba(200, 200, 200, 0.87)"
                : "rgba(50, 50, 50, 0.87)",
            boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
            cursor: "pointer",
            "&:hover": {
              border: "1px solid #09b1db",
            },
            // transform: snapshot.isDragging ? "rotate(5deg)" : "rotate(0deg)", //`rotate(${rotation})`,
            // transition: "transform 0.2s ease",
            // zIndex: snapshot.isDragging ? 1 : "auto",
          }}
        >
          <Box onClick={handleOpen}>
            <Typography variant="body1">{card.content}</Typography>
            {/* <IconButton onClick={handleOpen}>
              <EditIcon />
            </IconButton> */}
            {card.description && <NotesIcon color={"info"} />}
            {card.attachments.length > 0 && <DescriptionIcon color={"info"} />}
            <IconButton color="error" onClick={handleDeleteCard}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="edit-card-title"
            aria-describedby="edit-card-description"
            // style={{ backdropFilter: "blur(10px)" }}
          >
            <Fade in={open} timeout={250}>
              <Grid container spacing={2} justifyContent="center">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                    maxWidth: 720,
                    width: "100%",
                    margin: "0 auto",
                    overflowY: "auto",
                  }}
                >
                  <Paper
                    elevation={6}
                    sx={{
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backdropFilter: "blur(10px)",
                      borderRadius: "20px",
                      maxHeight: "95vh",
                      width: "100%",
                      overflowY: "auto",
                    }}
                  >
                    {/* Modal */}
                    {/* {loading && <LoadingWindow onClose={closeModal} />} */}

                    {/* Close Button */}
                    <IconButton
                      aria-label="delete"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    {/* Container Grid */}
                    <Grid xs={12} container justifyContent="center">
                      {/* Title */}
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={t("Title")}
                          value={content}
                          color="info"
                          onChange={(e) => setContent(e.target.value)}
                          sx={{
                            mt: 3,
                          }}
                        />
                      </Grid>

                      {/* Description */}
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={t("Description")}
                          multiline
                          rows={4}
                          value={description}
                          color="info"
                          onChange={(e) => setDescription(e.target.value)}
                          margin="normal"
                        />
                      </Grid>

                      {/* Upload Attachments Button */}
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          onClick={() => setOpenDocumentsDialog(true)}
                          fullWidth
                          sx={{
                            mt: 1,
                            mb: 0,
                            backgroundColor: "#F6B411",
                            color: "#fff",
                            height: "50px",
                          }}
                        >
                          <AttachFileIcon />
                          <Typography
                            component="h1"
                            variant="r16"
                            paddingLeft="10px"
                          >
                            {t("Upload Attachments")}
                          </Typography>
                        </Button>
                      </Grid>

                      {/* File List */}
                      <Grid item xs={12} container justifyContent="center">
                        {/* Old File List */}
                        <Grid item xs={6}>
                          <List
                            sx={{
                              width: "100%",
                              // maxWidth: 360,
                              bgcolor: "background.paper",
                              position: "relative",
                              overflow: "auto",
                              maxHeight: 100,
                              "& ul": { padding: 0 },
                            }}
                            subheader={<li />}
                          >
                            {card.attachments.map((doc, index) => (
                              <ListItem
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                key={index}
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleMarkFiles(doc)}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                }
                              >
                                <ListItemIcon>
                                  <FileIcon />
                                </ListItemIcon>
                                <ListItemText primary={getFileName(doc)} />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>

                        {/* New File List */}
                        <Grid item xs={6}>
                          <List
                            sx={{
                              width: "100%",
                              // maxWidth: 360,
                              bgcolor: "background.paper",
                              position: "relative",
                              overflow: "auto",
                              maxHeight: 100,
                              "& ul": { padding: 0 },
                            }}
                            subheader={<li />}
                          >
                            {newFiles.map((doc, index) => (
                              <ListItem
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                key={index}
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleNewDeleteFile(doc)}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                }
                              >
                                <ListItemIcon>
                                  <FileIcon />
                                </ListItemIcon>
                                <ListItemText primary={getFileName(doc.name)} />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        {/* Submit Button */}
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={handleSave}
                            fullWidth
                            sx={{
                              mt: 1,
                              backgroundColor: "#28a745",
                              color: "#fff",
                              height: "50px",
                            }}
                          >
                            <Typography component="h1" variant="r16">
                              {t("Save")}
                            </Typography>
                          </Button>
                        </Grid>

                        {/* Cancel Button */}
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={handleClose}
                            fullWidth
                            sx={{
                              mt: 1,
                              backgroundColor: "#A60A05", //D10A03
                              color: "#fff",
                              height: "50px",
                            }}
                          >
                            <Typography component="h1" variant="r16">
                              {t("Cancel")}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>

                      <Dialog
                        open={openDocumentsDialog}
                        onClose={() => setOpenDocumentsDialog(false)}
                      >
                        <DialogTitle>{t("Upload Documents")}</DialogTitle>
                        <DialogContent>
                          <input
                            type="file"
                            multiple
                            onChange={handleAddAttachment}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => setOpenDocumentsDialog(false)}
                            color="secondary"
                          >
                            {t("Close")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Paper>
                </Box>
              </Grid>
            </Fade>
          </Modal>
        </Box>
      )}
    </Draggable>
  );
};

export default Card;
