import React from "react";
import ReactDOM from "react-dom";
import { Box } from "@mui/material";

const HoverImage = ({ src, isVisible, position }) => {
  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <Box
      component="img"
      src={src}
      sx={{
        position: "fixed",
        top: position.top,
        left: position.left,
        maxWidth: 400,
        maxHeight: 400,
        zIndex: 9999,
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        objectFit: "contain",
      }}
    />,
    document.body
  );
};

export default HoverImage;
