/* eslint-disable react-hooks/exhaustive-deps */
// src/screens/KeyElements.jsx

import React, { useEffect, useState } from "react";
// import { useAuth } from "../components/AuthContext";
import {
  Box,
  useTheme,
  Typography,
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  styled,
  stepConnectorClasses,
  StepConnector,
  useMediaQuery,
} from "@mui/material";
import { firestore } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { CustomMessage } from "../components/Common";
import { useData } from "../components/DataProvider";
import AddBrandAndModel from "./AddBrandAndModel";
import AddBrandAndOrigin from "./AddBrandAndOrigin";
import AddCategories from "./AddCategories";
import AddDocuments from "./AddDocuments";
import PropTypes from "prop-types";
import SettingsIcon from "@mui/icons-material/Settings";
import CategoryIcon from "@mui/icons-material/Category";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import "../App.css";

const KeyElements = () => {
  //   const { currentUser } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { categories, docCategories } = useData();
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(0);
  const [openMessage, setOpenMessage] = useState(false);
  const [isBrandsAndModelsOpen, setIsBrandsAndModelsOpen] = useState(false);
  const [isBrandsAndOriginsOpen, setIsBrandsAndOriginsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [isDocumentsOpen, setIsDocumentsOpen] = useState(false);

  async function checkRefDocs() {
    const querySnapshot = await getDocs(collection(firestore, "refDocs"));

    if (!querySnapshot.empty) {
      return 1;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return null;
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (categories.length > 0) {
      if (docCategories.length > 0) {
        if (checkRefDocs()) {
          setStep(2);
        } else {
          setStep(1);
        }
      } else {
        setStep(0);
      }
    } else {
      setStep(0);
    }
  }, []);

  const closeModal = () => {
    setIsBrandsAndModelsOpen(false);
    setIsBrandsAndOriginsOpen(false);
    setIsCategoriesOpen(false);
    setIsDocumentsOpen(false);
  };

  const handleEditBrandsAndModels = async () => {
    setIsBrandsAndModelsOpen(true);
  };

  const handleEditBrandsAndOrigins = async () => {
    setIsBrandsAndOriginsOpen(true);
  };

  const handleEditCategories = async () => {
    setIsCategoriesOpen(true);
  };

  const handleEditDocuments = async () => {
    setIsDocumentsOpen(true);
  };

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  // MediaQuery Hooks
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const isSM = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMD = useMediaQuery(theme.breakpoints.between("md", "lg"));

  // Stepper Connector
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: isXS ? 30 : isSM ? 46 : isMD ? 61 : 61,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: isXS ? 4 : isSM ? 4 : isMD ? 6 : 6,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  // Stepper Root
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: isXS ? 64 : isSM ? 96 : isMD ? 128 : 128,
    height: isXS ? 64 : isSM ? 96 : isMD ? 128 : 128,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  }));

  //  Stepper Func
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: (
        <SettingsIcon
          style={{ fontSize: isXS ? 48 : isSM ? 64 : isMD ? 96 : 96 }}
        />
      ),
      2: (
        <CategoryIcon
          style={{ fontSize: isXS ? 48 : isSM ? 64 : isMD ? 96 : 96 }}
        />
      ),
      3: (
        <VideoLabelIcon
          style={{ fontSize: isXS ? 48 : isSM ? 64 : isMD ? 96 : 96 }}
        />
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  //  Stepper Config
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const steps = [
    t("Create some brand and model"),
    t("Create some categories"),
    t("Add some reference documents to specific brand/model"),
  ];

  //   if (currentUser.auth === "Admin" || currentUser.auth === "User") {
  return (
    <Box m="20px" sx={{ height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "95vh",
          width: "100%",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={2}>
            {/* Feedback Mechanism */}
            <CustomMessage
              open={openMessage}
              onClose={handleMessageClose}
              message={message}
            />

            <Grid item xs={2}>
              <Box mb="30px">
                <Typography
                  variant="h2"
                  color={colors.grey[200]}
                  fontWeight="bold"
                  sx={{
                    fontSize: {
                      xs: "1.5rem", // font size for extra-small screens
                      sm: "2rem", // font size for small screens
                      md: "2.5rem", // font size for medium screens
                      lg: "3rem", // font size for large screens
                      xl: "3rem", // font size for extra-large screens
                    },
                    whiteSpace: "nowrap", // Prevent text wrapping
                    display: {
                      userSelect: "none",
                    },
                  }}
                >
                  {t("Key Elements")}
                </Typography>
                <Typography
                  variant="r16"
                  color={colors.greenAccent[400]}
                  sx={{
                    fontSize: {
                      xs: "0.8rem", // font size for extra-small screens
                      sm: "1.2rem", // font size for small screens
                      md: "1.2rem", // font size for medium screens
                      lg: "1.5rem", // font size for large screens
                      xl: "1.5rem", // font size for extra-large screens
                    },
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflow if any
                    textOverflow: "ellipsis", // Add ellipsis if text overflows
                    display: {
                      userSelect: "none",
                    },
                  }}
                >
                  {t("You should add your key elements")}
                </Typography>
              </Box>
            </Grid>

            {/* Edit Brands & Models Modal */}
            {isBrandsAndModelsOpen && (
              <AddBrandAndModel
                onClose={closeModal}
                onFeedback={handleFeedback}
              />
            )}

            {/* Edit Part Brands & Origins Modal */}
            {isBrandsAndOriginsOpen && (
              <AddBrandAndOrigin
                onClose={closeModal}
                onFeedback={handleFeedback}
              />
            )}

            {/* Edit Categories Modal */}
            {isCategoriesOpen && (
              <AddCategories onClose={closeModal} onFeedback={handleFeedback} />
            )}

            {/* Edit Documents Modal */}
            {isDocumentsOpen && (
              <AddDocuments onClose={closeModal} onFeedback={handleFeedback} />
            )}

            {/* Horizontal Space */}
            <Box flexGrow={1} />
            {/* Brands & Models Button */}
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={handleEditBrandsAndModels}
                fullWidth
                sx={{
                  mt: {
                    xs: 0,
                    sm: 0,
                    md: 1,
                    lg: 1,
                    xl: 1,
                  },
                  backgroundColor: colors.greenAccent[600],
                  color: colors.grey[100],
                  height: "50px",
                  "&:hover": {
                    backgroundColor: colors.greenAccent[800],
                  },
                }}
              >
                <Typography
                  component="h1"
                  variant="r16"
                  sx={{
                    fontSize: {
                      xs: "0.6rem", // font size for extra-small screens
                      sm: "0.8rem", // font size for small screens
                      md: "0.9rem", // font size for medium screens
                      lg: "1.2rem", // font size for large screens
                      xl: "1.4rem", // font size for extra-large screens
                    },
                  }}
                >
                  {t("Brands & Models")}
                </Typography>
              </Button>
            </Grid>

            {/* Part Brands & Origins Button */}
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={handleEditBrandsAndOrigins}
                fullWidth
                sx={{
                  mt: {
                    xs: 0,
                    sm: 0,
                    md: 1,
                    lg: 1,
                    xl: 1,
                  },
                  backgroundColor: colors.greenAccent[600],
                  color: colors.grey[100],
                  height: "50px",
                  "&:hover": {
                    backgroundColor: colors.greenAccent[800],
                  },
                }}
              >
                <Typography
                  component="h1"
                  variant="r16"
                  sx={{
                    fontSize: {
                      xs: "0.6rem", // font size for extra-small screens
                      sm: "0.8rem", // font size for small screens
                      md: "0.9rem", // font size for medium screens
                      lg: "1.2rem", // font size for large screens
                      xl: "1.4rem", // font size for extra-large screens
                    },
                  }}
                >
                  {t("Part Brands & Origins")}
                </Typography>
              </Button>
            </Grid>

            {/* Categories Button */}
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={handleEditCategories}
                fullWidth
                sx={{
                  mt: {
                    xs: 0,
                    sm: 0,
                    md: 1,
                    lg: 1,
                    xl: 1,
                  },
                  backgroundColor: colors.blueAccent[500],
                  color: colors.grey[100],
                  height: "50px",
                  "&:hover": {
                    backgroundColor: colors.blueAccent[800],
                  },
                }}
              >
                <Typography
                  component="h1"
                  variant="r16"
                  sx={{
                    fontSize: {
                      xs: "0.5rem", // font size for extra-small screens
                      sm: "0.7rem", // font size for small screens
                      md: "0.9rem", // font size for medium screens
                      lg: "1.2rem", // font size for large screens
                      xl: "1.4rem", // font size for extra-large screens
                    },
                  }}
                >
                  {t("Edit Categories")}
                </Typography>
              </Button>
            </Grid>

            {/* Documents Button */}
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={handleEditDocuments}
                fullWidth
                sx={{
                  mt: {
                    xs: 0,
                    sm: 0,
                    md: 1,
                    lg: 1,
                    xl: 1,
                  },
                  backgroundColor: colors.redAccent[500],
                  color: colors.grey[100],
                  height: "50px",
                  "&:hover": {
                    backgroundColor: colors.redAccent[800],
                  },
                }}
              >
                <Typography
                  component="h1"
                  variant="r16"
                  sx={{
                    fontSize: {
                      xs: "0.5rem", // font size for extra-small screens
                      sm: "0.7rem", // font size for small screens
                      md: "0.9rem", // font size for medium screens
                      lg: "1.2rem", // font size for large screens
                      xl: "1.4rem", // font size for extra-large screens
                    },
                  }}
                >
                  {t("Edit Documents")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            {step === 2 && (
              <Typography
                variant="h1"
                color="secondary"
                noWrap
                mb={2}
                sx={{
                  display: {
                    userSelect: "none",
                  },
                }}
              >
                {t("Congratulations! You complete the key elements setup.")}
              </Typography>
            )}
            <Stepper
              alternativeLabel
              activeStep={step}
              connector={<ColorlibConnector />}
              sx={{
                width: "100%", // Adjust the width of the stepper container
                maxWidth: {
                  xs: 400,
                  sm: 800,
                  md: 1100,
                  lg: 1600,
                  xl: 1900,
                }, // Set a maximum width if needed
                "& .MuiStep-root": {
                  minHeight: "60px", // Adjust the height of each step
                },
                "& .MuiStepIcon-root": {
                  fontSize: "3rem", // Adjust the size of the step icons
                },
                "& .MuiStepLabel-root": {
                  fontSize: "1.2rem", // Adjust the font size of step labels
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    sx={{ fontSize: "1.5rem" }}
                  >
                    <Typography
                      variant="r16"
                      sx={{
                        display: {
                          userSelect: "none",
                        },
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  // isXS ? 64 : isSM ? 96 : isMD ? 128 : 128,
  //   } else {
  //     return (
  //       <Box m="20px">
  //         {/* HEADER */}
  //         <Box
  //           display="flex"
  //           justifyContent="space-between"
  //           alignItems="center"
  //           paddingRight="25px"
  //         >
  //           {/* <Header
  //             title={t("403")}
  //             subtitle={t("You don't have permission to see this page")}
  //           /> */}
  //         </Box>
  //       </Box>
  //     );
  //   }
};

export default KeyElements;
