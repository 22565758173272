// src/components/NavBar.jsx

import * as React from "react";
// Styles
// MUI Icons
// import LanguageIcon from "@mui/icons-material/Language";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import KeyIcon from "@mui/icons-material/Key";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
// Logo
import spada from "../assets/spada_icon.png";
// Dependencies
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { auth } from "../firebaseConfig";
import { useTranslation } from "react-i18next";
import { ColorModeContext, tokens } from "../theme";
import {
  useTheme,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  // Badge,
  Menu,
  MenuItem,
  Avatar,
  Button,
  ListItemText,
  Divider,
  Tooltip,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import "../App.css";
import ProvideFeedback from "../screens/ProvideFeedback";
import { CustomMessage } from "./Common";
import FlagIcon from "./FlagIcon";

export default function NavBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorNotEl, setAnchorNotEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [buttonMenuAnchorEl, setButtonMenuAnchorEl] = React.useState(null);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isNotifyMenuOpen = Boolean(anchorNotEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanguageMenuOpen = Boolean(languageMenuAnchorEl);
  const [isFeedbackOpen, setIsFeedbackOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [openMessage, setOpenMessage] = React.useState(false);
  const [currentLang, setCurrentLang] = React.useState("tr");

  // const [showNotifications, setShowNotifications] = React.useState(true);
  // const [open, setOpen] = React.useState(false);
  const { currentUser, signOut } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  // const isSM = useMediaQuery(theme.breakpoints.between("sm", "md"));
  // const bigger400 = useMediaQuery(theme.breakpoints.up(400));
  // const bigger500 = useMediaQuery(theme.breakpoints.up(500));
  const bigger600 = useMediaQuery(theme.breakpoints.up(600));
  const bigger650 = useMediaQuery(theme.breakpoints.up(650));
  const bigger700 = useMediaQuery(theme.breakpoints.up(700));
  const bigger750 = useMediaQuery(theme.breakpoints.up(750));
  const bigger800 = useMediaQuery(theme.breakpoints.up(800));
  const bigger900 = useMediaQuery(theme.breakpoints.up(900));
  const bigger1000 = useMediaQuery(theme.breakpoints.up(1000));
  const bigger1100 = useMediaQuery(theme.breakpoints.up(1100));
  const bigger1200 = useMediaQuery(theme.breakpoints.up(1200));
  const bigger1300 = useMediaQuery(theme.breakpoints.up(1300));
  // const isMD = useMediaQuery(theme.breakpoints.between("md", "lg"));
  // const isLG = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  // const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  const handleButtonClick = (path) => {
    handleMenuClose();
    navigate(path);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguageMenuAnchorEl(null);
    setCurrentLang(lang);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch {
      console.error("Failed to log out");
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleNotificationsMenuOpen = (event) => {
  //   setShowNotifications(false);
  //   setAnchorNotEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorNotEl(null);
    setButtonMenuAnchorEl(null);
    setLanguageMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleButtonMenuOpen = (event) => {
    setButtonMenuAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };

  const handleButtonMenuClose = () => {
    setButtonMenuAnchorEl(null);
  };

  const closeModal = () => {
    setIsFeedbackOpen(false);
  };

  const openProvideFeedbackWindow = () => {
    setIsFeedbackOpen(true);
  };

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => handleButtonClick("/profile")}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        {t("Profile")}
      </MenuItem>
      <Divider
        variant="fullwidth"
        sx={{
          borderColor:
            theme.palette.mode === "dark"
              ? "rgba(150, 150, 150, 1)"
              : "rgba(100, 100, 100, 1)",
        }}
      />
      {/* inset */}
      {(currentUser.type === "Admin" || currentUser.type === "GODLIKE") && (
        <MenuItem onClick={() => handleButtonClick("/keyelements")}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          {t("Key Elements")}
        </MenuItem>
      )}
      {(currentUser.type === "Admin" || currentUser.type === "GODLIKE") && (
        <Divider
          variant="fullwidth"
          sx={{
            borderColor:
              theme.palette.mode === "dark"
                ? "rgba(150, 150, 150, 1)"
                : "rgba(100, 100, 100, 1)",
          }}
        />
      )}
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        {t("Logout")}
      </MenuItem>
    </Menu>
  );

  const menuNotifyId = "primary-user-notifications-menu";
  const renderNotifications = (
    <Menu
      anchorEl={anchorNotEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuNotifyId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isNotifyMenuOpen}
      onClose={handleMenuClose}
    >
      {currentUser.notifications.map((notification, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <MenuItem key={index} onClick={handleMenuClose}>
          {/* Notifications */}
          <Avatar alt="B&B" sx={{ width: 32, height: 32 }}>
            <NotificationsIcon />
          </Avatar>
          <ListItemText
            sx={{ paddingLeft: "10px" }}
            primary={notification}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  // paddingLeft={"10px"}
                >
                  {currentUser.notif_desc[index]}
                </Typography>
              </React.Fragment>
            }
          />
          <Divider
            variant="inset"
            sx={{
              borderColor:
                theme.palette.mode === "dark"
                  ? "rgba(200, 200, 200, 0.87)"
                  : "rgba(50, 50, 50, 0.87)",
              borderWidth: "20px",
              opacity: 1,
            }}
          />
        </MenuItem>
      ))}
    </Menu>
  );

  const languageMenuId = "primary-language-menu";
  const renderLanguageMenu = (
    <Menu
      anchorEl={languageMenuAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      id={languageMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isLanguageMenuOpen}
      onClose={handleMenuClose}
    >
      {currentUser.purchasedLangPacks.includes("tr") && (
        <MenuItem onClick={() => changeLanguage("tr")}>
          <ListItemIcon sx={{ marginRight: 2 }}>
            <FlagIcon flag="trFlag" size={32} />
          </ListItemIcon>
          {t("Türkçe")}
        </MenuItem>
      )}

      {currentUser.purchasedLangPacks.includes("en") && (
        <MenuItem onClick={() => changeLanguage("en")}>
          <ListItemIcon sx={{ marginRight: 2 }}>
            <FlagIcon flag="usFlag" size={32} />
          </ListItemIcon>
          {t("English")}
        </MenuItem>
      )}

      {currentUser.purchasedLangPacks.includes("it") && (
        <MenuItem onClick={() => changeLanguage("it")}>
          <ListItemIcon sx={{ marginRight: 2 }}>
            <FlagIcon flag="itFlag" size={32} />
          </ListItemIcon>
          {t("Italiano")}
        </MenuItem>
      )}

      {currentUser.purchasedLangPacks.includes("jp") && (
        <MenuItem onClick={() => changeLanguage("jp")}>
          <ListItemIcon sx={{ marginRight: 2 }}>
            <FlagIcon flag="jpFlag" size={32} />
          </ListItemIcon>
          {t("日本語")}
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* Feedback Button */}
      <MenuItem onClick={openProvideFeedbackWindow}>
        <ListItemIcon>
          <AnnouncementIcon />
        </ListItemIcon>
        {t("Provide Feedback")}
      </MenuItem>

      {/* Language Button */}
      <MenuItem onClick={handleLanguageMenuOpen}>
        <ListItemIcon>
          {currentLang === "tr" ? (
            <FlagIcon flag="trFlag" size={24} />
          ) : currentLang === "en" ? (
            <FlagIcon flag="usFlag" size={24} />
          ) : currentLang === "it" ? (
            <FlagIcon flag="itFlag" size={24} />
          ) : (
            <FlagIcon flag="jpFlag" size={24} />
          )}
          {/* <LanguageIcon /> */}
        </ListItemIcon>
        {t("Change Language")}
      </MenuItem>

      {/* Theme Button */}
      <MenuItem onClick={colorMode.toggleColorMode}>
        <ListItemIcon>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </ListItemIcon>
        {t("Change Theme")}
      </MenuItem>

      {/* Profile Button */}
      <MenuItem onClick={() => handleButtonClick("/profile")}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        {t("Profile")}
      </MenuItem>

      {/* Key Elements Button */}
      {(currentUser.type === "Admin" || currentUser.type === "GODLIKE") && (
        <MenuItem onClick={() => handleButtonClick("/keyelements")}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          {t("Key Elements")}
        </MenuItem>
      )}

      {/* Logout Button */}
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        {t("Logout")}
      </MenuItem>
    </Menu>
  );

  return (
    <Box className="app-topbar" sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* Feedback Mechanism */}
          <CustomMessage
            open={openMessage}
            onClose={handleMessageClose}
            message={message}
          />

          {isFeedbackOpen && (
            <ProvideFeedback onClose={closeModal} onFeedback={handleFeedback} />
          )}

          {isXS && (
            <>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                onClick={() => handleButtonClick("/dashboard")}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={spada} alt="SPADA" />
                <Typography
                  variant="rbi32"
                  noWrap
                  component="div"
                  className="gradient-text-green"
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "block",
                      paddingLeft: "10px",
                      paddingBottom: "7px",
                      userSelect: "none",
                    },
                  }}
                >
                  SPADA
                </Typography>
              </Box>
            </>
          )}

          {bigger600 && !bigger1300 && (
            <Box
              onClick={() => handleButtonClick("/dashboard")}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={spada} alt="SPADA" />
            </Box>
          )}

          {bigger1300 && (
            <Box
              onClick={() => handleButtonClick("/dashboard")}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={spada} alt="SPADA" />
              <Typography
                variant="rbi32"
                noWrap
                component="div"
                className="gradient-text-green"
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    paddingLeft: "10px",
                    paddingBottom: "7px",
                    userSelect: "none",
                  },
                }}
              >
                SPADA
              </Typography>
            </Box>
          )}

          {!isXS && <Box sx={{ flexGrow: 1 }} />}

          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
              },
              gap: "10px",
            }}
          >
            {/* Stocks Button */}
            {currentUser.purchasedProducts.includes("appointmentsModule") && (
              <>
                {bigger600 && (
                  <Button
                    size="large"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/stocks")}
                  >
                    <Inventory2OutlinedIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Stocks")}
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {/* Purchases Button */}
            {currentUser.purchasedProducts.includes("purchasesModule") && (
              <>
                {bigger650 && (
                  <Button
                    size="large"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/purchases")}
                  >
                    <ReceiptOutlinedIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Purchases")}
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {/* Sales Button */}
            {currentUser.purchasedProducts.includes("salesModule") && (
              <>
                {bigger700 && (
                  <Button
                    size="large"
                    //   aria-label="show 4 new mails"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/sales")}
                  >
                    <AttachMoneyOutlinedIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Sales")}
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {/* Works Button */}
            {currentUser.purchasedProducts.includes("worksModule") && (
              <>
                {bigger750 && (
                  <Button
                    size="large"
                    //   aria-label="show 4 new mails"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/works")}
                  >
                    <BuildOutlinedIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Works")}
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {/* Clients Button */}
            {currentUser.purchasedProducts.includes("clientsModule") && (
              <>
                {bigger800 && (
                  <Button
                    size="large"
                    //   aria-label="show 4 new mails"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "block",
                        md: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/clients")}
                  >
                    <PersonOutlinedIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Clients")}
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {/* Motorcycles Button */}
            {currentUser.purchasedProducts.includes("motorcyclesModule") && (
              <>
                {bigger900 && (
                  <Button
                    size="large"
                    //   aria-label="show 4 new mails"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/motorcycles")}
                  >
                    <TwoWheelerIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Motorcycles")}
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {/* Suppliers Button */}
            {currentUser.purchasedProducts.includes("suppliersModule") && (
              <>
                {bigger1000 && (
                  <Button
                    size="large"
                    //   aria-label="show 4 new mails"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/suppliers")}
                  >
                    <GroupOutlinedIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Suppliers")}
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {/* Appointments Button */}
            {currentUser.purchasedProducts.includes("appointmentsModule") && (
              <>
                {bigger1100 && (
                  <Button
                    size="large"
                    //   aria-label="show 4 new mails"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/appointments")}
                  >
                    <ScheduleOutlinedIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Appointments")}
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {/* Project Management Button */}
            {currentUser.purchasedProducts.includes("pmModule") && (
              <>
                {bigger1200 && (
                  <Button
                    size="large"
                    //   aria-label="show 4 new mails"
                    color="inherit"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                      },
                      "&:hover": {
                        ...(theme.palette.mode === "dark"
                          ? {
                              color: colors.greenAccent[500],
                            }
                          : { color: colors.redAccent[600] }),
                      },
                    }}
                    onClick={() => handleButtonClick("/projectmanagement")}
                  >
                    <AccountTreeOutlinedIcon />
                    <Typography
                      className="poppins-medium"
                      noWrap
                      component="div"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                      {t("Projects")}
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {!bigger1200 && (
            <Box>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleButtonMenuOpen}
              >
                <MenuIcon />
              </IconButton>

              {/* Mini Menu */}
              <Menu
                anchorEl={buttonMenuAnchorEl}
                open={Boolean(buttonMenuAnchorEl)}
                onClose={handleButtonMenuClose}
              >
                {/* Stocks */}
                {currentUser.purchasedProducts.includes("stocksModule") && (
                  <Box>
                    {!bigger600 && (
                      <MenuItem onClick={() => handleButtonClick("/stocks")}>
                        <IconButton size="large" color="inherit">
                          <Inventory2OutlinedIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Stocks")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {/* Purchases */}
                {currentUser.purchasedProducts.includes("purchasesModule") && (
                  <Box>
                    {!bigger650 && (
                      <MenuItem onClick={() => handleButtonClick("/purchases")}>
                        <IconButton size="large" color="inherit">
                          <ReceiptOutlinedIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Purchases")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {/* Sales */}
                {currentUser.purchasedProducts.includes("salesModule") && (
                  <Box>
                    {!bigger700 && (
                      <MenuItem onClick={() => handleButtonClick("/sales")}>
                        <IconButton size="large" color="inherit">
                          <AttachMoneyOutlinedIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Sales")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {/* Works */}
                {currentUser.purchasedProducts.includes("worksModule") && (
                  <Box>
                    {!bigger750 && (
                      <MenuItem onClick={() => handleButtonClick("/works")}>
                        <IconButton size="large" color="inherit">
                          <BuildOutlinedIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Works")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {/* Clients */}
                {currentUser.purchasedProducts.includes("clientsModule") && (
                  <Box>
                    {!bigger800 && (
                      <MenuItem onClick={() => handleButtonClick("/clients")}>
                        <IconButton size="large" color="inherit">
                          <PersonOutlinedIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Clients")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {/* Motorcycles */}
                {currentUser.purchasedProducts.includes(
                  "motorcyclesModule"
                ) && (
                  <Box>
                    {!bigger900 && (
                      <MenuItem
                        onClick={() => handleButtonClick("/motorcycles")}
                      >
                        <IconButton size="large" color="inherit">
                          <TwoWheelerIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Motorcycles")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {/* Suppliers */}
                {currentUser.purchasedProducts.includes("suppliersModule") && (
                  <Box>
                    {!bigger1000 && (
                      <MenuItem onClick={() => handleButtonClick("/suppliers")}>
                        <IconButton size="large" color="inherit">
                          <GroupOutlinedIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Suppliers")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {/* Appointments */}
                {currentUser.purchasedProducts.includes(
                  "appointmentsModule"
                ) && (
                  <Box>
                    {!bigger1100 && (
                      <MenuItem
                        onClick={() => handleButtonClick("/appointments")}
                      >
                        <IconButton size="large" color="inherit">
                          <ScheduleOutlinedIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Appointments")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {/* Project Management */}
                {currentUser.purchasedProducts.includes("pmModule") && (
                  <Box>
                    {!bigger1200 && (
                      <MenuItem
                        onClick={() => handleButtonClick("/projectmanagement")}
                      >
                        <IconButton size="large" color="inherit">
                          <AccountTreeOutlinedIcon />
                        </IconButton>
                        <Typography className="poppins-medium" noWrap>
                          {t("Projects")}
                        </Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}
              </Menu>
            </Box>
          )}

          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "flex",
              },
            }}
          >
            <Tooltip title={t("Provide Feedback")}>
              <IconButton
                size="large"
                color="inherit"
                onClick={openProvideFeedbackWindow}
              >
                <AnnouncementIcon />
              </IconButton>
            </Tooltip>

            {/* Language Button */}
            <Tooltip title={t("Change Language")}>
              <IconButton
                size="large"
                color="inherit"
                onClick={handleLanguageMenuOpen}
              >
                {currentLang === "tr" ? (
                  <FlagIcon flag="trFlag" size={24} />
                ) : currentLang === "en" ? (
                  <FlagIcon flag="usFlag" size={24} />
                ) : currentLang === "it" ? (
                  <FlagIcon flag="itFlag" size={24} />
                ) : (
                  <FlagIcon flag="jpFlag" size={24} />
                )}

                {/* <LanguageIcon /> */}
              </IconButton>
            </Tooltip>

            {/* Theme Button */}
            <Tooltip title={t("Change Theme")}>
              <IconButton
                size="large"
                color="inherit"
                onClick={colorMode.toggleColorMode}
              >
                {theme.palette.mode === "dark" ? (
                  <DarkModeOutlinedIcon />
                ) : (
                  <LightModeOutlinedIcon />
                )}
              </IconButton>
            </Tooltip>

            {/* Account Button */}
            <Tooltip title={t("Account Settings")}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar
                  alt="B&B"
                  src={currentUser.profilePicture}
                  sx={{
                    width: 24,
                    height: 24,
                    "& img": {
                      objectFit: "scale-down",
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: "none" } }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderNotifications}
      {renderLanguageMenu}
    </Box>
  );
}
