// src/components/Column.jsx
import React, { useState, useCallback } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Card from "./Card";
import {
  Box,
  Button,
  Typography,
  TextField,
  useTheme,
  ClickAwayListener,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid"; // For unique IDs
// import { tokens } from "../theme";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UnfoldLessDoubleIcon from "@mui/icons-material/UnfoldLessDouble";
import UnfoldMoreDoubleIcon from "@mui/icons-material/UnfoldMoreDouble";

const Column = React.memo(
  ({ column, index, addCard, deleteCard, deleteColumn, updateColumnTitle }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    // const colors = tokens(theme.palette.mode);
    const [newCardContent, setNewCardContent] = useState("");
    const [hideAdd, setHideAdd] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [newTitle, setNewTitle] = useState(column.title);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleCancelClicked = () => {
      setNewCardContent("");
      setHideAdd(true);
    };

    const handleAddClicked = () => {
      setHideAdd(false);
    };

    const handleDeleteClicked = () => {
      deleteColumn(column.id);
    };

    const handleTitleClick = () => {
      setIsEditing(true);
    };

    const handleTitleChange = (e) => {
      setNewTitle(e.target.value);
    };

    const handleTitleBlur = async () => {
      setIsEditing(false);
      if (newTitle.trim() && newTitle !== column.title) {
        await updateColumnTitle(column.id, newTitle);
      }
    };

    const handleTitleKeyDown = async (e) => {
      if (e.key === "Enter") {
        setIsEditing(false);
        if (newTitle.trim() && newTitle !== column.title) {
          await updateColumnTitle(column.id, newTitle);
        }
      }
    };

    const handleAddCard = useCallback(() => {
      if (newCardContent.trim() === "") return;

      const newCard = {
        id: uuidv4(),
        content: newCardContent,
        description: "",
        attachments: [],
      };

      addCard(column.id, newCard);
      setNewCardContent("");
    }, [newCardContent, column.id, addCard]);

    // function getStyle(style, snapshot) {
    //   if (!snapshot.isDropAnimating) {
    //     return style;
    //   }
    //   const { moveTo, curve, duration } = snapshot.dropAnimation;
    //   // move to the right spot
    //   const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
    //   // add a bit of turn for fun
    //   const rotate = "rotate(0.5turn)";

    //   // patching the existing style
    //   return {
    //     ...style,
    //     transform: `${translate} ${rotate}`,
    //     // slowing down the drop because we can
    //     transition: `all ${curve} ${duration + 1}s`,
    //   };
    // }

    const handleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };

    return (
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              border:
                theme.palette.mode !== "dark"
                  ? "rgba(225, 225, 225, 0.87)"
                  : "rgba(50, 50, 50, 0.87)",
              borderRadius: "10px",
              padding: "8px",
              width: "300px",
              margin: "0 8px",
              display: "flex",
              flexDirection: "column",
              backgroundColor:
                theme.palette.mode !== "dark"
                  ? "rgba(225, 225, 225, 0.87)"
                  : "rgba(50, 50, 50, 0.87)",
              // "&:hover": {
              //   border: "1px solid #09b1db",
              // },
            }}
          >
            <Box
              {...provided.dragHandleProps}
              sx={{ padding: 2, cursor: "grab" }}
            >
              {isEditing ? (
                <TextField
                  value={newTitle}
                  onChange={handleTitleChange}
                  onBlur={handleTitleBlur}
                  onKeyDown={handleTitleKeyDown}
                  color="info"
                  varint="outlined"
                  autoFocus
                  fullWidth
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    justifyContent: "space-between",
                    backdropFilter: "blur(10px)",
                    // borderRadius: "20px",
                    // maxHeight: "95vh",
                    width: "100%",
                    // overflowY: "auto",
                  }}
                >
                  <Typography
                    variant="r16"
                    onClick={handleTitleClick}
                    sx={
                      {
                        // display: "flex",
                        // justifyContent: "space-between",
                        // alignItems: "center",
                      }
                    }
                  >
                    {column.title}
                  </Typography>
                  {!isCollapsed ? (
                    <UnfoldLessDoubleIcon
                      onClick={handleCollapse}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        botton: 0,
                      }}
                    />
                  ) : (
                    <UnfoldMoreDoubleIcon
                      onClick={handleCollapse}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        botton: 0,
                      }}
                    />
                  )}
                </Box>
              )}
            </Box>

            <Droppable droppableId={column.id} type="CARD">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  // sx={{ padding: 2, flexGrow: 1, minHeight: 100 }}
                >
                  {Object.values(column.cards).map((card, index) => (
                    <Card
                      key={card.id}
                      card={card}
                      index={index}
                      deleteCard={deleteCard}
                      columnId={column.id}
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>

            <Box mt={1}>
              {!hideAdd && (
                <>
                  <ClickAwayListener onClickAway={handleCancelClicked}>
                    <TextField
                      value={newCardContent}
                      onChange={(e) => setNewCardContent(e.target.value)}
                      label={t("Add New Card")}
                      variant="outlined"
                      color="info"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddCard();
                        }
                      }}
                    />
                  </ClickAwayListener>
                  <Button
                    color="info"
                    variant="outlined"
                    onClick={handleAddCard}
                    sx={{
                      mt: 1,
                    }}
                  >
                    <AddIcon />
                    <Typography sx={{ ml: 2 }}>{t("Add")}</Typography>
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={handleCancelClicked}
                    sx={{
                      mt: 1,
                      ml: 1,
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </>
              )}
              {hideAdd && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                  }}
                >
                  {/* Add Button */}
                  <Button
                    color="info"
                    variant="outlined"
                    onClick={handleAddClicked}
                  >
                    <AddIcon />
                  </Button>

                  <Box sx={{ flexGrow: true }} />

                  {/* Delete Button */}
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={handleDeleteClicked}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Draggable>
    );
  }
);

export default Column;
