// src/screens/Purchases.jsx

import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme, Typography, Grid, Button } from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import CustomLinearProgress, {
  CustomToolbar,
  // BankChip,
  CustomMessage,
} from "../components/Common";
import AddPurchase from "./AddPurchase";
import EditPurchase from "./EditPurchase";
import ViewPurchaseDetails from "./ViewPurchaseDetails";
import { useData } from "../components/DataProvider";
import "../App.css";

const Purchases = () => {
  const { currentUser } = useAuth();
  const { purchases, loading } = useData();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [purchaseDetails, setPurchaseDetails] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const closeModal = () => {
    setIsAddOpen(false);
    setIsEditOpen(false);
    setIsDetailsOpen(false);
  };

  // Set selected IDs
  const handleSelectionChange = (newSelection) => {
    // console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  // Firestore Update
  const handleUpdate = async () => {
    if (selectedRowIds[0]) {
      // Get Parts Collection
      const purchaseRef = collection(firestore, "purchases");
      // Search exact ID within documents
      const q = query(purchaseRef, where("id", "==", selectedRowIds[0]));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const purchaseDoc = querySnapshot.docs[0].data();
      // Set ID to edit
      setPurchaseDetails(purchaseDoc);
      // Open Modal
      setIsEditOpen(true);
    }
  };

  const handleViewDetails = async () => {
    if (selectedRowIds[0]) {
      // Get Parts Collection
      const purchaseRef = collection(firestore, "purchases");
      // Search exact ID within documents
      const q = query(purchaseRef, where("id", "==", selectedRowIds[0]));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const purchaseDoc = querySnapshot.docs[0].data();
      // Set ID to edit
      setPurchaseDetails(purchaseDoc);
      // Open Modal
      setIsDetailsOpen(true);
    }
  };

  const handleCreateNew = async () => {
    setIsAddOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "seller",
      headerName: t("Supplier"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "date",
      headerName: t("Date of Purchase"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) =>
        format(params.row.date.toDate(), "dd/MM/yyyy HH:mm:ss"),
    },
    {
      field: "partsPurchased",
      headerName: t("Parts"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => {
        const parts = params.row.partsPurchased;
        const partNames = parts.map((part) => part.part.name).join(", ");
        return parts ? partNames : "N/A";
      },
    },
    {
      field: "total_price",
      headerName: t("Total Cost"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
  ];

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const datagridMaxHeight = 616;

  if (currentUser.purchasedProducts.includes("purchasesModule")) {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95vh",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {/* Feedback Mechanism */}
              <CustomMessage
                open={openMessage}
                onClose={handleMessageClose}
                message={message}
              />
              <Grid item xs={2}>
                <Box mb="30px">
                  <Typography
                    variant="h2"
                    color={colors.grey[200]}
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // font size for extra-small screens
                        sm: "2rem", // font size for small screens
                        md: "3rem", // font size for medium screens
                        lg: "3.5rem", // font size for large screens
                        xl: "3.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                    }}
                  >
                    {t("Purchases")}
                  </Typography>
                  <Typography
                    variant="r16"
                    color={colors.greenAccent[400]}
                    sx={{
                      fontSize: {
                        xs: "1rem", // font size for extra-small screens
                        sm: "1.2rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t("All Purchases")}
                  </Typography>
                </Box>
              </Grid>

              {/* Create Purchase Modal */}
              {isAddOpen && (
                <AddPurchase onClose={closeModal} onFeedback={handleFeedback} />
              )}

              {/* Edit Purchase Modal */}
              {isEditOpen && (
                <EditPurchase
                  onClose={closeModal}
                  purchaseDetails={purchaseDetails}
                  onFeedback={handleFeedback}
                />
              )}

              {/* View Details Modal */}
              {isDetailsOpen && (
                <ViewPurchaseDetails
                  onClose={closeModal}
                  purchaseDetails={purchaseDetails}
                />
              )}

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* View Details */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.purchases % 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleViewDetails}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.greenAccent[600],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.greenAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("View Details")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* Create New Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.purchases & 2) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleCreateNew}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.blueAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.blueAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Create New Purchase")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}

              {/* Edit Button */}
              {(currentUser.type === "Admin" ||
                currentUser.type === "GODLIKE" ||
                currentUser.authorization.purchases > 3) && (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      fullWidth
                      sx={{
                        mt: {
                          xs: 0,
                          sm: 0,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        },
                        backgroundColor: colors.redAccent[500],
                        color: colors.grey[100],
                        height: "50px",
                        "&:hover": {
                          backgroundColor: colors.redAccent[800],
                        },
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        sx={{
                          fontSize: {
                            xs: "0.7rem", // font size for extra-small screens
                            sm: "0.8rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Edit Purchase")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <Box
            m="0px 0px 0px 0px"
            display="flex"
            flex-direction="column"
            min-height="400px"
            width="100%"
            overflow={"auto"}
            sx={{
              height: {
                xs: `${datagridMaxHeight / 1.25}px`,
                sm: `${datagridMaxHeight / 1.25}px`,
                md: `${datagridMaxHeight}px`,
                lg: `${datagridMaxHeight}px`,
                xl: `${datagridMaxHeight * 1.5}px`,
              },
              width: "100%",
              "& .MuiDataGrid-root": {
                fontSize: {
                  xs: "0.7rem", // font size for extra-small screens
                  sm: "0.8rem", // font size for small screens
                  md: "0.9rem", // font size for medium screens
                  lg: "1.2rem", // font size for large screens
                  xl: "1.5rem", // font size for extra-large screens
                },
                ...(theme.palette.mode === "dark"
                  ? {
                      border: "1px solid rgba(62, 67, 150, 1)",
                    }
                  : { border: "1px solid rgba(175, 63, 59, 1)" }),
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                fontFamily: "Poppins",
                fontSize: {
                  xs: "0.7rem", // font size for extra-small screens
                  sm: "0.8rem", // font size for small screens
                  md: "0.9rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                fontFamily: "Poppins",
                fontSize: {
                  xs: "0.5rem", // font size for extra-small screens
                  sm: "0.7rem", // font size for small screens
                  md: "0.8rem", // font size for medium screens
                  lg: "1rem", // font size for large screens
                  xl: "1.2rem", // font size for extra-large screens
                },
                ...(theme.palette.mode === "dark"
                  ? {
                      color: colors.greenAccent[500],
                    }
                  : { color: colors.redAccent[500] }),
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                ...(theme.palette.mode === "dark"
                  ? {
                      backgroundColor: colors.blueAccent[700],
                    }
                  : { backgroundColor: colors.redAccent[800] }),
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-cell:hover": {
                ...(theme.palette.mode === "dark"
                  ? {
                      color: colors.blueAccent[500],
                    }
                  : { color: colors.redAccent[500] }),
              },
            }}
          >
            <DataGrid
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              }}
              checkboxSelection={true}
              disableMultipleRowSelection
              rows={purchases}
              columns={columns}
              slots={{
                toolbar: CustomToolbar,
                loadingOverlay: CustomLinearProgress,
              }}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[10, 20]}
              disableSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                handleSelectionChange(newSelection)
              }
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
        </Box>
      </Box>
    );

    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "95vh",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {/* Feedback Mechanism */}
              <CustomMessage
                open={openMessage}
                onClose={handleMessageClose}
                message={message}
              />
              <Grid item xs={2}>
                <Box mb="30px">
                  <Typography
                    variant="h2"
                    color={colors.redAccent[500]}
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // font size for extra-small screens
                        sm: "2rem", // font size for small screens
                        md: "3rem", // font size for medium screens
                        lg: "3.5rem", // font size for large screens
                        xl: "3.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("ACCESS DENIED!")}
                  </Typography>
                  <Typography
                    variant="r16"
                    color={colors.grey[300]}
                    sx={{
                      fontSize: {
                        xs: "0.9rem", // font size for extra-small screens
                        sm: "1.1rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      display: {
                        userSelect: "none",
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t(
                      "Unauthorized login attempt! You may purchase this module."
                    )}
                  </Typography>
                </Box>
              </Grid>

              {/* Horizontal Space */}
              <Box flexGrow={1} />

              {/* Buy Button */}
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  // onClick={handleRedirection("purchases")}
                  fullWidth
                  sx={{
                    mt: {
                      xs: 0,
                      sm: 0,
                      md: 1,
                      lg: 1,
                      xl: 1,
                    },
                    backgroundColor: colors.greenAccent[600],
                    color: colors.grey[100],
                    height: "50px",
                    "&:hover": {
                      backgroundColor: colors.greenAccent[800],
                    },
                  }}
                >
                  <Typography
                    component="h1"
                    variant="r16"
                    sx={{
                      fontSize: {
                        xs: "0.7rem", // font size for extra-small screens
                        sm: "0.8rem", // font size for small screens
                        md: "0.9rem", // font size for medium screens
                        lg: "1.2rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                    }}
                  >
                    {t("Purchase This Module")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Purchases;
