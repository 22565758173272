// src/screens/ViewWorkDetails.jsx

import React, { useEffect, useState, useCallback } from "react";
// import { useAuth } from "../components/AuthContext";
import { storage } from "../firebaseConfig";
import { getDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import {
  Avatar,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemText,
} from "@mui/material";
import { format } from "date-fns";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, { CustomToolbar } from "../components/Common";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import HoverImage from "../components/HoverImage";
import "../App.css";

const ViewWorkDetails = ({ onClose, workDetails }) => {
  //   const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const workDate = workDetails.startDate ? format(workDetails.startDate.toDate(), "dd/MM/yyyy") : "";
  const [partsData, setPartsData] = useState([]);
  const [motoData, setMotoData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  const handleFileOpenClick = (url) => {
    window.open(url, getFileName(url), "noopener,noreferrer");
  };

  const handleCancel = async () => {
    handleClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const clientRef = workDetails.client;
      const motoRef = workDetails.motorcycle;

      let clientLData = {};
      let motoLData = {};
      let partsUsedData = [];

      try {
        setLoading(true);
        // Client Document
        const clientDoc = await getDoc(clientRef);
        if (clientDoc.exists()) {
          clientLData = clientDoc.data();
          //   console.log(clientLData);
        } else {
          console.error(`Client document ${clientRef.id} does not exist`);
          return null;
        }

        // Motorcycle Document
        const motorcycleDoc = await getDoc(motoRef);
        if (motorcycleDoc.exists()) {
          motoLData = motorcycleDoc.data();
          //   console.log(motoLData);
        } else {
          console.error(`Motorcycle document ${motoRef.id} does not exist`);
          return null;
        }

        const partsUsedEntries = Object.entries(workDetails.parts_used);
        // console.log("partsUsedEntries: ", partsUsedEntries);

        const partsUsedPromises = partsUsedEntries.map(async ([key, part]) => {
          try {
            // console.log("Fetching part: ", part[0]);
            // Index 0: Document Reference
            // Index 1: Quantity
            // Index 2: Sale Price

            const partDoc = await getDoc(part[0]);
            // console.log("Part Data: ", partDoc.data(), key);
            if (partDoc.exists()) {
              return {
                id: key, // Unique identifier for DataGrid rows
                part: partDoc.data(),
                quantity: part[1],
                salePrice: part[2],
              };
              // biome-ignore lint/style/noUselessElse: <explanation>
            } else {
              console.error(`Part document ${part[0].id} does not exist`);
              return null;
            }
          } catch (error) {
            console.error(`Error fetching part document ${part[0].id}:`, error);
            return null;
          }
        });

        partsUsedData = await Promise.all(partsUsedPromises);
        // console.log(partsUsedData);
      } catch (error) {
        console.error(
          "Error fetching client, motorcycle, or parts data:",
          error
        );
        return null;
      } finally {
        setLoading(false);
      }

      const transformedPartsUsedData = partsUsedData.map((item) => ({
        id: item.id,
        photo: item.part.photo,
        brand: item.part.brand,
        origin: item.part.origin,
        barcode: item.part.barcode,
        name: item.part.name,
        category: item.part.category,
        purchase_price: item.part.purchase_price,
        sale_price: item.part.sale_price,
        quantity: item.quantity,
        salePrice: item.salePrice,
        totalPrice: item.salePrice * item.quantity,
      }));

      setClientData(clientLData);
      setMotoData(motoLData);
      setPartsData(transformedPartsUsedData);
    };
    fetchData();
  }, [workDetails]);

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const clientColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: t("Full Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "birthday",
      headerName: t("Date of Birth"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => {
        const birthday = format(params.row.birthdate.toDate(), "dd/MM/yyyy");
        return birthday ? birthday : "N/A";
      },
    },
    {
      field: "driving_licence",
      headerName: t("Driving Licence"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "phone",
      headerName: t("Phone Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "address",
      headerName: t("Address"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  const motoColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "model",
      headerName: t("Model"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "year",
      headerName: t("Year"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "odometer",
      headerName: t("Current KM"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "license_plate",
      headerName: t("License Plate"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "chasis_no",
      headerName: t("Chasis Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "motor_no",
      headerName: t("Motor Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  const partsColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "origin",
      headerName: t("Origin"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "barcode",
      headerName: t("Barcode"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.barcode : "N/A";
      //   },
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.name : "N/A";
      //   },
    },
    {
      field: "category",
      headerName: t("Category"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.category : "N/A";
      //   },
    },
    // {
    //   field: "purchase_price",
    //   headerName: t("Purchase Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    // {
    //   field: "sale_price",
    //   headerName: t("Sale Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    {
      field: "quantity",
      headerName: t("Quantity"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "salePrice",
      headerName: t("Unit Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "totalPrice",
      headerName: t("Total Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
  ];

  const datagridMaxHeight = 247;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 720,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* Container */}
                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("View Details")}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Photo Section */}
                  {/* <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {renderPhoto()}
                    </Box>
                  </Grid> */}

                  {/* Date Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Date")}
                      name="date"
                      color="secondary"
                      value={workDate}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Amount Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Amount")}
                      name="amount"
                      color="secondary"
                      value={workDetails.amount}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Work Details Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Work Details")}
                      name="work_details"
                      color="secondary"
                      multiline
                      value={workDetails.work_details}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Notes Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Notes")}
                      name="notes"
                      color="secondary"
                      multiline
                      value={workDetails.notes}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Data Grid for Client Details */}
                  <Grid item xs={12}>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        rows={clientData.id ? [clientData] : []}
                        columns={clientColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        pageSize={5}
                        autoHeight
                        autoWidth
                        disableSelectionOnClick
                        //   getRowHeight={calculateRowHeight}
                        //   onRowSelectionModelChange={(newSelection) =>
                        //     handleSelectionChange(newSelection)
                        //   }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  {/* Data Grid for Motorcycle Details */}
                  <Grid item xs={12}>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        rows={motoData.id ? [motoData] : []}
                        columns={motoColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        pageSize={5}
                        autoHeight
                        autoWidth
                        disableSelectionOnClick
                        //   getRowHeight={calculateRowHeight}
                        //   onRowSelectionModelChange={(newSelection) =>
                        //     handleSelectionChange(newSelection)
                        //   }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  {/* Data Grid for Parts Used Details */}
                  <Grid item xs={12}>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        height: `${datagridMaxHeight * 1.65}px`,
                        width: "100%",
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        rows={partsData} //{transformedData.id ? transformedData : []} // {transformedData.filter((data) => data !== null)}
                        columns={partsColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        //   getRowHeight={calculateRowHeight}
                        //   onRowSelectionModelChange={(newSelection) =>
                        //     handleSelectionChange(newSelection)
                        //   }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  {/* File List */}
                  <Grid item xs={12}>
                    <List>
                      {workDetails.documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleFileOpenClick(doc)}
                            >
                              <FileIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={getFileName(doc)} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  <HoverImage
                    src={hoveredImage}
                    isVisible={!!hoveredImage}
                    position={hoverPosition}
                  />

                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel} // () => { console.log(partsData); }
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Close")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ViewWorkDetails;
