// src/screens/AddBrandAndModel.jsx

import React, { useState, useEffect } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  // useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  FormControlLabel,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
// import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import LoadingWindow from "../components/LoadingWindow";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import { SpadaSwitch } from "../components/Common";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const AddBrandAndModel = ({ onClose, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { brandsAndModels } = useData();
  const [showBrandsAndModels, setShowBrandsAndModels] = useState();
  const [addBrandChecked, setAddBrandChecked] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [errors, setErrors] = useState({});
  const [openBrands, setOpenBrands] = useState({});
  const [brandData, setBrandData] = useState({
    brand: "",
    model: "",
  });

  // Get motorcycles brands from Database
  useEffect(() => {
    setBrands(Object.keys(brandsAndModels).sort());
    setShowBrandsAndModels(brandsAndModels);
  }, [brandsAndModels]);

  const handleBrandChange = (event, value) => {
    setSelectedBrand(value);
    setBrandData({ ...brandData, brand: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBrandData({ ...brandData, [name]: value });
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!brandData.brand) newErrors.brand = t("Brand is required");
    if (!brandData.model) newErrors.model = t("Model is required");
    return newErrors;
  };

  const handleClearData = () => {
    setBrandData({
      brand: "",
      model: "",
    });
    setErrors({});
    setBrands(Object.keys(brandsAndModels));
  };

  const updateDocument = async (brand, model) => {
    // console.log(brand, model);
    const docRef = doc(firestore, "motoSelect", "JPFgTWQGxhhqRbv526Wy");

    const newBrand = brands.find((b) => b.brand === brand);

    if (newBrand) {
      //   console.log("Mevcut marka");
      await updateDoc(docRef, {
        [brand]: [model],
      });
    } else {
      //   console.log("Yeni marka");
      await updateDoc(docRef, {
        [brand]: arrayUnion(model),
      });
    }
  };

  const handleBrandListClick = (brandName) => {
    setOpenBrands((prevOpenBrands) => ({
      ...prevOpenBrands,
      [brandName]: !prevOpenBrands[brandName],
    }));
  };

  const handleDeleteModel = (brandName, model) => {
    setShowBrandsAndModels((prevBrandsAndModels) => {
      const updatedModels = prevBrandsAndModels[brandName].filter(
        (m) => m !== model
      );
      return {
        ...prevBrandsAndModels,
        [brandName]: updatedModels,
      };
    });

    // Optional: Update Firestore document
    const docRef = doc(firestore, "motoSelect", "JPFgTWQGxhhqRbv526Wy"); // Replace with your collection and document names
    updateDoc(docRef, {
      [`${brandName}`]: showBrandsAndModels[brandName].filter(
        (m) => m !== model
      ),
    });
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        if (addBrandChecked) {
          await updateDocument(selectedBrand, brandData.model);
        } else {
          updateDocument(brandData.brand, brandData.model);
        }
        // Clear Data
        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Create Brand/Model"));
      } finally {
        setLoading(false);
      }
    } else {
      //   console.log(errors);
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  //   const handleUpdateClicked = async (cat, type) => {
  //     if (type === "part") {
  //       setPartCategory(cat);
  //     } else if (type === "doc") {
  //       setDocCategory(cat);
  //     }
  //     setConfirmVisible(true);
  //   };

  //   const handleCategoryUpdate = async (cat, type) => {
  //     if (type === "part") {
  //       console.log(categories);
  //       console.log(cat);
  //       console.log(type);
  //       console.log(categories.filter((category) => category !== cat));
  //       //   updatePartCategoriesDocument(
  //       //     categories.filter((category) => category !== cat),
  //       //     "Update"
  //       //   );
  //       setPartCategory("");
  //     } else if (type === "doc") {
  //       console.log(docCategories);
  //       console.log(cat);
  //       console.log(type);
  //       console.log(docCategories.filter((category) => category !== cat));
  //       //   updateDocCategoriesDocument(
  //       //     docCategories.filter((category) => category !== cat),
  //       //     "Update"
  //       //   );
  //       setDocCategory("");
  //     }
  //     setConfirmVisible(false);
  //   };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 720,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  {/* Motorcycle Brand - Model */}
                  <Grid item xs={6}>
                    <Box
                      flex-direction="column"
                      overflow={"auto"}
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                        minWidth: 200,
                        maxWidth: 300,
                        minHeight: 200,
                        maxHeight: 400,
                        bgcolor: "background.paper",
                        borderRadius: 5,
                      }}
                    >
                      <List
                        sx={{
                          width: "100%",
                          //   maxHeight: 400,
                          bgcolor: "background.paper",
                          //   borderRadius: 5,
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                          <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                            sx={{
                              fontSize: {
                                xs: "0.7rem", // font size for extra-small screens
                                sm: "0.8rem", // font size for small screens
                                md: "0.9rem", // font size for medium screens
                                lg: "1.2rem", // font size for large screens
                                xl: "1.5rem", // font size for extra-large screens
                              },
                            }}
                          >
                            {t("Brands & Models")}
                          </ListSubheader>
                        }
                      >
                        {Object.entries(brandsAndModels)
                          .sort()
                          .map(([brandName, models]) => (
                            <div key={brandName}>
                              <ListItemButton
                                onClick={() => handleBrandListClick(brandName)}
                              >
                                <ListItemIcon>
                                  <BookmarkBorderIcon />
                                </ListItemIcon>
                                <ListItemText primary={brandName} />
                                {openBrands[brandName] ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItemButton>
                              <Collapse
                                in={openBrands[brandName]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {models.map((model) => (
                                    <ListItemButton key={model} sx={{ pl: 4 }}>
                                      <ListItemIcon>
                                        <DeleteIcon
                                          onClick={() =>
                                            handleDeleteModel(brandName, model)
                                          }
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary={model} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </div>
                          ))}
                      </List>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    {/* Text */}
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant="r16"
                          marginBottom={1}
                          sx={{
                            fontSize: {
                              xs: "0.7rem", // font size for extra-small screens
                              sm: "0.8rem", // font size for small screens
                              md: "0.9rem", // font size for medium screens
                              lg: "1.2rem", // font size for large screens
                              xl: "1.5rem", // font size for extra-large screens
                            },
                          }}
                        >
                          {t("Create Brand/Model")}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Control Switch Field */}
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FormControlLabel
                          sx={{ marginLeft: 1, marginBottom: 1 }}
                          control={
                            <SpadaSwitch
                              foreColor={"#2170E2"}
                              iconColor={"#000000"}
                              onChange={() =>
                                setAddBrandChecked(!addBrandChecked)
                              }
                              checked={addBrandChecked}
                            />
                          }
                          label={
                            addBrandChecked
                              ? t("Create Model")
                              : t("Create Brand")
                          }
                        />
                      </Box>
                    </Grid>

                    {addBrandChecked && (
                      <Autocomplete
                        options={brands}
                        fullWidth
                        disablePortal
                        id="combobox-brand"
                        name="brand"
                        color="info"
                        sx={{ marginBottom: 1, marginTop: 1 }}
                        value={selectedBrand || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.value
                        }
                        onChange={(event, newValue) =>
                          handleBrandChange("brand", newValue ? newValue : "")
                        }
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Brand")}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {},
                                "&:hover fieldset": {
                                  borderColor: "white",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#21ade2",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                //   color: "purple",
                                "&.Mui-focused": {
                                  color: "#21ade2",
                                },
                              },
                              "& .MuiOutlinedInput-input": {
                                //   color: "purple",
                              },
                            }}
                          />
                        )}
                      />
                    )}

                    {/* Brand Field */}
                    {!addBrandChecked && (
                      <TextField
                        label={t("Brand")}
                        name="brand"
                        color="info"
                        required
                        value={brandData.brand}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!errors.brand}
                        helperText={errors.brand}
                        sx={{ marginBottom: 1, marginTop: 1 }}
                      />
                    )}

                    {/* Model Field */}
                    <TextField
                      label={t("Model")}
                      name="model"
                      color="info"
                      required
                      value={brandData.model}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.model}
                      helperText={errors.model}
                      sx={{ marginBottom: 1 }}
                    />

                    {/* Submit Button */}
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Create")}
                      </Typography>
                    </Button>

                    {/* Cancel Button */}
                    <Button
                      variant="contained"
                      onClick={handleClearData}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default AddBrandAndModel;
