// src/screens/Login.jsx

import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  CssBaseline,
  useTheme,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
// import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
// Logo
import spada from "../assets/spada_icon.png";
import bnb from "../assets/bnb_site_mini.png";
// import { Navigate } from "react-router-dom";
import { auth, firestore } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import loginBackground from "../assets/login.jpg";

const Login = () => {
  const theme = useTheme();
  //   const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  // const [success, setSuccess] = useState(false);
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors((prev) => ({ ...prev, email: "" }));
    setLoginError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors((prev) => ({ ...prev, password: "" }));
    setLoginError("");
  };

  // const handleSignUp = (e) => {
  //   navigate("/signup");
  // };

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: "", password: "" };
    // console.log(email, password);

    if (!email) {
      newErrors.email = t("Email is required");
      valid = false;
    } else if (
      !(
        email.includes("@") &&
        (email.includes(".com") ||
          email.includes(".org") ||
          email.includes(".net"))
      )
    ) {
      newErrors.email = t("email_error1");
      valid = false;
    } else {
      const atIndex = email.indexOf("@");
      if (email.endsWith(".com")) {
        const dotComIndex = email.indexOf(".com", atIndex);
        if (dotComIndex - atIndex <= 1) {
          newErrors.email = t("email_error2");
          valid = false;
        }
      } else if (email.endsWith(".org")) {
        const dotComIndex = email.indexOf(".org", atIndex);
        if (dotComIndex - atIndex <= 1) {
          newErrors.email = t("email_error2");
          valid = false;
        }
      } else if (email.endsWith(".net")) {
        const dotComIndex = email.indexOf(".net", atIndex);
        if (dotComIndex - atIndex <= 1) {
          newErrors.email = t("email_error2");
          valid = false;
        }
      } else {
        if (email.endsWith(".tr")) {
          const dotComIndex = email.indexOf(".com", atIndex);
          const dotTrIndex = email.indexOf(".tr", dotComIndex);

          if (dotTrIndex - dotComIndex <= 3) {
            newErrors.email = t("email_error3");
            valid = false;
          }
        } else {
          newErrors.email = t("email_error4");
          valid = false;
        }
      }
    }

    if (!password) {
      newErrors.password = t("Password is required");
      valid = false;
    } else if (password.length < 8) {
      newErrors.password = t("Password must be at least 8 characters long");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");

    if (validateForm()) {
      // This method uses firebase authentication logic
      try {
        // Authenticate user with Firebase Authentication
        // const userCredential =
        await signInWithEmailAndPassword(auth, email, password);
        // const user = userCredential.user;

        // console.log("User logged in: ", user);

        // Check user credentials in Firestore
        const usersRef = collection(firestore, "users");
        const q = query(usersRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setLoginError(t("User not found!"));
          return;
        }
        navigate("/dashboard");
        // setSuccess(true); // Set success flag to true
      } catch (e) {
        // console.error("Login error:", e);
        setLoginError(t("Failed to log in. Please check your credentials!"));
      }
    }
  };

  // if (success) {
  //   return <Navigate to="/dashboard" />;
  // }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage: `url(${loginBackground})`,
          // 'url("https://images.pexels.com/photos/163210/motorcycles-race-helmets-pilots-163210.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat", // Add this to prevent the background from repeating
          minHeight: "100vh", // Full viewport height
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              // backgroundImage: 'url("https://source.unsplash.com/random")',
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Paper
              elevation={6}
              sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backdropFilter: "blur(10px)",
                borderRadius: "20px",
              }}
            >
              <img src={spada} alt="SPADA" paddingRight="10px" />
              <Typography
                variant="rbi32"
                noWrap
                component="div"
                className="gradient-text-green"
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    // paddingLeft: "10px",
                    paddingBottom: "25px",
                    userSelect: "none",
                  },
                }}
              >
                SPADA
              </Typography>
              {loginError !== "" && (
                <Box
                  alignItems="center"
                  p={2}
                  sx={{
                    border: "2px solid red",
                    borderRadius: 2,
                    color: "red",
                  }}
                >
                  <Typography variant="r16">{loginError}</Typography>
                </Box>
              )}
              <Typography
                component="h1"
                variant="r16"
                paddingTop="25px"
                sx={{
                  display: {
                    userSelect: "none",
                  },
                }}
              >
                {t("Sign In")}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("Email Address")}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  variant="outlined"
                  color="secondary"
                  value={email}
                  onChange={handleEmailChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputLabelProps={{
                    shrink: !!email,
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  variant="outlined"
                  color="secondary"
                  value={password}
                  onChange={handlePasswordChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputLabelProps={{
                    shrink: !!password,
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  // color="primary"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#28a745",
                    color: "#fff",
                    height: "50px",
                  }}
                >
                  <Typography component="h1" variant="r16">
                    {t("Sign In")}
                  </Typography>
                </Button>

                {/* <Button
                fullWidth
                variant="contained"
                onClick={handleSignUp}
                sx={{
                  mt: 1,
                  mb: 2,
                  backgroundColor: "#CC6611",
                  color: "#fff",
                  height: "50px",
                }}
              >
                <Typography component="h1" variant="r16">
                  {t("Sign Up")}
                </Typography>
              </Button> */}
              </Box>
            </Paper>

            <Typography
              variant="rbi16"
              noWrap
              component="div"
              className="gradient-text-pink"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  // paddingLeft: "10px",
                  paddingBottom: "25px",
                  userSelect: "none",
                },
              }}
            >
              Powered by
            </Typography>
            <img src={bnb} alt="B&B Garage" paddingRight="10px" />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
