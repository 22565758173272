// src/screens/EditMoto.jsx

import React, { useEffect, useState, useCallback } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import {
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  deleteObject,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
// Images
import emptyPhoto from "../assets/no_image.png";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";

const EditMoto = ({ onClose, motoDetails, onFeedback }) => {
  //   const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { brandsAndModels } = useData();
  const [years, setYears] = useState([]);
  const [errors, setErrors] = useState({});
  const [photo, setPhoto] = useState(null);
  const [documents, setDocuments] = useState(motoDetails.documents);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(motoDetails.brand);
  const [motoData, setMotoData] = useState({
    brand: motoDetails.brand,
    chasis_no: motoDetails.chasis_no,
    description: motoDetails.description,
    documents: motoDetails.documents,
    id: motoDetails.id,
    license_plate: motoDetails.license_plate,
    model: motoDetails.model,
    motor_no: motoDetails.motor_no,
    odometer: motoDetails.odometer,
    photo: motoDetails.photo,
    year: motoDetails.year,
  });

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  //   const handleFileOpenClick = (url) => {
  //     window.open(url, getFileName(url), "noopener,noreferrer");
  //   };

  // Get years from JSON file
  useEffect(() => {
    fetch("/Years.json")
      .then((response) => response.json())
      .then((data) => {
        setYears(data.years);
      })
      .catch((error) => console.error("Error fetching years:", error));
  }, []);

  // Get motorcycles brands from Database
  useEffect(() => {
    setBrands(Object.keys(brandsAndModels).sort());
  }, [brandsAndModels]);

  // Get motorcycles models from Database according to brand
  useEffect(() => {
    if (selectedBrand && brandsAndModels) {
      setModels(brandsAndModels[selectedBrand].sort());
    }
  }, [selectedBrand, brandsAndModels]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMotoData({ ...motoData, [name]: value });
  };

  const handleModelChange = (name, newValue) => {
    setMotoData((prevmotoData) => ({
      ...prevmotoData,
      [name]: newValue,
    }));
  };

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(value)) {
      setMotoData({ ...motoData, [name]: value });
    }
  };

  const handleBrandChange = (event, value) => {
    setSelectedBrand(value);
    const brand = brands.find((b) => b.brand === value);
    setModels(brand ? brand.models : []);
  };

  const handlePhotoSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file);
    }
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setNewFiles(files);
  };

  const validateFields = () => {
    const newErrors = {};
    // if (!motoData.brand) newErrors.brand = t("Brand is required");
    if (!motoData.model) newErrors.model = t("Model is required");
    if (!motoData.year) newErrors.year = t("Year is required");
    if (!motoData.odometer) newErrors.odometer = t("Current KM is required");
    if (!motoData.license_plate) {
      if (!motoData.chasis_no) {
        if (!motoData.motor_no) {
          newErrors.license_plate = t(
            "You should provide at least one of those: License Plate, Chasis Number or Motor Number"
          );
        }
      }
    }
    return newErrors;
  };

  const handleClearData = () => {
    setMotoData({
      brand: "",
      chasis_no: "",
      description: "",
      documents: [],
      photo: "",
      id: "",
      license_plate: "",
      model: "",
      motor_no: "",
      odometer: "",
      year: "",
    });
    setPhoto(null);
    setSelectedBrand(null);
    setDocuments([]); // Needed for hold the names (old and new)
    setNewFiles([]); // Needed for new uploads (new files)
    setFiles([]); // Needed for mark and delete functions (already installed files)
    setErrors({});
  };

  // Mark files for delete operation
  const handleMarkFiles = (filePath) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      filePath: [...(prevFiles.filePath || []), filePath],
    }));
    // console.log(files);
    setDocuments(documents.filter((file) => file !== filePath));
  };

  // Delete new files from list
  const handleNewDeleteFile = (filePath) => {
    setNewFiles(newFiles.filter((file) => file !== filePath));
  };

  // Delete files
  const handleFileDelete = async () => {
    const docsRef = ref(storage, `motorcycles/${motoData.id}/doc`);
    try {
      // List all items (file paths) under the path
      const res = await listAll(docsRef);

      // Delete files that match
      await Promise.all(
        res.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);

          if (files.filePath.includes(downloadURL)) {
            await deleteObject(itemRef);
            console.log("Deleted:", downloadURL);
          } else {
            console.log("Not Deleted:", downloadURL);
          }
        })
      );

      console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Delete marked files
        await handleFileDelete();

        // Upload photo
        let photoUrl = motoData.photo;
        if (photo) {
          const photoRef = ref(
            storage,
            `motorcycles/${motoData.id}/pic/${photo.name}`
          );
          await uploadBytes(photoRef, photo);
          photoUrl = await getDownloadURL(photoRef);
        }

        // Upload documents
        const documentUrls = [];
        for (const file of newFiles) {
          const documentRef = ref(
            storage,
            `motorcycles/${motoData.id}/doc/${file.name}`
          );
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          documentUrls.push(url);
        }

        if (documents.length) documents.map((file) => documentUrls.push(file));

        // Set photo and documents URL in motoData
        const updatedmotoData = {
          ...motoData,
          photo: photoUrl,
          documents: documentUrls,
        };

        // console.log(updatedmotoData);

        // Create document reference
        const partsRef = collection(firestore, "motorcycles");
        // Search exact ID within documents
        const q = query(partsRef, where("id", "==", updatedmotoData.id));
        // Search the query
        const querySnapshot = await getDocs(q);
        // Find the exact document
        const motoDoc = querySnapshot.docs[0];
        // Create reference to this document
        const docRef = doc(firestore, "motorcycles", motoDoc.id);
        // Update the document
        await updateDoc(docRef, updatedmotoData);
        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Update Motorcycle"));
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  const imageSize = {
    width: 400, // Adjust the width as needed
    height: 400, // Adjust the height as needed
  };

  const renderPhoto = () => {
    if (photo) {
      return (
        <img
          src={URL.createObjectURL(photo)}
          alt="Moto"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down",
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (motoDetails.photo) {
      return (
        <img
          src={motoDetails.photo}
          alt="Moto"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down",
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <img
          src={emptyPhoto}
          alt="Empty"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down",
            paddingBottom: "10px",
          }}
        />
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Edit Moto")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {renderPhoto()}
                    </Box>
                  </Grid>
                  {/* Brand Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={brands}
                      fullWidth
                      disablePortal
                      id="combobox-brand"
                      name="brand"
                      color="secondary"
                      value={selectedBrand || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleBrandChange("brand", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Brand")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* Model Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={models}
                      fullWidth
                      disablePortal
                      id="combobox-model"
                      name="model"
                      color="secondary"
                      value={motoData.model || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleModelChange("model", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Model")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* Year Field */}
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={years}
                      fullWidth
                      disablePortal
                      id="combobox-year"
                      name="year"
                      color="secondary"
                      value={motoData.year || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleModelChange("year", newValue ? newValue : "")
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Year")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* Current KM Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Current KM")}
                      name="odometer"
                      color="secondary"
                      value={motoData.odometer}
                      onChange={handleNumericChange}
                      fullWidth
                      required
                      error={!!errors.odometer}
                      helperText={errors.odometer}
                    />
                  </Grid>
                  {/* Chasis Number Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Chasis Number")}
                      name="chasis_no"
                      color="secondary"
                      value={motoData.chasis_no}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.chasis_no}
                      helperText={errors.chasis_no}
                    />
                  </Grid>
                  {/* Motor Number Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Motor Number")}
                      name="motor_no"
                      color="secondary"
                      value={motoData.motor_no}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.motor_no}
                      helperText={errors.motor_no}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>
                  {/* License Plate Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("License Plate")}
                      name="license_plate"
                      color="secondary"
                      value={motoData.license_plate}
                      onChange={handleInputChange}
                      fullWidth
                      error={!!errors.license_plate}
                      helperText={errors.license_plate}
                    />
                  </Grid>
                  {/* Description Field */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label={t("Description")}
                      name="description"
                      color="secondary"
                      multiline
                      value={motoData.description}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  {/* Upload Photo Button */}
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenPhotoDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#0EC6EA",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <AddAPhotoOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Photo")}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* Upload Documents Button */}
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDocumentsDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#F6B411",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <PostAddOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Documents")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Old File List */}
                  <Grid item xs={6}>
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 100,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleMarkFiles(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={getFileName(doc)} />
                        </ListItem>
                      ))}
                      {/* {[0, 1, 2, 3, 4].map((sectionId) => (
                        <li key={`section-${sectionId}`}>
                          <ul>
                            <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            {[0, 1, 2].map((item) => (
                              <ListItem key={`item-${sectionId}-${item}`}>
                                <ListItemText primary={`Item ${item}`} />
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      ))} */}
                    </List>
                  </Grid>

                  {/* New File List */}
                  <Grid item xs={6}>
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 100,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {newFiles.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleNewDeleteFile(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={getFileName(doc.name)} />
                        </ListItem>
                      ))}
                      {/* {[0, 1, 2, 3, 4].map((sectionId) => (
                        <li key={`section-${sectionId}`}>
                          <ul>
                            <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            {[0, 1, 2].map((item) => (
                              <ListItem key={`item-${sectionId}-${item}`}>
                                <ListItemText primary={`Item ${item}`} />
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      ))} */}
                    </List>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Update Part")}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openPhotoDialog}
                  onClose={() => setOpenPhotoDialog(false)}
                >
                  <DialogTitle> {t("Upload Photo")} </DialogTitle>
                  <DialogContent>
                    <input type="file" onChange={handlePhotoSelection} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenPhotoDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EditMoto;
